import {
  applyMiddleware,
  compose,
  createStore,
  combineReducers
} from 'redux';
import { History } from 'history';
import isUndefined from 'lodash/isUndefined';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
  routerMiddleware,
  connectRouter
} from 'connected-react-router';
import {
  ELSCommonConfig,
  ELSStorageHelper
} from '@els/els-ui-common-react';
import {
  PERSIST_KEY,
  reduxStateDomains,
  restoreStateKeys,
  RootState
} from './redux.constants';
import { studentStudyReducer } from './student-study/studentStudy.reducer';
import { studyActions } from './student-study/studentStudy.actions';
import { omitProps, omitPropsWithDefaultValue } from './redux.utilities';
import { ELSLoggingService } from '../components/els.components';

const fileName = 'redux.store';

const restoreState = store => {
  const rootStateFromStorage = ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
    .namespace('')
    .get(PERSIST_KEY);

  if (!rootStateFromStorage) return;
  const cwState = rootStateFromStorage[reduxStateDomains.STUDENTSTUDY_STATE];
  if (cwState) {
    const stateNeedRestore = Object.keys(restoreStateKeys).filter((key) => {
      return restoreStateKeys[key] && !isUndefined(cwState[key]);
    }).reduce((acc, cur) => ({
      ...acc,
      [cur]: cwState[cur],
    }), {});
    store.dispatch(studyActions.restoreState({
      ...stateNeedRestore,
      pendingRequestCount: 0
    }));
  }
};

const createAppReducer = (history: History) =>
  combineReducers({
    [reduxStateDomains.ROUTER_STATE]: connectRouter(history),
    [reduxStateDomains.STUDENTSTUDY_STATE]: studentStudyReducer,
  });

// We decide not to use the list of actions that trigger the caching because
// we want to cache redux state in every actions. However, we didn't remove this
// whitelist implementation in case we need it in the future
const cacheTriggerActionTypes = [];

const storageMiddleware = ({ getState }) => next => action => {
  const result = next(action);
  try {
    if (cacheTriggerActionTypes.length === 0 || cacheTriggerActionTypes.includes(action.type)) {
      const store: RootState = getState();
      const omittedPropsWithDefaultValue = omitPropsWithDefaultValue(store);
      const omittedProps = omitProps(omittedPropsWithDefaultValue);
      ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
        .namespace('')
        .put(PERSIST_KEY, omittedProps);
    }
  } catch (e) {
    ELSLoggingService.warn(fileName, e.message);
    return result;
  }
  return result;
};

export const configureStore = (history: History) => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), storageMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }

  const enhancers = [applyMiddleware(...middlewares)];
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(createAppReducer(history), {}, composeEnhancers(...enhancers));
  restoreState(store);

  return store;
};
