import { Component } from 'react';
import { connect } from 'react-redux';
import { ELSWithModalService } from '@els/els-component-modal-react';
import { Location } from 'history';
import { locationSelectors } from '../../redux/location/location.selectors';
import { ELSModalServiceType } from '../../models/els.models';

export interface LocationChangeHandlerProps {
  location: Location;
  modalService: ELSModalServiceType;
}

export class LocationChangeHandler extends Component<LocationChangeHandlerProps> {
  componentDidUpdate({ location: prevLocation }) {
    const pathname = 'pathname';
    const newLocation = this.props.location;
    if (prevLocation && newLocation && prevLocation[pathname] !== newLocation[pathname]) {
      this.props.modalService.closeAllModal();
    }
    /* eslint-disable no-underscore-dangle */
    window._mfq = window._mfq || [];
    const regexp = new RegExp('/#', 'g');
    const href = window.location.href.replace(regexp, '');
    window._mfq.push(['newPageView', href]);
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = state => ({
  location: locationSelectors.getLocation(state)
});

export default connect(mapStateToProps, null)(ELSWithModalService(LocationChangeHandler));
