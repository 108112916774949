export const prefix = {
  ITEM: 'o-els-flex-layout__item',
  LAYOUT: 'o-els-flex-layout',
};

export const DEFAULT_TAG = 'div';

export enum FlexLayoutModifier {
  GUTTERS = 'gutters',
  // eslint-disable-next-line @typescript-eslint/camelcase
  GUTTERS_1o2 = 'gutters-1o2',
  // eslint-disable-next-line @typescript-eslint/camelcase
  GUTTERS_3o4 = 'gutters-3o4',
  // eslint-disable-next-line @typescript-eslint/camelcase
  GUTTERS_1X_1o2 = 'gutters-1x1o2',
  GUTTERS_1X_MOBILE = 'gutters-1x@mobile',
  GUTTERS_2X = 'gutters-2x',
  GUTTERS_3X = 'gutters-3x',
  WRAP_AT_MOBILE = 'wrap@mobile',
  WRAP_AT_TABLET = 'wrap@tablet',
  WRAP = 'wrap',
  GROW = 'grow',
  COLUMN = 'column',
  VERTICAL_GUTTERS = 'vertical-gutters',
  MIDDLE = 'middle',
  LEFT = 'left',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}
