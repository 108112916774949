import { ELSCommonConfig } from '../els.components';

enum EnvProfile {
  dev = 'dev',
  qa = 'qa',
  demo = 'demo',
  test1 = 'test1',
  test2 = 'test2',
  test3 = 'test3',
  test4 = 'test4',
  test5 = 'test5',
  test6 = 'test6',
  test7 = 'test7',
  'ck-cert' = 'ck-cert',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  'cd-staging' = 'cd-staging',
  staging = 'staging',
  stage = 'stage',
  stage1 = 'stage1',
  stage2 = 'stage2',
  validate = 'validate',
  vendor = 'vendor',
  perf = 'perf',
  betaprod = 'betaprod',
  prod = 'prod',
}

type EnvConfig = {
  gatewayBaseURL: string;
  contentBaseURL: string;
  florenceFacadeBaseURL: string;
  cookiesDomain: string;
  evolveCartURL: string;
  myEvolveUrl: string;
  eolsEnv: string;
  config: {
    PushEventsEnabled: boolean;
    PushEventsUsesGateway: boolean;
    TokenServiceEnabled: boolean; // toggle for enabling token service integration
    CORSAllowCredentials: boolean; // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
    EnableLogging: boolean;
  };
}

const getSharedLowerEnvConfig = (env: EnvProfile): EnvConfig => {
  return {
    gatewayBaseURL: `https://eolsapi-${env}.apps.els-ols.com`,
    florenceFacadeBaseURL: `https://florence-facade-service-${env}.apps.els-ols.com`,
    contentBaseURL: 'https://content-qa.els-ols.com',
    cookiesDomain: '.apps.els-ols.com',
    evolveCartURL: 'https://evolvetest.elsevier.com/cs/lms',
    myEvolveUrl: 'https://evolvetest.elsevier.com/cs/myEvolve',
    eolsEnv: '',
    config: {
      PushEventsEnabled: null,
      PushEventsUsesGateway: null,
      TokenServiceEnabled: true,
      CORSAllowCredentials: true,
      EnableLogging: true
    }
  };
};

const envConfigMap: Record<EnvProfile, EnvConfig> = {
  dev: {
    ...getSharedLowerEnvConfig(EnvProfile.dev),
    contentBaseURL: 'https://content-dev.els-ols.com/dev',
  },
  qa: {
    ...getSharedLowerEnvConfig(EnvProfile.qa),
    gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com',
  },
  demo: {
    ...getSharedLowerEnvConfig(EnvProfile.demo),
  },
  test1: {
    ...getSharedLowerEnvConfig(EnvProfile.test1),
  },
  test2: {
    ...getSharedLowerEnvConfig(EnvProfile.test2),
  },
  test3: {
    ...getSharedLowerEnvConfig(EnvProfile.test3),
  },
  test4: {
    ...getSharedLowerEnvConfig(EnvProfile.test4),
  },
  test5: {
    ...getSharedLowerEnvConfig(EnvProfile.test5),
  },
  test6: {
    ...getSharedLowerEnvConfig(EnvProfile.test6),
  },
  test7: {
    ...getSharedLowerEnvConfig(EnvProfile.test7),
  },
  'ck-cert': {
    ...getSharedLowerEnvConfig(EnvProfile['ck-cert']),
  },
  'cd-staging': {
    ...getSharedLowerEnvConfig(EnvProfile.stage),
  },
  staging: {
    ...getSharedLowerEnvConfig(EnvProfile.stage),
  },
  stage: {
    ...getSharedLowerEnvConfig(EnvProfile.stage),
  },
  stage1: {
    ...getSharedLowerEnvConfig(EnvProfile.stage1),
  },
  stage2: {
    ...getSharedLowerEnvConfig(EnvProfile.stage2),
  },
  validate: {
    ...getSharedLowerEnvConfig(EnvProfile.validate),
    eolsEnv: 'validate',
  },
  vendor: {
    ...getSharedLowerEnvConfig(EnvProfile.vendor),
  },
  perf: {
    ...getSharedLowerEnvConfig(EnvProfile.perf),
    gatewayBaseURL: 'https://eolsapi-perf.apps.elsevier.education',
    contentBaseURL: 'https://content-perf.apps.elsevier.education',
  },
  betaprod: {
    ...getSharedLowerEnvConfig(EnvProfile.betaprod),
    gatewayBaseURL: 'https://eolsapi-betaprod.apps.elsevier.education',
    contentBaseURL: 'https://content-betaprod.apps.elsevier.education',
    config: {
      ...getSharedLowerEnvConfig(EnvProfile.betaprod).config,
      PushEventsEnabled: true,
      PushEventsUsesGateway: true,
    }
  },
  prod: {
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    florenceFacadeBaseURL: 'https://florence-api.elsevier.com',
    contentBaseURL: 'https://eolscontent.elsevier.com',
    evolveCartURL: 'https://evolve.elsevier.com/cs/lms',
    myEvolveUrl: 'https://evolve.elsevier.com/cs/myEvolve',
    cookiesDomain: '.elsevier.com',
    eolsEnv: '',
    config: {
      PushEventsEnabled: null,
      PushEventsUsesGateway: null,
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  }
};

export const ServerConstants: Record<EnvProfile, EnvConfig> & {
  DataSource: EnvProfile;
} = {
  DataSource: ELSCommonConfig.appProfile as EnvProfile,
  ...envConfigMap
};
