import React from 'react';
import { ELSPageLoader } from '@els/els-ui-common-react';
import IsRender from '../../components/is-render/IsRender.component';

// Note: If you use this before connect it will not work
const withPageLoader = (WrappedComponent) => (wrappedProps) => {
  const { showPageLoader } = wrappedProps;
  return (
    <>
      <IsRender isRender={showPageLoader}>
        <ELSPageLoader />
      </IsRender>
      <WrappedComponent {...wrappedProps} />
    </>
  );
};

export default withPageLoader;
