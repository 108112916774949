export enum AppLinkRedirectErrorMessage {
  MISSING_COOKIES = 'MISSING_COOKIES',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  MISSING_COURSE_SECTION_ID = 'MISSING_COURSE_SECTION_ID',
  UNSUPPORTED_USER_ROLE = 'UNSUPPORTED_USER_ROLE',
  REQUEST_FAILED = 'REQUEST_FAILED',
}

export const AppLinkRedirectErrorMessages: Record<AppLinkRedirectErrorMessage, string> = {
  [AppLinkRedirectErrorMessage.MISSING_COOKIES]: 'Failed to launch by inter app linking: missing cookies',
  [AppLinkRedirectErrorMessage.EXPIRED_TOKEN]: 'Failed to launch by inter app linking: expired token',
  [AppLinkRedirectErrorMessage.INVALID_TOKEN]: 'Failed to launch by inter app linking: invalid token',
  [AppLinkRedirectErrorMessage.MISSING_COURSE_SECTION_ID]: 'Failed to launch by inter app linking: missing courseSectionId',
  [AppLinkRedirectErrorMessage.UNSUPPORTED_USER_ROLE]: 'Failed to launch by inter app linking: user role is not supported',
  [AppLinkRedirectErrorMessage.REQUEST_FAILED]: 'Failed to launch by inter app linking: request(s) failed',
};

export enum AppLinkCookie {
  X_TOKEN = 'x-token',
  X_LINK_ID = 'x-link-id',
}
