import React from 'react';
import { ELSModalService } from '@els/els-component-modal-react';
import { ELSToastService, } from '@els/els-component-toast-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { AnalyticsActionProps } from '../../models/analytics.models';
import {
  RecommendationDto,
  RecommendationItemDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import {
  RemediationActivityType,
  RemediationStatusCounts
} from './remediation-home.models';
import { getAssessmentGoalsItemsTotals } from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export const RemediationQuizModalId = 'RemediationQuizModalId';

export type RemediationQuizModalProps = {
  modalService: ELSModalService;
  toastService: ELSToastService;
  trackAction: (props: AnalyticsActionProps) => void;
  redirect: (path: string, state?: Record<string, string>) => void;
  assessmentGoals: AssessmentGoalDto[];
  recommendation: RecommendationDto;
  recommendationItems: RecommendationItemDto[];
  recommendationItemStatusMap: Record<string, RemediationStatusCounts>;
};

const RemediationQuizModal = (props: RemediationQuizModalProps) => {

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationQuizModalId);
  };

  const recTotals = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: props.assessmentGoals,
      activityType: RemediationActivityType.ASSESS,
      recommendations: [props.recommendation],
      recommendationItems: props.recommendationItems,
      statusMap: props.recommendationItemStatusMap
    }
  );

  return (
    <div>
      <div className="c-els-modal">
        <div className="c-els-modal__window u-els-width-1o1@mobile">
          <div className="c-ssa-remediation-quiz-modal">

            <FlexLayout
              modifiers={[FlexLayoutModifier.TOP, FlexLayoutModifier.GUTTERS]}
            >
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h2>Quizzing</h2>
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-modal__close"
                  onClick={handleCancelClick}
                >
                  <ELSIcon name="close" size="1x" />
                  <span className="u-els-hide-visually">Close this modal window</span>
                </button>
              </FlexItem>
            </FlexLayout>

            <div className="o-els-container">
              These quiz questions were gathered based on the questions and concepts you struggled with.
            </div>
            <div className="o-els-container">
              <FlexLayout
                modifiers={[
                  FlexLayoutModifier.MIDDLE,
                  FlexLayoutModifier.GUTTERS,
                ]}
              >
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <div className="c-ssa-remediation-activity-section__checklist-item">
                    {recTotals.attempted}/{recTotals.total} Questions Answered
                  </div>
                </FlexItem>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <div className="c-ssa-remediation-activity-section__checklist-item">
                    {recTotals.completed}/{recTotals.total} Questions Correct
                  </div>
                </FlexItem>
              </FlexLayout>
            </div>

            <div className="o-els-container">
              <button
                type="button"
                className="c-els-button c-els-button--secondary c-els-button--x-small"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  alert('launch quiz');
                }}
              >
                Start quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemediationQuizModal;
