import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { CourseSectionDto } from './eols-course-crud.dtos';
import { addSearchParams } from '../../utilities/app.utilities';

export const fetchCourseSection = (courseId: string): Promise<CourseSectionDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<CourseSectionDto>(`/courseSections/${courseId}`);
  });
};

export const fetchUserCourseSections = (userId: string, active = true, entitled?: boolean): Promise<CourseSectionDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<CourseSectionDto[]>(addSearchParams('/courseSections', { entitled, userId, active }));
  });
};
