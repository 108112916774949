import {
  eolsBaseApi,
  withApiErrorLoggingOnly,
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from './eols-features-api.dtos';
import { AppConstants } from '../../components/app/app.constants';

export const fetchGroupedFeatureFlags = (app: string = AppConstants.APP_ID, nameFilter?: string, groupFilter?: string): Promise<FeatureFlagsGroupedDto[]> => {

  const url = addSearchParams('/features/groupedFeatures', {
    app,
    nameFilter,
    groupFilter
  });

  return withApiErrorLoggingOnly(() => eolsBaseApi.get<FeatureFlagsGroupedDto[]>(url));
};

export const fetchGroupFeatureFlag = (app: string = AppConstants.APP_ID, key: string, group: string): Promise<FeatureFlagDto> => {
  return withApiErrorLoggingOnly(
    () => {
      return eolsBaseApi.get<FeatureFlagDto>(`/features/app/${app}/key/${key}/group/${group}`);
    }
  );
};

export const postGroupFeatureFlag = (app: string = AppConstants.APP_ID, key: string, group: string, value: string): Promise<void> => {
  return withApiErrorLoggingOnly(
    () => {
      return eolsBaseApi.post<void>(`/features/app/${app}/key/${key}/group/${group}`, {
        data: value
      });
    }
  );
};
