import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import {
  fetchLearningObjectsData
} from '../../apis/ocs-api-service/ocs-api-service.utilities';
import { QtiDataDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import QuizItemPreview from './QuizItemPreview.component';
import {
  RecommendationDto,
  RecommendationItemDto
} from '../../apis/florence-facade/florence-facade.dtos';

type RemediationQuizPropsOnly = {}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  fetchRemediationRecommendationsAction: studyActions.fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationItemsAction: studyActions.fetchRemediationRecommendationItemsAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationQuizProps = PropsFromRedux & RemediationQuizPropsOnly & ELSPropsFromModalService;

export type RemediationQuizState = {
  qtiDataDtos: QtiDataDto[];
  quizItems: RecommendationItemDto[];
  recommendations: RecommendationDto[];
}

const defaultState: RemediationQuizState = {
  qtiDataDtos: [],
  quizItems: [],
  recommendations: [],
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationQuizComponent = (props: RemediationQuizProps) => {

  const [state, setState] = useState<RemediationQuizState>(defaultState);

  const mergeState = (newState: Partial<RemediationQuizState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  useEffect(() => {

    const {
      fetchRemediationRecommendationsAction,
      fetchRemediationRecommendationItemsAction,
      appLinkData
    } = props;

    fetchRemediationRecommendationsAction(appLinkData.outPostBody.assessmentId).then((recommendations) => {
      mergeState({ recommendations });
      const quiz = recommendations.find((recommendation) => {
        return recommendation.contentType === 'EAQ';
      });

      if (quiz) {
        fetchRemediationRecommendationItemsAction(quiz.id).then((quizItems: RecommendationItemDto[]) => {
          mergeState({ quizItems });
          fetchLearningObjectsData(quizItems.map((quizItem) => {
            return quizItem.itemId;
          })).then((response) => {
            const qtiDataDtos: QtiDataDto[] = Object.values(response).map((item) => {
              return JSON.parse(item).qtiData;
            });
            mergeState({ qtiDataDtos });
          });
        });
      }
    });

  }, []);

  const handleReturnNavigation = () => {
    props.redirect(RoutePath.STUDY_ASSIGNMENT_HOME);
  };

  return (
    <>
      <ELSPageHeader onCloseClick={handleReturnNavigation} title="Study Quiz" />
      <div className="c-ssa-remediation-quiz">
        {state.qtiDataDtos.map((qtiData) => {
          return (
            <div key={qtiData.vtwId}>
              <div className="o-els-container o-els-container--2x">
                <div className="c-els-card c-els-card--size-small c-ssa-remediation-quiz__card">
                  <div className="c-els-card__content">
                    <QuizItemPreview qtiData={qtiData} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Student Study Quiz' }),
  connector,
  ELSWithModalService
];

const RemediationQuiz = compose<null, RemediationQuizPropsOnly>(...enhancers)(RemediationQuizComponent);

export default RemediationQuiz;
