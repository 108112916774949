export enum VALIDATOR {
  VALIDATE_DATE = 'validDate',
  VALIDATE_DATE_TIME = 'validDateTime',
  VALIDATE_BEFORE_DATE = 'validDateBefore',
  VALID_WEEKS = 'validWeeks',
  VALID_STRING_LENGTH = 'validStringLength',
  VALID_STRING_MIN_LENGTH = 'validStringMinLength',
  VALID_HOURS = 'validHours',
  VALID_MINUTES = 'validMinutes',
  VALID_DAYS = 'validDays',
  VALID_URL_FORMAT = 'validUrlFormat',
  VALID_URL_PROTOCOL ='validUrlProtocol'
}
