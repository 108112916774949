import React, { Component } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  isEmpty,
  toString
} from 'lodash';
import moment from 'moment';
import {
  ELSURLHelper,
  ELSTokenHelper,
  ELSLoggingService,
  ELSCommonUIConstants,
  ELSTokenServiceRegistrar,
  ELSAdobeAnalyticService,
} from '@els/els-ui-common-react';
import {
  studyActions,
  UserParams
} from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { locationSelectors } from '../../redux/location/location.selectors';
import { ELSTokenUser } from '../../models/els.dtos';
import { registerMouseFlowService } from '../../components/app/app.config';
import {
  removeCookies
} from '../app-link-redirect/app-link-redirect.utilities';
import { AnalyticsAction } from '../../models/analytics.models';
import { DeepLinkQueryParam } from '../../constants/deep-link.constants';
import { LoginQueryParam } from './login.constants';
import { RoutePath } from '../../components/app/app.constants';

const mapStateToProps = (state) => ({
  location: locationSelectors.getLocation(state),
});

const mapDispatchToProps = {
  fetchCourseSection: studyActions.fetchCourseSection,
  fetchAllAppFeatureFlags: studyActions.fetchAllAppFeatureFlags,
  redirect: locationActions.redirect,
  setIsbns: studyActions.setIsbns,
  setUser: studyActions.setUser,
  setRegisteredToken: studyActions.setRegisteredToken,
  trackAction: studyActions.trackAction,
  setEnableDeepLink: studyActions.setEnableDeepLink,
  fetchEvolveUsers: studyActions.fetchEvolveUsers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LoginPageProps = ConnectedProps<typeof connector> & {
  aaData?: {
    analytics: {
      pageName: string;
      pageType: string;
    };
  };
}

export interface LoginPageState {
  hasError: boolean;
  errorMessage: string;
}

const LoginPageFileName = 'Login.page.tsx';

class LoginPage extends Component<LoginPageProps, LoginPageState> {
  constructor(props) {
    super(props);
    // Remove cookies if left around by other app
    removeCookies();
    this.state = {
      hasError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {

    const {
      location,
      setRegisteredToken,
      setEnableDeepLink
    } = this.props;

    const queryString = location.search;
    const courseSectionId = parseInt(ELSURLHelper.getParameterByName(LoginQueryParam.COURSE_ID, queryString), 10) || 0;
    const token = ELSURLHelper.getParameterByName(LoginQueryParam.TOKEN, queryString);
    const enableDeepLink = ELSURLHelper.getParameterByName(DeepLinkQueryParam.ENABLE_DEEP_LINK, queryString);

    if (!courseSectionId) {
      const errorMessage = 'Failed to login via LMS - Launching app by LTI launch: missing courseSectionId';
      this.logError(errorMessage);

      return;
    }

    if (!token) {
      const errorMessage = `Failed to login via LMS - Launching app by LTI launch with courseSectionId: ${courseSectionId} missing token`;
      this.logError(errorMessage);

      return;
    }

    const tokenExpireDate = ELSTokenHelper.getExpirationDateFromToken(token);
    const now = moment();
    if (now.isAfter(tokenExpireDate)) {
      const errorMessage = `Failed to login via LMS - Launching app by LTI launch with courseSectionId: ${courseSectionId} expired token`;
      this.logError(errorMessage);

      return;
    }

    ELSTokenServiceRegistrar.register(token);
    setRegisteredToken(token);
    setEnableDeepLink(enableDeepLink === 'true');

    const tokenUser: ELSTokenUser = ELSTokenHelper.getUser();

    if (isEmpty(tokenUser)) {
      const errorMessage = `Failed to login via LMS - Launching app by LTI launch with courseSectionId: ${courseSectionId} invalid token`;
      this.logError(errorMessage);

      return;
    }

    if (tokenUser.role !== ELSCommonUIConstants.userType.Student && tokenUser.role !== ELSCommonUIConstants.userType.Instructor) {
      const errorMessage = 'User role is not supported';
      this.logError(errorMessage);

      return;
    }

    const userParams = {
      userId: tokenUser.userId.toString(),
      courseId: toString(courseSectionId),
      roleId: tokenUser.role,
      isbns: tokenUser.appParams.isbns
    };
    this.init(userParams);
  }

  logError = errorMessage => {
    ELSLoggingService.error(LoginPageFileName, errorMessage);
    this.setState({
      hasError: true,
      errorMessage
    });
  };

  init(userParams: UserParams): Promise<void> {

    const {
      setIsbns,
      setUser,
      fetchCourseSection,
      fetchAllAppFeatureFlags,
      trackAction,
      fetchEvolveUsers,
      redirect
    } = this.props;

    setUser(userParams);
    setIsbns(userParams.isbns);
    registerMouseFlowService();

    fetchEvolveUsers(userParams.userId);

    return Promise.all([
      fetchCourseSection(userParams.courseId),
      fetchAllAppFeatureFlags(),
    ]).then(() => {
      trackAction({ action: AnalyticsAction.LOGIN_LAUNCH, props: null });

      if (this.props.aaData) {
        const { pageName, pageType } = this.props.aaData.analytics;
        ELSAdobeAnalyticService.trackEvent(
          ELSCommonUIConstants.analytics.eventTypes.newPage,
          {
            ...window.pageData,
            page: {
              ...window.pageData.page,
              name: pageName,
              type: pageType
            },
          }
        );
      }

      redirect(RoutePath.AI_CHAT);
    });
  }

  render() {
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      return (
        <div className="u-els-padding-3x">
          <h2 className="c-els-heading c-els-heading--h4 u-els-color-primary">{errorMessage}</h2>
        </div>
      );
    }

    return (
      <div className="u-els-padding-3x">
        <h2 className="c-els-heading c-els-heading--h4 u-els-color-primary">Loading</h2>
      </div>
    );
  }
}

export { LoginPage };

export default connector(LoginPage);
