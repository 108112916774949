import React, {
  useState
} from 'react';
import { ELSButton } from '@els/els-component-button-react';
import { ELSModalService } from '@els/els-component-modal-react';
import { ELSCheckBox } from '@els/els-component-form-field-react';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';

export type AuthoringIsbnModalProps = {
  handleSave: (selectedEeoIsbns: Record<string, boolean>) => void;
  modalService: ELSModalService;
  selectedEeoIsbns: Record<string, boolean>;
  primaryTaxonomies: Record<string, string>;
  modalId: string;
}

export type AuthoringIsbnModalState = {
  selectedEeoIsbns: Record<string, boolean>;
}

const defaultState: AuthoringIsbnModalState = {
  selectedEeoIsbns: {}
};

const AuthoringIsbnModal = (props: AuthoringIsbnModalProps) => {
  const {
    modalService,
  } = props;

  const [state, setState] = useState<AuthoringIsbnModalState>({
    ...defaultState,
    selectedEeoIsbns: props.selectedEeoIsbns
  });

  const handleIsbnCheck = (row: {
    isbn: string;
  }, isSelected: boolean) => {

    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: {
          ...prevState.selectedEeoIsbns,
          [row.isbn]: isSelected
        }
      };
    });
  };

  const handleCancel = () => {
    modalService.closeModal(props.modalId);
  };

  const selectAllIsbns = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: Object.keys(props.primaryTaxonomies).reduce((acc, eeoIsbn) => {
          return {
            ...acc,
            [eeoIsbn]: true
          };
        }, {})
      };
    });
  };

  const deselectAllIsbns = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: {}
      };
    });
  };

  return (
    <div>
      <div className="c-els-modal">
        <div className="c-els-modal__window u-els-width-1o1@mobile">
          <div>
            <div>Selected isbns:</div>
            <div>
              <FlexLayout modifiers={[
                FlexLayoutModifier.LEFT,
                FlexLayoutModifier.GUTTERS
              ]}>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-anchorize"
                    onClick={selectAllIsbns}
                  >
                    select all
                  </button>
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-anchorize"
                    onClick={deselectAllIsbns}
                  >
                    deselect all
                  </button>
                </FlexItem>
              </FlexLayout>
            </div>
            <div className="o-els-container">
              {
                Object.keys(props.primaryTaxonomies).map((eeoIsbn) => {
                  return (
                    <div
                      key={eeoIsbn}
                      className="o-els-container o-els-container--1o2"
                    >
                      <ELSCheckBox
                        changeHandler={(e, value) => handleIsbnCheck({ isbn: eeoIsbn }, value)}
                        checked={state.selectedEeoIsbns[eeoIsbn]}
                      >
                        {props.primaryTaxonomies[eeoIsbn]}
                      </ELSCheckBox>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div>
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.WRAP,
              FlexLayoutModifier.LEFT
            ]}>
              <FlexItem>
                <ELSButton type={ELSButtonType.PRIMARY}
                           size={ELSButtonSize.SMALL}
                           onClick={() => {
                             props.handleSave(state.selectedEeoIsbns);
                           }}>
                  Save
                </ELSButton>
              </FlexItem>
              <FlexItem>
                <ELSButton type={ELSButtonType.SECONDARY}
                           size={ELSButtonSize.SMALL}
                           onClick={handleCancel}>
                  Cancel
                </ELSButton>
              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthoringIsbnModal;
