import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { ELSDropDown } from '@els/els-component-form-field-react';
import React from 'react';
import { studySelectors } from '../../../redux/student-study/studentStudy.selectors';
import IsRender from '../../../components/is-render/IsRender.component';
import { masteryLevelsDropdownOptions } from './select-mastery-goal.constants';
import { TopicWithLineage } from '../../eaq-student-topic-report/eaq-student-topic-report.models';
import { EaqSelfStudyAssessmentEditorState } from '../EaqSelfStudyAssessmentEditor.page';
import { isMasteryLevelValid } from '../eaq-self-study-assessment-editor.utilities';
import { SELECT_OPTION } from '../../../utilities/app.utilities';
import { FlexLayout } from '../../../components/flex/FlexLayout.component';
import { FlexItem } from '../../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../../components/flex/flex.constants';

export interface SelectMasteryGoalPropsOnly {
    handleMasteryGoalChange: (selectedGoal: string) => void;
    flattenedTopics: TopicWithLineage[];
    state: EaqSelfStudyAssessmentEditorState;
}

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state)
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type SelectMasteryGoalProps = PropsFromRedux & SelectMasteryGoalPropsOnly;

export const SelectMasteryGoalComponent = (props: SelectMasteryGoalProps) => {
  const {
    messages,
    handleMasteryGoalChange,
    state
  } = props;

  const getDropDownValue = () => {
    const { assessment } = state;
    if (!assessment
        || !assessment.assessmentGoals
        || !assessment.assessmentGoals.length) {
      return SELECT_OPTION.value;
    }
    return state.assessment.assessmentGoals[0].goal;
  };

  return (
    <div>
      <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS, FlexLayoutModifier.RIGHT]}>
        <FlexItem>
          {messages.SELECT_A_MASTERY_GOAL}
        </FlexItem>
        <FlexItem>
          <ELSDropDown
              name="select-mastery-goal"
              id="c-ssa-select-mastery-goal"
              options={masteryLevelsDropdownOptions}
              changeHandler={(e, value: string) => handleMasteryGoalChange(value)}
              value={getDropDownValue()}
           />
        </FlexItem>
      </FlexLayout>

      <IsRender isRender={!isMasteryLevelValid(state)}>
        <div className="u-els-margin-top-1o2 u-els-color-warn">
          <p>{messages.MASTERY_LEVEL_ALREADY_ACHIEVED_FOR_THIS_TOPIC}</p>
        </div>
      </IsRender>
    </div>
  );
};

const enhancers = [connector];

const SelectMasteryGoal = compose<null, SelectMasteryGoalPropsOnly>(...enhancers)(SelectMasteryGoalComponent);

export default SelectMasteryGoal;
