export enum CoursewareUserHistoryStateKey {
  IS_COURSE_OWNER = 'IS_COURSE_OWNER',
  DISMISSED_LATE_ASSIGNMENTS = 'DISMISSED_LATE_ASSIGNMENTS',
  DISMISSED_NEW_CATALOG_ITEMS = 'DISMISSED_NEW_CATALOG_ITEMS',
  DISMISSED_UPCOMING_CATALOG_ITEMS = 'DISMISSED_UPCOMING_CATALOG_ITEMS',
  COLLAPSED_FOLDERS = 'COLLAPSED_FOLDERS',
  DEFAULT_COURSE_PLAN_TAB = 'DEFAULT_COURSE_PLAN_TAB',
}

export interface EolsUserHistoryResponseDto {
  id: number;
  stateKey: string;
  stateInfo: string;
  courseSectionId: number;
  instanceId?: string;
}

export interface EolsUserHistoryDto {
  id?: number;
  stateKey: string;
  stateInfo: string;
  courseSectionId: number;
  instanceId?: string;
  eolsUser: {
    id: number;
  };
}

export interface EolsLessonStatusAttributeRequest {
  attributeContents: string;
  userId: number;
  lessonId: string;
  attributeName: string;
  courseSectionId?: number;
  instanceId?: string;
}
