import React from 'react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSDropDown,
  ELSDropDownOption
} from '@els/els-component-form-field-react';
import { AssessmentDto } from '../../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { allQuestionNumberOptions } from './number-of-questions.constants';
import { studySelectors } from '../../../redux/student-study/studentStudy.selectors';
import IsRender from '../../../components/is-render/IsRender.component';
import { FlexLayout } from '../../../components/flex/FlexLayout.component';
import { FlexItem } from '../../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../../components/flex/flex.constants';

export interface NumberOfQuestionsPropsOnly {
  maxNumber: number;
  onAssessmentChange: Function;
  assessment: AssessmentDto;
}

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state)
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type NumberOfQuestionsProps = PropsFromRedux & NumberOfQuestionsPropsOnly;

export const NumberOfQuestionsComponent = (props: NumberOfQuestionsProps) => {
  const {
    maxNumber,
    onAssessmentChange,
    assessment,
    messages
  } = props;

  const getMaxQuestionsOptions = (): ELSDropDownOption[] => {
    return allQuestionNumberOptions;
  };

  const handleOnSelect = (selectedNumber: string) => {
    const goal = parseInt(selectedNumber, 10);
    onAssessmentChange(
      { assessmentGoals: [{ goal }] }
    );
  };

  const isDisabled = (): boolean => {
    return false;
  };

  const getSelectedOption = (options: ELSDropDownOption[]): number => {

    if (!options || !options.length) {
      return null;
    }
    if (!assessment || !assessment.assessmentGoals || !assessment.assessmentGoals.length) {
      return options[0].value as number;
    }
    const _selectedOption = options.find((option) => {
      return option.value === assessment.assessmentGoals[0].goal;
    });

    if (!_selectedOption) {
      return null;
    }
    return _selectedOption.value as number;
  };

  const options:ELSDropDownOption[] = getMaxQuestionsOptions();
  const selectedOption: number = getSelectedOption(options);

  const isValid = (value: number, maxLength: number) => {
    return isNaN(value)
    || value === null
    || isNaN(maxLength)
    || maxLength === null
    || value <= maxLength;
  };

  return (
    <div>
      <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS, FlexLayoutModifier.RIGHT]}>
        <FlexItem>
          {messages.NUMBER_OF_QUESTIONS}
        </FlexItem>
        <FlexItem>
          <ELSDropDown
            name="number-of-questions"
            id="c-ssa-number-of-questions"
            options={options}
            value={selectedOption}
            changeHandler={(e, val) => handleOnSelect(val)}
            isDisabled={isDisabled()}
         />
        </FlexItem>
      </FlexLayout>

      <IsRender isRender={!isValid(selectedOption, maxNumber)}>
        <div className="u-els-margin-top-1o2 u-els-color-warn">
          {messages.NOT_ENOUGH_QUESTIONS_SELECT_FEWER_QUESTIONS_OR_MORE_ASSESSMENTS}
        </div>
      </IsRender>
    </div>
  );
};

const enhancers = [connector];

const NumberOfQuestions = compose<null, NumberOfQuestionsPropsOnly>(...enhancers)(NumberOfQuestionsComponent);

export default NumberOfQuestions;
