import { IntlShape } from 'react-intl';
import { LANGUAGE_KEY, Translations } from './message.constants';
import { Messages } from './message.models';

export const translateMessages = (messagesKeys: LANGUAGE_KEY[], intl: IntlShape): Messages => {
  return messagesKeys.reduce((messages, messagesKey) => {
    return {
      ...messages,
      [messagesKey]: intl.formatMessage(Translations[messagesKey]),
    };
  }, {});
};
