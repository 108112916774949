import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSPageLoader } from '@els/els-ui-common-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import FocusTrap from 'focus-trap-react';
import moment from 'moment';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { ELSProgressBar } from '../../components/els.components';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { locationActions } from '../../redux/location/location.actions';
import RemediationHeader from './RemediationHeader.component';
import {
  RemediationActivityType,
  RemediationContentTypeConfigMap,
  RemediationStatusCounts,
} from './remediation-home.models';
import {
  generateMockAssessment,
  generateMockAssignment,
  generateMockRemediationAssignment,
  generateRecommendationItems,
  generateRecommendationItemStatusMap,
  generateRecommendations,
  generateRecommendationStatusMap,
  getAssessmentGoalsTotalsCombined,
  getParentLinkId
} from './remediation-home.utilities';
import {
  RecommendationDto,
  RecommendationItemDto,
  RemediationAssignmentDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import RemediationQuizModal, { RemediationQuizModalId } from './RemediationQuizModal.component';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import RemediationActivitySection from './RemediationActivitySection.component';
import {
  AssessmentDto,
  AssessmentGoalDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { DATE_TIME_PRIMARY } from '../../constants/date.constants';
import RemediationAssessmentGoalModal, { RemediationAssessmentGoalModalId } from './RemediationAssessmentGoalModal.component';

type RemediationHomePropsOnly = {}

const mapDispatchToProps = {
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  fetchRemediationRecommendationsAction: studyActions.fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationItemsAction: studyActions.fetchRemediationRecommendationItemsAction,
  fetchAssessmentByIdAction: studyActions.fetchAssessmentByIdAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationHomeProps = PropsFromRedux
  & RemediationHomePropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

export type RemediationHomeState = {
  showPageLoader: boolean;
  pendingRequestCount: number;
  remediationAssignment: RemediationAssignmentDto;
  assignment: AssignmentDto;
  assessment: AssessmentDto;
  recommendations: RecommendationDto[];
  recommendationItems: RecommendationItemDto[];
  recommendationStatusMap: Record<string, RemediationStatusCounts>;
  recommendationItemsStatusMap: Record<string, RemediationStatusCounts>;
  audit: RecommendationDto[];
}

const defaultState: RemediationHomeState = {
  showPageLoader: false,
  pendingRequestCount: 0,
  remediationAssignment: null,
  assignment: null,
  assessment: null,
  recommendations: null,
  recommendationItems: null,
  recommendationStatusMap: null,
  recommendationItemsStatusMap: null,
  audit: null,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationHomeComponent = (props: RemediationHomeProps) => {

  const [state, setState] = useState<RemediationHomeState>(defaultState);

  const mergeState = (newState: Partial<RemediationHomeState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  // TODO: Implement this when all APIs are available
  // const initFromApis = () => {
  //   const {
  //     fetchRemediationRecommendationsAction,
  //     fetchRemediationRecommendationItemsAction,
  //     appLinkData,
  //     userId,
  //     fetchAssessmentByIdAction
  //   } = props;
  //
  //   // TODO: Pick up here when looking for assignment details
  //   fetchAssessmentByIdAction(userId, appLinkData.outPostBody.assessmentId).then((remediationAssessment) => {
  //     mergeState({ assessment: remediationAssessment });
  //   });
  //
  //   fetchRemediationRecommendationsAction(appLinkData.outPostBody.assessmentId).then((recommendations) => {
  //     mergeState({ recommendations });
  //     const quiz = recommendations.find((recommendation: RecommendationDto) => {
  //       return recommendation.contentType === RemRecContentTypeDto.EAQ;
  //     });
  //
  //     if (quiz) {
  //       fetchRemediationRecommendationItemsAction(quiz.id).then((recommendationItems) => {
  //         mergeState({ recommendationItems });
  //       });
  //     }
  //   });
  // };

  const initFromMock = () => {
    mergeState({
      showPageLoader: true,
      pendingRequestCount: 1
    });
    setTimeout(() => {
      const assessment = generateMockAssessment();
      const mockRecommendations = generateRecommendations(assessment.assessmentGoals);
      const mockRecommendationItems = generateRecommendationItems(mockRecommendations);
      mergeState({
        showPageLoader: false,
        pendingRequestCount: 0,
        assignment: generateMockAssignment(),
        remediationAssignment: generateMockRemediationAssignment(),
        assessment,
        recommendations: mockRecommendations,
        recommendationItems: mockRecommendationItems,
        recommendationStatusMap: generateRecommendationStatusMap(mockRecommendations),
        recommendationItemsStatusMap: generateRecommendationItemStatusMap(mockRecommendationItems),
      });
    }, 500);
  };

  useEffect(() => {
    // initFromApis();
    initFromMock();
  }, []);

  const handleReturnNavigation = () => {
    props.returnAppLink({
      linkId: getParentLinkId(props),
      returnPostBody: null
    });
  };

  const handleAssessLaunch = (recommendation: RecommendationDto) => {

    const {
      redirect,
      modalService,
      trackAction,
      toastService
    } = props;

    return modalService.openCustomModal({
      modalId: RemediationQuizModalId,
      modal: (
        <FocusTrap>
          <RemediationQuizModal
            modalService={modalService}
            toastService={toastService}
            trackAction={trackAction}
            redirect={redirect}
            assessmentGoals={state.assessment.assessmentGoals}
            recommendation={recommendation}
            recommendationItems={state.recommendationItems}
            recommendationItemStatusMap={state.recommendationItemsStatusMap}
          />
        </FocusTrap>
      )
    });
  };

  const handleRecommendationClick = (recommendation: RecommendationDto) => {
    handleAssessLaunch(recommendation);
  };

  const handleTaxonClick = (assessmentGoal: AssessmentGoalDto) => {

    const {
      redirect,
      modalService,
      trackAction,
      toastService
    } = props;

    modalService.openCustomModal({
      modalId: RemediationAssessmentGoalModalId,
      modal: (
        <FocusTrap>
          <RemediationAssessmentGoalModal
            activityType={assessmentGoal.type as RemediationActivityType}
            modalService={modalService}
            toastService={toastService}
            trackAction={trackAction}
            redirect={redirect}
            assessmentGoal={assessmentGoal}
            statusMap={state.recommendationStatusMap}
            recommendations={
              state.recommendations.filter((recommendation: RecommendationDto) => {
                return recommendation.taxonId === assessmentGoal.vtwId
                  && RemediationContentTypeConfigMap[recommendation.contentType].activityType === assessmentGoal.type;
              })
            }
          />
        </FocusTrap>
      )
    });
  };

  const getTotal = () => {
    const percents = [RemediationActivityType.REVIEW, RemediationActivityType.APPLY, RemediationActivityType.ASSESS].map((activityType) => {
      const activityTotals = getAssessmentGoalsTotalsCombined({
        assessmentGoals: state.assessment.assessmentGoals,
        activityType,
        recommendations: state.recommendations,
        recommendationStatusMap: state.recommendationStatusMap,
        recommendationItemsStatusMap: state.recommendationItemsStatusMap,
        recommendationItems: state.recommendationItems
      });

      return Math.round((activityTotals.completed / activityTotals.total) * 100);
    });

    const total = percents.reduce((acc, percent) => {
      return acc + percent;
    }, 0);

    return Math.round(total / percents.length);
  };

  const getDueDisplay = () => {
    const daysUntil = moment(state.assignment.dueDate).diff(moment(), 'days');
    return (
      <>
        <span>Due {moment(state.assignment.dueDate).format(DATE_TIME_PRIMARY)}</span>
        <span>&nbsp;</span>
        <span>({daysUntil <= 0 ? 'Due today' : `In ${daysUntil} days`})</span>
      </>
    );
  };

  if (!state.assignment) {
    return null;
  }

  return (
    <>
      {state.pendingRequestCount > 0 && (<ELSPageLoader />)}
      <div className="c-ssa-remediation-home">
        <RemediationHeader handleReturnNavigation={handleReturnNavigation} />

        <div className="c-ssa-remediation-home__main">
          <div className="c-ssa-remediation-home__center">
            <div className="c-ssa-remediation-home__main-header">

              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_2X,
                FlexLayoutModifier.MIDDLE,
              ]}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW, FlexLayoutModifier.TOP]}>
                  <div className="u-els-color-secondary">{state.assignment.title}</div>
                  <h3><strong>Personalized Learning Plan</strong></h3>
                </FlexItem>
                <FlexItem>

                  <div className="u-els-margin-bottom">
                    <div className="c-ssa-remediation-home__progress">
                      <div>{getDueDisplay()}</div>
                      <FlexLayout modifiers={[
                        FlexLayoutModifier.GUTTERS_1o2,
                        FlexLayoutModifier.MIDDLE,
                      ]}>
                        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                          <ELSProgressBar progressNum={getTotal()} totalNum={100} />
                        </FlexItem>
                        <FlexItem>
                          <span className="u-els-font-size-meta">{getTotal()}%</span>
                        </FlexItem>
                      </FlexLayout>
                    </div>
                  </div>
                </FlexItem>
              </FlexLayout>

              <div>
                You took the exam, and now its time to make sure you’re on top of your game for next time.
                We&apos;ve analyzed your results and have put together a learning plan personalized to your specific needs.
              </div>

            </div>

            <div className="o-els-container o-els-container--2x">
              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.REVIEW}
                    assessmentGoals={state.assessment.assessmentGoals}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                  />
                </FlexItem>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.APPLY}
                    assessmentGoals={state.assessment.assessmentGoals}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                  />
                </FlexItem>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <RemediationActivityProgress
                    activityType={RemediationActivityType.ASSESS}
                    assessmentGoals={state.assessment.assessmentGoals}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                  />
                </FlexItem>
              </FlexLayout>

            </div>

            <div>

              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    assessmentGoals={state.assessment.assessmentGoals}
                    activityType={RemediationActivityType.REVIEW}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                    onTaxonClick={handleTaxonClick}
                    onRecommendationClick={null}
                  />
                </div>
              </div>
              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    assessmentGoals={state.assessment.assessmentGoals}
                    activityType={RemediationActivityType.APPLY}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                    onTaxonClick={handleTaxonClick}
                    onRecommendationClick={null}
                  />
                </div>
              </div>
              <div className="c-ssa-remediation-home__tab">
                <div className="c-ssa-remediation-home__tab-content">
                  <RemediationActivitySection
                    assessmentGoals={state.assessment.assessmentGoals}
                    activityType={RemediationActivityType.ASSESS}
                    recommendations={state.recommendations}
                    recommendationStatusMap={state.recommendationStatusMap}
                    recommendationItems={state.recommendationItems}
                    recommendationItemsStatusMap={state.recommendationItemsStatusMap}
                    onTaxonClick={null}
                    onRecommendationClick={handleRecommendationClick}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Remediation Home' }),
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationHome = compose<null, RemediationHomePropsOnly>(...enhancers)(RemediationHomeComponent);

export default RemediationHome;
