import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  EolsUserHistoryDto,
  EolsUserHistoryResponseDto,
  CoursewareUserHistoryStateKey
} from './eols-user-crud.dtos';

export const postUserHistory = (eolsUserHistoryDTO: EolsUserHistoryDto): Promise<EolsUserHistoryResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/eolsUserHistory', { data: eolsUserHistoryDTO });
  });
};

export const deleteUserHistoryByUserId = (props: {
  userId: number;
  instanceId?: string;
}) => {

  const {
    userId,
    instanceId
  } = props;

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.delete(addSearchParams(`/eolsUserHistory/eolsUserId/${userId}`, { instanceId }));
  });
};

export const fetchUserHistoryByCourseByStateKey = (props: {
  userId: number;
  stateKey: CoursewareUserHistoryStateKey | string;
  courseSectionId: number;
  instanceId?: string;
}): Promise<EolsUserHistoryResponseDto[]> => {
  const {
    userId,
    stateKey,
    courseSectionId,
    instanceId
  } = props;
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/eolsUserHistory/user/${userId}/courseSection/${courseSectionId}/state/${stateKey}`, { instanceId }));
  });
};
