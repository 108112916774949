import {
  ELSRestClientHelper,
  ELSCommonConfig,
  ELSTokenHelper,
  ELSLoggingService
} from '@els/els-ui-common-react';
import { AxiosResponse } from 'axios';

const fileName = 'api.utilities';

export const buildUrlFromBase = (
  url: string,
  addBaseUrl = true,
  includeApiPath = true
) => {
  if (!addBaseUrl) {
    return url;
  }
  if (!includeApiPath) {
    return `${ELSCommonConfig.buildUrl}${url}`;
  }
  return `${ELSCommonConfig.buildUrl}/api${url}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withApiErrorLoggingOnly = <T extends any>(promiseFunction: () => Promise<AxiosResponse<T>>) =>
  promiseFunction()
    .then(res => res.data)
    .catch((error: Error) => {
      // https://github.com/elsevierPTG/els-ui-common-react/blob/develop/module/services/LoggingService.js
      ELSLoggingService.error(fileName, error.message, error);
      throw error;
    });

export const getDefaultRequestHeaders = (headers = {}) => {
  return {
    Authorization: `Bearer ${ELSTokenHelper.getToken()}`,
    accept: 'application/json, text/plain, */*',
    ...headers
  };
};

export const getDefaultRequestOptions = (options = {}, headers = {}) => {
  return {
    headers: getDefaultRequestHeaders(headers),
    ...options,
  };
};

enum RestCall {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

export type eolsBaseApiType = Record<
  RestCall,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T extends any>(
    url: string,
    options?: object,
    headers?: object,
    addBaseUrl?: boolean,
    includeApiPath?: boolean
  ) => Promise<AxiosResponse<T>>
>

const eolsBaseApi: eolsBaseApiType = Object.values(RestCall).reduce((acc, cur) => {
  return {
    ...acc,
    [cur]: (
      url,
      options = {},
      headers = {},
      addBaseUrl = true,
      includeApiPath = true,
    ) => {
      return ELSRestClientHelper[cur](buildUrlFromBase(url, addBaseUrl, includeApiPath), getDefaultRequestOptions(options, headers));
    },
  };
}, {} as eolsBaseApiType);

export {
  withApiErrorLoggingOnly,
  eolsBaseApi
};
