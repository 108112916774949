import React from 'react';
import { ResourceQtiDataDto } from '../../apis/ocs-api-service/ocs-api-service.dtos';

export interface HotspotQuestionDisplayProps {
  question: ResourceQtiDataDto;
}

export const HotspotQuestionDisplay = (props: HotspotQuestionDisplayProps) => {
  const {
    question
  } = props;

  return (
    <div>
      <h2
        role="presentation"
        className="o-els-container u-els-margin-top-2x u-els-margin-bottom-2x u-els-font-size-intro question-prompt__content question-prompt__content--selection"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: question.prompt }}
      />

      <div className="u-els-margin-top u-els-font-size-h4">
        {/* eslint-disable-next-line react/no-danger */}
        <p className="rationale__text" dangerouslySetInnerHTML={{ __html: question.feedback.CHOICE_000 }} />
      </div>
    </div>
  );

};
