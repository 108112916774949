import {
  isNil,
  orderBy,
  sortBy
} from 'lodash';
import { ELSDropDownOption } from '@els/els-component-form-field-react';
import React from 'react';
import { ALL_OPTION, zeroSeconds } from './eaq-student-topic-report.constants';
import { TopicWithLineage } from './eaq-student-topic-report.models';
import {
  EaqQuestionSetDto,
  EaqStudentPerformanceDto,
  EaqStudentTopicPerformanceDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { Messages } from '../../translations/message.models';
import { ELSModalServiceType } from '../../models/els.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';

export const getTimeSpentFormat = (allSeconds: number): string => {

  if (isNil(allSeconds) || isNaN(allSeconds)) {
    return zeroSeconds;
  }

  const hours = Math.floor(allSeconds / 3600);
  const minutes = Math.floor((allSeconds - (hours * 3600)) / 60);
  const seconds = allSeconds - (hours * 3600) - (minutes * 60);

  const displayArray = [`${seconds}${seconds !== 1 ? 'secs' : 'sec'}`];

  if (minutes >= 1) {
    displayArray.unshift(`${minutes}${minutes !== 1 ? 'mins' : 'min'}`);
  }

  if (hours >= 1) {
    displayArray.unshift(`${hours}${hours !== 1 ? 'hours' : 'hour'}`);
  }

  return displayArray.join(' ');
};

export const getDefaultQuestionSetIsbn = (questionSets: EaqQuestionSetDto[]): string => {
  if (!questionSets || !questionSets.length) {
    return null;
  }

  return questionSets[0].isbn;
};

export const flattenTreeWithLineage = (array: EaqStudentTopicPerformanceDto[], parentLineage: EaqStudentTopicPerformanceDto[] = []): TopicWithLineage[] => {
  return sortBy(array, (item: EaqStudentTopicPerformanceDto) => {
    return item.displayOrder;
  })
    .reduce((acc, cur: EaqStudentTopicPerformanceDto) => {
      const itemLineage = [...parentLineage, cur];
      if (cur.children && cur.children.length) {
        return [...acc, ...flattenTreeWithLineage(cur.children, itemLineage)];
      }
      return [...acc, {
        item: cur,
        lineage: itemLineage
      }];
    }, []);
};

export const getStudentPerformance = (topic: EaqStudentTopicPerformanceDto): EaqStudentPerformanceDto => {
  if (
    !topic
    || !topic.studentPerformances
    || !topic.studentPerformances.length
  ) {
    return {
      eolsUser: null,
      masteryLevel: null,
      questionsAnswered: null,
      questionsConfident: null,
      questionsCorrect: null,
      timeSpent: null,
    };
  }

  return topic.studentPerformances[0];
};
const getSortValue = (item: EaqStudentTopicPerformanceDto) => {
  return item.displayOrder + (item.displayName || item.text);
};

export const getPercentageAnsweredCorrectly = (studentPerformance: EaqStudentPerformanceDto): number => {
  if (
    !studentPerformance
    || isNaN(studentPerformance.questionsCorrect)
    || isNaN(studentPerformance.questionsAnswered)
  ) {
    return 0;
  }
  const percentage = studentPerformance.questionsCorrect / studentPerformance.questionsAnswered;
  if (isNaN(percentage)) {
    return 0;
  }
  return Math.floor(percentage * 100);
};

export const flattenedTopicReportTopics = (
  topicPerformances: EaqStudentTopicPerformanceDto[],
  activeTopicOrgVtwId: string
): TopicWithLineage[] => {
  if (!topicPerformances || !topicPerformances.length) {
    return null;
  }

  return flattenTreeWithLineage(
    topicPerformances.filter((item) => {
      if (!activeTopicOrgVtwId || activeTopicOrgVtwId === ALL_OPTION.value) {
        return true;
      }
      return item.vtwId === activeTopicOrgVtwId;
    })
  );
};

export const getTopicOrgOptions = (topicPerformances: EaqStudentTopicPerformanceDto[]): ELSDropDownOption[] => {
  const options = orderBy(topicPerformances, getSortValue).map((item) => {
    return {
      value: item.vtwId,
      name: item.displayName || item.text
    };
  });
  return [
    ALL_OPTION,
    ...options
  ];
};

export const withPageLoaderRequest = (promise, setState) => {
  setState((prevState) => {
    return {
      ...prevState,
      pendingRequestCount: prevState.pendingRequestCount + 1
    };
  });
  return promise.finally(() => {
    setState((prevState) => {
      return {
        ...prevState,
        pendingRequestCount: prevState.pendingRequestCount - 1
      };
    });
  });
};

export const convertMasteryScoreToPercentage = (score: number) => {
  let _score = score;

  const masteryMax = 4;

  if (isNil(_score)) {
    _score = 0;
  }

  if (_score < 0) {
    _score = 0;
  }

  if (_score > masteryMax) {
    _score = masteryMax;
  }

  _score /= masteryMax;

  if (isNaN(_score)) {
    _score = 0;
  }

  return _score * 100;
};

export const handleExplainerClick = (props: {
    messages: Messages;
    modalService: ELSModalServiceType;
}) => {
  const {
    messages,
    modalService
  } = props;
  const modalId = 'EXPLAINER_MODAL';
  modalService.openAlertModal({
    modalId,
    content: (
      <div className="">
        <h2 className="u-els-margin-bottom-2x">{messages.MASTERY_LEVELS}</h2>
        <div className="c-ssa-mastery-chart-key-table">
          <div className="c-ssa-mastery-chart-key-table__row">
            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
              <FlexItem>
                <div
                  className="c-ssa-mastery-chart-key-table__key c-ssa-mastery-chart-key-table__key--level-1" />
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h3>{messages.NOVICE}</h3>
                <p>{messages.NOVICE_EXPLAINER}</p>
              </FlexItem>
            </FlexLayout>
          </div>
          <div className="c-ssa-mastery-chart-key-table__row">
            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
              <FlexItem>
                <div
                  className="c-ssa-mastery-chart-key-table__key c-ssa-mastery-chart-key-table__key--level-2" />
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h3>{messages.INTERMEDIATE}</h3>
                <p>{messages.INTERMEDIATE_EXPLAINER}</p>
              </FlexItem>
            </FlexLayout>
          </div>
          <div className="c-ssa-mastery-chart-key-table__row">
            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
              <FlexItem>
                <div
                  className="c-ssa-mastery-chart-key-table__key c-ssa-mastery-chart-key-table__key--level-3" />
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h3>{messages.PROFICIENT}</h3>
                <p>{messages.PROFICIENT_EXPLAINER}</p>
              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>
    ),
    confirmHandler: () => {
      modalService.closeModal(modalId);
    },
  });
};
