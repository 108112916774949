import moment from 'moment';
import { ELSFormFieldValidationItem } from '../models/els.models';
import { VALIDATOR } from '../constants/validator.constants';

export const validateRequiredAlpha2Chars = text => {
  const reg = /^[A-Z]{2}$/;
  return reg.test(text);
};

export const validateRequiredAlphaNumeric2Chars = text => {
  const reg = /^[a-z0-9]{2}$/i;
  return reg.test(text);
};

export const validateRequiredAlphaNumericMax2Chars = text => {
  const reg = /^[a-z0-9]{1,2}$/i;
  return reg.test(text);
};

export const validateDateNotInFuture = (momentDate, now = moment()) => {
  const inPast = momentDate.diff(now, 'days') < 0;
  const sameDay = momentDate.diff(now, 'days') === 0 && momentDate.isSame(now, 'day');
  return inPast || sameDay;
};

export const asPromise = value => Promise.resolve(value);

export const validateStringLength = (value: string, maxLength: number, message: string) => (): ELSFormFieldValidationItem => {
  return {
    id: VALIDATOR.VALID_STRING_LENGTH,
    value,
    content: message,
    isValid: value ? value.length <= maxLength : true,
  };
};

export const validateStringMinLength = (value: string, minLength: number, message: string) => (): ELSFormFieldValidationItem => {
  return {
    id: VALIDATOR.VALID_STRING_MIN_LENGTH,
    value,
    content: message,
    isValid: value ? value.length >= minLength : false,
  };
};

export const isInvalidLength = (value: string, maxLength: number): boolean => {
  if (!value || value.length === 0) {
    return true;
  }
  return value && value.length > maxLength;
};
