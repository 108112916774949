import React from 'react';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { ELSIcon } from '../../components/els.components';

export type RemediationHeaderProps = {
  handleReturnNavigation: () => void;
};

const RemediationHeader = (props: RemediationHeaderProps) => {
  const {
    handleReturnNavigation,
  } = props;

  return (
    <div className="c-ssa-remediation-home__header">
      <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS]}>
        <FlexItem>
          <button
            onClick={handleReturnNavigation}
            type="button"
            className="u-els-anchorize u-els-display-block"
          >
            <ELSIcon
              size="1x"
              prefix="hmds"
              name="close"
              customClass="u-els-display-block"
            />
          </button>
        </FlexItem>
        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
          <h1 className="u-els-font-size-h3">Study Assignment</h1>
        </FlexItem>

      </FlexLayout>

    </div>
  );
};
export default RemediationHeader;
