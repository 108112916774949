import React from 'react';
import {
  ELSMultipleAnswer,
  ELSSingleBestAnswer,
  ELSFillTheBlank,
  ELSMatchingBasic
} from '@els/els-component-assessment-react';
import { QuestionType } from '../../constants/assessment.constants';
import { QtiDataDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { OrderQuestionDisplay } from '../../components/order-question-display/OrderQuestionDisplay.component';
import { HotspotQuestionDisplay } from '../../components/hotspot-question-display/HotspotQuestionDisplay.component';

export type QuizItemPreviewProps = {
  qtiData: QtiDataDto;
};

export const QuizItemPreview = (props: QuizItemPreviewProps) => {

  const { qtiData } = props;

  if (!qtiData) {
    return null;
  }

  const rationaleTypeBlock = 'block';
  const question = qtiData;
  let component = (
    <div>
      <div>{question.questionType}</div>
      <div>{question.prompt}</div>
    </div>
  );

  switch (question.questionType) {
    case QuestionType.MCMA:
      component = (
        <ELSMultipleAnswer
          questionPrompt={question.prompt}
          questionChoices={question.responseChoices}
          questionFeedback={question.feedback}
          correctAnswers={question.correctResponse}
          selectedAnswers={question.correctResponse}
          isShowSuccessFlagAllCorrectAnswer
          rationaleType={rationaleTypeBlock}
        />
      );
      break;
    case QuestionType.MCSA:
      component = (
        <ELSSingleBestAnswer
          questionPrompt={question.prompt}
          questionChoices={question.responseChoices}
          questionFeedback={question.feedback}
          correctAnswers={question.correctResponse}
          selectedAnswers={question.correctResponse}
          isShowSuccessFlagAllCorrectAnswer
          rationaleType={rationaleTypeBlock}
        />
      );
      break;
    case QuestionType.FILL_IN_BLANK:
      component = (
        <ELSFillTheBlank
          questionPrompt={question.prompt}
          questionChoices={question.responseChoices}
          questionFeedback={question.feedback}
          rationaleType={rationaleTypeBlock}
          isShowSuccessFlagAllCorrectAnswer
          rationaleBlockFeedback
          textReference={question.textReference}
          correctAnswers={question.correctResponse}
          selectedAnswers={(question.correctResponse as string[]).map(correctChoice => {
            if (Array.isArray(question.responseChoices[correctChoice])) {
              const value = question.responseChoices[correctChoice];
              return value[0];
            }
            return question.responseChoices[correctChoice];
          })}
        />
      );
      break;
    case QuestionType.MATCH_LABEL_TEXT: {
      // Currently this is not supported by EAQ
      // Leaving it in for a future day
      component = (
        <ELSMatchingBasic
          questionPrompt={question.prompt}
          questionPromptHeadingTag="h2"
          questionChoices={question.responseChoices}
          targetQuestionChoices={question.targetResponseChoices}
          selectedAnswers={question.correctResponse}
          correctAnswers={question.correctResponse}
          updateCurrentTargetQuestionChoice={() => true}
          showCheckAnswerButtons={() => true}
          onSelectedAnswer={() => true}
        />
      );
      break;
    }
    case QuestionType.ORDER: {
      component = (
        <OrderQuestionDisplay
          question={question}
        />
      );
      break;
    }
    case QuestionType.HOTSPOT: {
      component = (
        <HotspotQuestionDisplay
          question={question}
        />
      );
      break;
    }
    default:
      break;
  }
  return component;

};

export default QuizItemPreview;
