import React from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { orderBy } from 'lodash';
import {
  ChatReferenceDto,
  ChatReferenceWithParsedContentProps,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import {
  getAfterChapterString,
  getCitationGroups,
  getPageNumber
} from './ai-chat.utilities';

export type AiChatEntryCitationProps = {
  citations: ChatReferenceDto[];
  isCitationLinkEnabled: boolean;
  isDirectAccessByFeatureFlag: boolean;
  isDirectAccessByIsbn: boolean;
  entitlements: Record<string, string>;
  handleClick: (citation: ChatReferenceDto) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AiChatEntryCitation = (props: AiChatEntryCitationProps) => {

  const {
    citations,
    isCitationLinkEnabled,
    handleClick,
    isDirectAccessByFeatureFlag,
    isDirectAccessByIsbn,
    entitlements
  } = props;

  if (!citations || !citations.length) {
    return null;
  }

  const isDirectAccess = isDirectAccessByFeatureFlag || isDirectAccessByIsbn;

  const isCitationNotLinked = (citation: ChatReferenceWithParsedContentProps) => {

    if (!isCitationLinkEnabled) {
      return true;
    }

    if (isDirectAccess) {
      return true;
    }

    if (!entitlements) {
      return true;
    }

    const eeoIsbns = Object.keys(entitlements);

    return !eeoIsbns.some((isbn) => {
      return isbn === citation._contentProps.source_id;
    });

  };

  const renderBookLink = (content: string, citation: ChatReferenceWithParsedContentProps) => {
    return (
      <button
        type="button"
        className="u-els-anchorize u-els-debuttonize"
        onClick={() => {

          handleClick(citation);
        }}
      >
        {content}
      </button>
    );
  };

  const renderBreadcrumbs = (citation: ChatReferenceWithParsedContentProps) => {

    if (
      !citation
      || !citation.contentProps
      || !citation._contentProps
      || !citation._contentProps.bread_crumbs
    ) {
      return null;
    }

    const chapter = citation._contentProps.bread_crumbs.find((breadcrumb) => {
      return breadcrumb.type === 'CHAPTER';
    });

    const afterChapterString = getAfterChapterString(citation);
    const chapterTitleDisplay = chapter && chapter.title
      ? <span className="u-els-margin-right-1o4">{chapter.title}</span>
      : null;

    if (!chapterTitleDisplay && !afterChapterString) {
      return null;
    }

    if (chapterTitleDisplay && !afterChapterString) {
      if (isCitationNotLinked(citation)) {
        return chapterTitleDisplay;
      }
      return renderBookLink(chapter.title, citation);
    }

    if (isCitationNotLinked(citation)) {
      return (
        <>
          {chapterTitleDisplay}
          <span className="u-els-color-n7">{afterChapterString}</span>
        </>
      );
    }

    return (
      <>
        {chapterTitleDisplay}
        {renderBookLink(afterChapterString, citation)}
      </>
    );

  };

  const groups = getCitationGroups(citations);

  return (
    <>
      {
        Object.keys(groups).map((resourceId) => {
          return (
            <div
              key={resourceId}
              className="c-els-ai-chat__entry-reference"
            >
              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_1o2,
                FlexLayoutModifier.LEFT,
                FlexLayoutModifier.TOP
              ]}>
                <FlexItem>
                  <div>
                    <ELSIcon
                      size="1x1o4"
                      prefix="hmds"
                      name="ebook"
                    />
                  </div>
                </FlexItem>
                <FlexItem classes={['u-cw-min-width-0']}>
                  <div className="u-els-margin-bottom-1o2">{groups[resourceId][0].resourceTitle}</div>
                  <div>
                    <table className="c-els-ai-chat__citation-table">
                      <tbody>
                        {
                          orderBy(groups[resourceId], (citation: ChatReferenceWithParsedContentProps) => {
                            return getPageNumber(citation);
                          }).map((citation) => {
                            return (
                              <tr key={citation.id}>
                                <td className="c-els-ai-chat__citation-table-col-1">
                                  <span className="u-els-nowrap">p {getPageNumber(citation)}</span>
                                </td>
                                <td className="c-els-ai-chat__citation-table-col-2">
                                  <span className="u-els-color-n7 u-els-nowrap">Ch {citation._contentProps.chapter_number}:</span>
                                </td>
                                <td>
                                  {renderBreadcrumbs(citation)}
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </FlexItem>
              </FlexLayout>
            </div>
          );
        })
      }
    </>
  );

};
