import { ELSDropDownOption } from '@els/els-component-form-field-react';
import { SELECT_OPTION } from '../../../utilities/app.utilities';

export const masteryLevelsDropdownOptions: ELSDropDownOption[] = [
  SELECT_OPTION,
  { name: 'Novice', value: 1 },
  { name: 'Intermediate', value: 2 },
  { name: 'Proficient', value: 3 },
  { name: 'Limitless', value: 4 }
];
