import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  LearningAnalyticsDto,
  LearningAnalyticsRequestDto
} from './learning-analytics-service.dtos';
import { stripNilProps } from '../../utilities/common.utilities';

export const fetchAnalyticsContext = (analyticsRequestDto: LearningAnalyticsRequestDto): Promise<LearningAnalyticsDto> => {
  const data = {
    action: analyticsRequestDto.action,
    filterFrom: analyticsRequestDto.filterFrom || null,
    filterTo: analyticsRequestDto.filterTo || null,
    resources: analyticsRequestDto.resources,
    context: analyticsRequestDto.context
  };
  const _data = stripNilProps(data);
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/analyticsContext', { data: _data });
  });
};
