import React, { useState } from 'react';
import { ELSModalService } from '@els/els-component-modal-react';
import { ELSToastService, } from '@els/els-component-toast-react';
import {
  ELSDropDown,
  ELSDropDownOption,
  ELSIcon,
  ELSRadio
} from '@els/els-component-form-field-react';
import { AnalyticsActionProps } from '../../models/analytics.models';
import { RecommendationDto, } from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import {
  ActivityTypeConfigMap,
  RemediationActivityType,
  RemediationGoalType,
  RemediationStatusCounts,
} from './remediation-home.models';
import RemediationRecommendation from './RemediationRecommendation.component';
import {
  getAssessmentGoalsTotals,
  getContentTypeDisplay,
  getContentTypes
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import { ELSProgressBar } from '../../components/els.components';

export const RemediationAssessmentGoalModalId = 'RemediationCategoryModalId';

enum ViewOptionValue {
  TOP_RECOMMENDATION = 'TOP_RECOMMENDATION',
  ALL_RECOMMENDATIONS = 'ALL_RECOMMENDATIONS'
}

const viewOptions: ELSDropDownOption[] = [{
  name: 'Top recommendation',
  value: ViewOptionValue.TOP_RECOMMENDATION
}, {
  name: 'All recommendations',
  value: ViewOptionValue.ALL_RECOMMENDATIONS
}];

enum SkipOptionValue {
  ALREADY = 'ALREADY',
  NO_SENSE = 'NO_SENSE',
  WANT_NEXT = 'WANT_NEXT',
  WANT_OTHER = 'WANT_OTHER',
  OTHER = 'OTHER',
}

const skipOptions: ELSDropDownOption[] = [{
  name: 'I have already completed this activity prior to taking the exam.',
  value: SkipOptionValue.ALREADY
}, {
  name: 'This activity doesn’t make sense for the exam contents.',
  value: SkipOptionValue.NO_SENSE
}, {
  name: 'I want to see the next choice.',
  value: SkipOptionValue.WANT_NEXT
}, {
  name: 'I just want to see all the choices.',
  value: SkipOptionValue.WANT_OTHER
}, {
  name: 'Other',
  value: SkipOptionValue.OTHER
}];

export type RemediationAssessmentGoalModalProps = {
  modalService: ELSModalService;
  toastService: ELSToastService;
  trackAction: (props: AnalyticsActionProps) => void;
  redirect: (path: string, state?: Record<string, string | number | boolean>) => void;
  recommendations: RecommendationDto[];
  assessmentGoal: AssessmentGoalDto;
  activityType: RemediationActivityType;
  statusMap: Record<string, RemediationStatusCounts>;
}

export type RemediationAssessmentGoalModalState = {
  activeView: ViewOptionValue;
  activeRecommendation: RecommendationDto;
  showSkipForm: boolean;
  recommendations: RecommendationDto[];
}

const defaultState: RemediationAssessmentGoalModalState = {
  activeView: ViewOptionValue.TOP_RECOMMENDATION,
  activeRecommendation: null,
  showSkipForm: false,
  recommendations: null
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const RemediationAssessmentGoalModal = (props: RemediationAssessmentGoalModalProps) => {

  const totals = getAssessmentGoalsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: props.assessmentGoal.type as RemediationActivityType,
      recommendations: props.recommendations,
      statusMap: props.statusMap
    }
  );

  const [state, setState] = useState<RemediationAssessmentGoalModalState>(
    {
      ...defaultState,
      recommendations: props.recommendations,
      activeRecommendation: props.recommendations[0],
      activeView: (totals.completed >= totals.total || props.recommendations.length === totals.total)
        ? ViewOptionValue.ALL_RECOMMENDATIONS
        : ViewOptionValue.TOP_RECOMMENDATION
    }
  );

  const mergeState = (newState: Partial<RemediationAssessmentGoalModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationAssessmentGoalModalId);
  };

  const handleSkipSubmit = () => {
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({
      activeRecommendation: nextRec,
      showSkipForm: false
    });
  };
  const handleNextRecClick = () => {
    if (!state.activeRecommendation.recommendationData.skipReason) {
      mergeState({ showSkipForm: true });
      return;
    }
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({ activeRecommendation: nextRec });
  };

  const getPrevRec = (): RecommendationDto => {
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) - 1;
    return state.recommendations[nextIndex] || state.recommendations[state.recommendations.length - 1];
  };

  const handlePrevRecClick = () => {
    const prevRec = getPrevRec();
    mergeState({ activeRecommendation: prevRec });
  };

  const isPrevRecDisabled = (): boolean => {
    const prevRec = getPrevRec();
    return !prevRec.recommendationData.skipReason;
  };

  const contentTypes = getContentTypes(props.activityType);

  const goalType = props.assessmentGoal.vtwId.split('::')[0] as RemediationGoalType;

  return (
    <div>
      <div className="c-els-modal c-els-modal--full">
        <div className="c-els-modal__window u-els-width-1o1@mobile">
          <div className={`c-ssa-remediation-assessment-goal-modal c-ssa-remediation-assessment-goal-modal--${props.assessmentGoal.type.toLowerCase()}`}>

            <div className="u-els-margin-bottom-2x">

              <FlexLayout
                modifiers={[FlexLayoutModifier.TOP, FlexLayoutModifier.GUTTERS]}
              >
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <h2><strong>{ActivityTypeConfigMap[props.assessmentGoal.type].title}</strong> / {props.assessmentGoal.text}</h2>
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="c-els-modal__close"
                    onClick={handleCancelClick}
                  >
                    <ELSIcon name="close" size="1x" />
                    <span className="u-els-hide-visually">Close this modal window</span>
                  </button>
                </FlexItem>
              </FlexLayout>

              <div className="u-els-margin-top c-els-divider" />
            </div>

            {
              totals.completed >= totals.total && (
                <div className="c-ssa-remediation-assessment-goal-modal__complete">
                  <h3>Great work! You’ve completed all {totals.total} requirements.</h3>
                  <div>
                    Any remaining work will not count toward your requirements.
                    You can review any of the additional {getContentTypeDisplay(contentTypes).toLowerCase()} as optional.
                  </div>
                </div>
              )
            }

            <div className="o-els-container">
              <FlexLayout
                modifiers={[FlexLayoutModifier.TOP, FlexLayoutModifier.GUTTERS_2X]}
              >
                <FlexItem
                  modifiers={[FlexLayoutModifier.GROW]}
                  isRender={totals.completed < totals.total && goalType === RemediationGoalType.TAXON}
                >
                  Review <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> for this topic.
                  We have retrieved relevant review content based on the questions you have missed in this topic.
                </FlexItem>
                <FlexItem
                  modifiers={[FlexLayoutModifier.GROW]}
                  isRender={totals.completed < totals.total && goalType === RemediationGoalType.CONTENT_TYPE}
                >
                  Complete <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> to complete requirements for this section.
                </FlexItem>
                <FlexItem
                  modifiers={[FlexLayoutModifier.GROW]}
                  isRender={totals.completed >= totals.total}
                >
                  <h4>All available {ActivityTypeConfigMap[props.assessmentGoal.type].title.toLowerCase()} content</h4>
                </FlexItem>
                <FlexItem>
                  <div className="c-ssa-remediation-assessment-goal-modal__progress">
                    <div className="u-els-margin-bottom-1o2">
                      {totals.completed} / {totals.total} completed
                    </div>
                    <div>
                      <ELSProgressBar progressNum={totals.completed} totalNum={totals.total} />
                    </div>
                  </div>
                </FlexItem>
              </FlexLayout>
            </div>

            {totals.completed < totals.total && props.recommendations.length > totals.total && (
              <div className="o-els-container o-els-container--2x">
                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
                  <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                    {
                      state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                        <>
                          The first one we suggest you review is below:
                        </>
                      )
                    }
                  </FlexItem>
                  <FlexItem>

                    <FlexLayout modifiers={[
                      FlexLayoutModifier.GUTTERS,
                      FlexLayoutModifier.MIDDLE,
                    ]}>
                      <FlexItem>
                        Currently viewing:
                      </FlexItem>
                      <FlexItem>
                        <ELSDropDown
                          value={state.activeView}
                          options={viewOptions}
                          changeHandler={() => {
                            mergeState({
                              activeView: state.activeView === ViewOptionValue.TOP_RECOMMENDATION
                                ? ViewOptionValue.ALL_RECOMMENDATIONS
                                : ViewOptionValue.TOP_RECOMMENDATION
                            });
                          }}
                        />
                      </FlexItem>
                    </FlexLayout>
                  </FlexItem>
                </FlexLayout>
              </div>
            )}

            {
              state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="c-ssa-remediation-assessment-goal-modal__card-wrap">
                  <div className="c-ssa-remediation-assessment-goal-modal__skip-form">
                    <div className="o-els-container">Why did you skip the previous recommendation?</div>
                    {skipOptions.map((option) => {
                      return (
                        <div
                          className="o-els-container"
                          key={option.value}
                        >
                          <ELSRadio
                            changeHandler={(e, value) => {
                              setState((prevState) => {
                                const updatedRec = {
                                  ...prevState.activeRecommendation,
                                  recommendationData: {
                                    ...prevState.activeRecommendation.recommendationData,
                                    skipReason: value
                                  }
                                };
                                return {
                                  ...prevState,
                                  recommendations: prevState.recommendations.map((rec) => {
                                    if (rec.id === updatedRec.id) {
                                      return updatedRec;
                                    }
                                    return rec;
                                  }),
                                  activeRecommendation: updatedRec
                                };
                              });
                            }}
                            name={option.value as string}
                            value={option.value}
                            checked={state.activeRecommendation.recommendationData.skipReason === option.value}
                          >
                            {option.name}
                          </ELSRadio>
                        </div>
                      );
                    })}

                  </div>
                  <div className="u-els-margin-top">
                    <FlexLayout modifiers={[FlexLayoutModifier.RIGHT]}>
                      <FlexItem>
                        <button
                          type="button"
                          className="c-els-button c-els-button--small"
                          onClick={() => {
                            handleSkipSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              !state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="c-ssa-remediation-assessment-goal-modal__card-wrap">

                  {state.activeRecommendation && (
                    <div className="o-els-container">
                      <RemediationRecommendation
                        recommendation={state.activeRecommendation}
                        isExpanded
                        onTitleClick={() => {
                          // eslint-disable-next-line no-alert
                          alert('launch content');
                        }}
                      />
                    </div>
                  )}

                  <div className="o-els-container">
                    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        {
                          !isPrevRecDisabled() && (
                            <button
                              type="button"
                              className="u-els-anchorize"
                              onClick={handlePrevRecClick}
                            >
                              <IconWithText
                                iconName="arrow-left"
                                iconPrefix="hmds"
                                iconPosition={ELSButtonIconPosition.LEFT}
                              >
                                Previous Suggestion
                              </IconWithText>
                            </button>
                          )
                        }
                      </FlexItem>
                      <FlexItem>
                        <button
                          type="button"
                          className="u-els-anchorize"
                          onClick={handleNextRecClick}
                        >
                          <IconWithText
                            iconName="arrow-right"
                            iconPrefix="hmds"
                            iconPosition={ELSButtonIconPosition.RIGHT}
                          >
                            Next Suggestion
                          </IconWithText>
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.ALL_RECOMMENDATIONS && (
                <div className="o-els-container o-els-container--2x">
                  <div className="c-ssa-remediation-assessment-goal-modal__list">
                    {state.recommendations && state.recommendations
                      .map((recommendation) => {
                        return (
                          <div
                            key={recommendation.id}
                            className="c-ssa-remediation-assessment-goal-modal__list-item"
                          >
                            <RemediationRecommendation
                              recommendation={recommendation}
                              isExpanded={recommendation.id === state.activeRecommendation.id}
                              onTitleClick={() => {
                                mergeState({ activeRecommendation: recommendation });
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="u-els-margin-top-3x">
                  <IconWithText
                    iconName="information"
                    iconPrefix="gizmo">
                    Learn more about how we&apos;ve personalized your plan and review content suggestions.
                  </IconWithText>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default RemediationAssessmentGoalModal;
