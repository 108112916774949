// This is temporary model until we know what the primary taxonomy DTO looks like from APIs

import { EvolveProductType } from '../sherpath-app-facade-service/sherpath-app-facade-service.dtos';

export enum PrimaryTaxonomyNodeType {
  BOOK = 'BOOK',
  CHAPTER = 'CHAPTER',
  TOPIC = 'TOPIC',
}

export interface PrimaryTaxonomyTaxonAttributesDto {
  displayName: string;
  displayOrder: number;
  isLeaf: boolean;
  isRoot: boolean;
  level: number;
  nodeType: PrimaryTaxonomyNodeType;
  text: string;
  pageRange: {
    startRange: number;
    endRange: number;
  };
  // priority is used to distinguish primary taxonomies that should be used in the course builder
  // only priority 1 primary taxonomies should be used for course building
  priority: number;
}

export enum PrimaryTaxonomyType {
  TAXONOMY = 'Taxonomy'
}

export interface PrimaryTaxonomyTaxonDto {
  type: PrimaryTaxonomyType;
  id: string;
  attributes: Partial<PrimaryTaxonomyTaxonAttributesDto>;
  relationships: {
    children?: Partial<PrimaryTaxonomyDto>;
  };
  links: {};
}

export interface PrimaryTaxonomyDto {
  data: Partial<PrimaryTaxonomyTaxonDto>[];
  included: PrimaryTaxonomyTaxonDto[];
  errors: [];
  links: {};
  meta: {};
}

export interface PrimaryTaxonomy {
  taxonomy: PrimaryTaxonomyDto;
  isbn: string;
  isbnType: EvolveProductType;
}

export enum RecLearningType {
  SIM_CHART = 'SIM_CHART',
  CASE_STUDY = 'CASE_STUDY',
  QUESTION = 'QUESTION',
  ADAPTIVE_LESSON = 'ADAPTIVE_LESSON',
  SHERPATH_LESSON = 'SHERPATH_LESSON',
  HESI_EXAM = 'HESI_EXAM',
  SHERPATH_MODULE = 'SHERPATH_MODULE',
  EBOOK_READING = 'EBOOK_READING',
  SHERPATH_POWERPOINT = 'SHERPATH_POWERPOINT',
  SHERPATH_GROUP_ACTIVITY = 'SHERPATH_GROUP_ACTIVITY',
  SHERPATH_CASE_STUDY = 'SHERPATH_CASE_STUDY',
}
