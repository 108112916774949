/* eslint-disable sonarjs/no-duplicate-string */

import { orderBy } from 'lodash';
import {
  RecommendationDto,
  RecommendationItemDto,
  RemediationAssignmentDto,
  RemediationAssignmentSettingsDto,
  RemRecContentTypeDto,
  RemRecItemTypeDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { RemediationHomeProps } from './RemediationHome.page';
import {
  RemediationActivityType,
  RemediationContentTypeConfigMap,
  RemediationGoalType,
  RemediationStatusCounts
} from './remediation-home.models';
import {
  AssessmentDto,
  AssessmentGoalDto,
  AssessmentStatusDto,
  AssessmentTypeDto,
  AssignmentDto,
  AssignmentGradeType,
  AssignmentScoreType,
  AssignmentTargetType,
  AssignmentType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export const getParentLinkId = (props: RemediationHomeProps): string => {
  const { appLinkData, appLinkCookies } = props;
  if (appLinkData && appLinkData.parentLinkId) {
    return appLinkData.parentLinkId;
  }
  return appLinkCookies ? appLinkCookies.linkId : null;
};

export const generateRecommendation = (
  contentType: RemRecContentTypeDto,
  taxonId: string,
): RecommendationDto => {
  const id = Math.floor(Math.random() * 10000000);
  return {
    id,
    assessmentId: -1,
    userId: -1,
    courseSectionId: -1,
    contentType,
    contentId: 'abc',
    status: 'TODO',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recommendationData: {
      title: `Recommendation example title for ${contentType} (topic: ${taxonId} / id:${id})`,
      timeEstimateSeconds: 500,
      topics: ['Topic 1', 'Topic 2'],
      skipReason: null,
      subtitle: 'This is an example longer form description of the content item'
    },
    remediationAssignment: null,
    createdAt: null,
    updatedAt: null,
    taxonId
  };
};

export const generateMockAssignment = (): AssignmentDto => {
  return {
    assignmentGoals: [],
    assignmentGradeType: AssignmentGradeType.NOT_GRADED,
    assignmentOptions: [],
    assignmentQuestions: [],
    assignmentScoreType: AssignmentScoreType.BEST,
    assignmentTopics: [],
    assignmentType: AssignmentType.STUDENT_STUDY,
    availableDate: '2024-10-01',
    courseSectionId: null,
    createdAt: null,
    dueDate: '2024-12-01',
    elsAssignmentId: 'mockAssignmentId',
    endDate: '2021-01-01',
    gradePoints: 100,
    id: 1,
    isbn: null,
    lmsAssignmentGuid: null,
    sendResultToGradebook: false,
    studentAssignments: [],
    students: [],
    studyMode: false,
    targetType: AssignmentTargetType.COURSE,
    title: 'Example Remediation Assignment Title',
    subtitle: null,
    updatedAt: null,
    userId: 1,
    contentId: null
  };
};

export const generateMockRemediationAssignment = (): RemediationAssignmentDto => {
  return {
    id: 1,
    assignmentId: 1,
    createdByUser: 1,
    updatedByUser: 1,
    courseSectionId: 1,
    settings: {} as RemediationAssignmentSettingsDto,
    createdAt: '2021-01-01',
    updatedAt: '2021-01-01'
  };
};

export const generateMockAssessment = (): AssessmentDto => {
  return {
    id: 1,
    assignmentId: 1,
    assessmentGoals: [{
      goal: 3,
      text: 'Anxiety and mood disorder',
      vtwId: `${RemediationGoalType.TAXON}::A`,
      type: RemediationActivityType.REVIEW,
      optional: false
    }, {
      goal: 2,
      text: 'Another topic here',
      vtwId: `${RemediationGoalType.TAXON}::B`,
      type: RemediationActivityType.REVIEW,
      optional: false
    }, {
      goal: 3,
      text: 'Case Studies',
      vtwId: `${RemediationGoalType.CONTENT_TYPE}::CS`,
      type: RemediationActivityType.APPLY,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: `${RemediationGoalType.CONTENT_TYPE}::Q1`,
      type: RemediationActivityType.ASSESS,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: `${RemediationGoalType.CONTENT_TYPE}::Q2`,
      type: RemediationActivityType.ASSESS,
      optional: false
    }],
    assessmentTopics: null,
    studentAssignment: null,
    userId: 1,
    courseSectionId: 1,
    type: AssessmentTypeDto.STUDENT_STUDY,
    lessonId: null,
    studyMode: false,
    isbn: null,
    contentId: null,
    status: AssessmentStatusDto.IN_PROGRESS
  };
};

export const generateRecommendations = (
  checklistItems: AssessmentGoalDto[],
): RecommendationDto[] => {

  return checklistItems.reduce((acc, cur) => {
    let numOfRecs = cur.goal;
    let contentType;
    if (cur.type === RemediationActivityType.REVIEW) {
      contentType = RemRecContentTypeDto.EBOOK_CHUNK;
      numOfRecs += 5;
    }
    if (cur.type === RemediationActivityType.APPLY) {
      contentType = RemRecContentTypeDto.HESI_CASE_STUDY;
    }
    if (cur.type === RemediationActivityType.ASSESS) {
      contentType = RemRecContentTypeDto.EAQ;
    }
    const recommendations = Array.from({ length: numOfRecs }, () => {
      return generateRecommendation(
        contentType,
        cur.vtwId
      );
    });
    return acc.concat(recommendations);
  }, []);

};

export const generateRecommendationItems = (
  recommendations: RecommendationDto[],
): RecommendationItemDto[] => {
  return recommendations.reduce((acc, rec) => {
    if (rec.contentType !== RemRecContentTypeDto.EAQ) {
      return acc;
    }
    const items = Array.from({ length: 60 }, () => {
      return {
        id: Math.floor(Math.random() * 10000000),
        itemType: RemRecItemTypeDto.EAQ_QUESTION,
        itemId: 'abc',
        itemData: null,
        remediationRecommendation: rec,
        createdAt: null,
        updatedAt: null
      };
    });
    return [...acc, ...items];
  }, []);
};

export const generateRecommendationStatusMap = (
  recommendations: RecommendationDto[],
): Record<string, {
  completed: number;
  total: number;
  attempted: number;
}> => {
  return recommendations.reduce((acc, cur) => {
    const attempted = Math.random() > 0.5 ? 1 : 0;
    const completed = attempted === 1 && Math.random() > 0.5 ? 1 : 0;
    return {
      ...acc,
      [cur.id]: {
        completed,
        total: 1,
        attempted,
      }
    };
  }, {});
};

export const generateRecommendationItemStatusMap = (
  recommendationItems: RecommendationItemDto[],
): Record<string, {
  completed: number;
  total: number;
  attempted: number;
}> => {
  return recommendationItems.reduce((acc, cur) => {
    const attempted = Math.random() > 0.5 ? 1 : 0;
    const completed = attempted === 1 && Math.random() > 0.5 ? 1 : 0;
    return {
      ...acc,
      [cur.id]: {
        completed,
        total: 1,
        attempted
      }
    };
  }, {});
};

export const getHourAndMinuteFromSecond = (second: number): string => {
  if (second === 0) {
    return '0 min';
  }

  let minute = Math.floor(second / 60);
  let hour = 0;

  if (minute === 0) {
    return '1 min';
  }

  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute -= 60 * hour;

    if (minute === 0) {
      return `${hour} hr`;
    }

    return `${hour} hr ${minute} min`;
  }

  return `${minute} min`;
};

export const getAssessmentGoalsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    statusMap: Record<string, RemediationStatusCounts>;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    statusMap
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (assessmentGoal.type !== activityType) {
      return acc;
    }

    return recommendations.reduce((_acc, rec) => {

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (rec.taxonId !== assessmentGoal.vtwId) {
        return _acc;
      }

      const status = statusMap[rec.id];

      if (!status) {
        return _acc;
      }

      const newCompleted = _acc.completed + status.completed;
      const newAttempted = _acc.attempted + status.attempted;

      return {
        total: _acc.total,
        completed: newCompleted < _acc.total ? newCompleted : _acc.total,
        attempted: newAttempted
      };
    }, {
      ...acc,
      total: acc.total + assessmentGoal.goal
    });
  }, defaultTotal);
};

export const getAssessmentGoalsItemsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    recommendationItems: RecommendationItemDto[];
    statusMap: Record<string, RemediationStatusCounts>;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationItems,
    statusMap
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (assessmentGoal.type !== activityType) {
      return acc;
    }

    return recommendations.reduce((_acc, rec) => {

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (rec.taxonId !== assessmentGoal.vtwId) {
        return _acc;
      }

      const items = recommendationItems.filter((item) => item.remediationRecommendation.id === rec.id);

      return items.reduce((__acc, item) => {
        const status = statusMap[item.id];

        if (!status) {
          return __acc;
        }

        const newCompleted = __acc.completed + status.completed;
        const newAttempted = __acc.attempted + status.attempted;

        return {
          total: __acc.total,
          completed: newCompleted < __acc.total ? newCompleted : __acc.total,
          attempted: newAttempted
        };
      }, {
        ..._acc,
        total: _acc.total + items.length
      });

    }, acc);
  }, defaultTotal);
};

export const getAssessmentGoalsTotalsCombined = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    recommendationItems: RecommendationItemDto[];
    recommendationItemsStatusMap: Record<string, RemediationStatusCounts>;
    recommendationStatusMap: Record<string, RemediationStatusCounts>;
  }
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationItems,
    recommendationItemsStatusMap,
    recommendationStatusMap
  } = props;

  if (props.activityType === RemediationActivityType.ASSESS) {
    return getAssessmentGoalsItemsTotals(
      {
        assessmentGoals,
        activityType,
        recommendations,
        recommendationItems,
        statusMap: recommendationItemsStatusMap
      }
    );
  }

  return getAssessmentGoalsTotals({
    assessmentGoals,
    activityType,
    recommendations,
    statusMap: recommendationStatusMap
  });
};

export const getProgressDisplay = (totals: RemediationStatusCounts, activityType: RemediationActivityType) => {
  return activityType === RemediationActivityType.ASSESS
    ? `${Math.round((totals.completed / totals.total) * 100)}%`
    : `${totals.completed} / ${totals.total}`;
};

export const getContentTypes = (activityType: RemediationActivityType): RemRecContentTypeDto[] => {
  return Object.keys(RemRecContentTypeDto).filter((contentType: RemRecContentTypeDto) => {
    return RemediationContentTypeConfigMap[contentType] && RemediationContentTypeConfigMap[contentType].activityType === activityType;
  }) as RemRecContentTypeDto[];
};

export const getContentTypeDisplay = (contentTypes: RemRecContentTypeDto[]) => {
  return orderBy(contentTypes, (contentType) => RemediationContentTypeConfigMap[contentType].sortOrder)
    .map((contentType: RemRecContentTypeDto) => {
      return RemediationContentTypeConfigMap[contentType].titlePlural;
    }).join(' & ');
};
