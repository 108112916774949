import {
  Component,
  ErrorInfo,
} from 'react';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { errorUtils } from '../../utilities/error.utilities';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';

export type ErrorBoundaryProps = {
  trackAction: (props: AnalyticsActionProps) => void;
  redirect: (path: string) => void;
};

interface ErrorBoundaryState {
  error: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const {
      trackAction,
      redirect
    } = this.props;
    this.setState({
      error,
    });

    trackAction({
      action: AnalyticsAction.JS_ERROR,
      props: {
        errorName: error ? error.name : null,
        errorMessage: error ? error.message : null,
        errorStack: errorInfo ? errorInfo.componentStack : null
      }
    });

    errorUtils.logError({ err: error, previousState: errorInfo, previousStateParams: null });

    if (error && error.name === 'InvalidTokenError') {
      redirect(`/${ELSCommonUIConstants.security.States.NotAuthorized}`);
    }
  }

  handleRefresh = () => {
    const { trackAction } = this.props;
    trackAction({
      action: AnalyticsAction.JS_ERROR_PAGE_REFRESH,
      props: null
    });
    window.location.reload();
  }

  render() {

    if (!this.state.error) {
      return this.props.children;
    }

    return (
      <div className="c-ssa-error-boundary">
        <div className="c-ssa-error-boundary__box">
          <div>
            <ELSIcon name="alert-unexpected-error" size="4x" />
          </div>
          <h2>Apologies, something went wrong.</h2>
          <div className="o-els-container o-els-container--2x">

            <div className="o-els-container u-els-font-size-h4">
              Please refresh the page and try again.
            </div>
            <div className="o-els-container">
              <button
                type="button"
                onClick={this.handleRefresh}
                className="c-els-button c-els-button--primary c-els-button--small"
              >
                Refresh page
              </button>
            </div>
            <div className="o-els-container u-els-font-size-h4">
              If refreshing the page does not resolve the issue, please try clearing your browser cache, closing this browser tab/window, and relaunching the application.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
