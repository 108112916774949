import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  isNil,
} from 'lodash';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSPropsFromToastService,
} from '@els/els-component-toast-react';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { getObjectPropsArray } from '../../utilities/common.utilities';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  course: studySelectors.getCourse(state),
  user: studySelectors.getUser(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type SupportProps = PropsFromRedux & ELSPropsFromToastService;

export type SupportState = {}

export class Support extends Component<SupportProps, SupportState> {

  getCourseDataRows() {
    const { course } = this.props;
    if (!course) {
      return [];
    }

    return getObjectPropsArray(course, [], '');
  }

  getUserDataRows() {
    const { user } = this.props;
    if (!user) {
      return [];
    }

    return getObjectPropsArray(user, [], '');
  }

  renderRow = (row) => {
    return (
      <tr key={row.prop}
          className="c-els-table__row">
        <td className="c-els-table__cell">
          {row.prop}
        </td>
        <td className="c-els-table__cell">
          {isNil(row.val) ? null : row.val.toString()}
        </td>
      </tr>
    );
  }

  render() {
    const { messages } = this.props;
    const buildVersion = window.getBuildVersion ? window.getBuildVersion() : null;

    return (
      <>
        <h1>{messages.SUPPORT}</h1>

        <div className="o-els-container">
          <span>{`App Version: ${buildVersion}`}</span>
        </div>

        <div className="o-els-container">
          <h3>{messages.COURSE_INFO}</h3>
          <table className="c-els-table c-els-table--padding-1o2 c-els-table--vertical-dividers">
            <tbody className="c-els-table__body">
              {
              this.getCourseDataRows().map((row) => {
                return this.renderRow(row);
              })
            }
            </tbody>
          </table>
        </div>

        <div className="o-els-container">
          <h3>{messages.USER_INFO}</h3>
          <table className="c-els-table c-els-table--padding-1o2 c-els-table--vertical-dividers">
            <tbody className="c-els-table__body">
              {
              this.getUserDataRows().map((row) => {
                return this.renderRow(row);
              })
            }
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const enhancers = [
  connector,
  withHTMLHeadSEO({ title: 'Support' }),
  withPageLoader // This must come after connect
];

export default compose(...enhancers)(Support);
