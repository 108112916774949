import { inRange } from 'lodash';
import { AssessmentTypeDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';

export const isV2ApplinkingEnabledAndIsAuthessTypeQuiz = (
  quizType: AssessmentTypeDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string
): boolean => {
  const isV2AppLinkingEnabled = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(featureFlagsGrouped, FEATURE_FLAG.ENABLE_V2_AUTHESS_APP_LINKING, courseSectionId);

  return (quizType === AssessmentTypeDto.AUTHESS && isV2AppLinkingEnabled);
};

export const getGoalBasedOnMasteryLevel = (currentMasteryLevel: number): number => {
  if (inRange(currentMasteryLevel, 0.0, 1.0)) {
    return 1;
  }
  if (inRange(currentMasteryLevel, 1.0, 2.0)) {
    return 2;
  }
  if (inRange(currentMasteryLevel, 2.0, 3.0)) {
    return 3;
  }
  return 1000;
};
