import React from 'react';
import { isNil } from 'lodash';
import { DEFAULT_TAG, prefix } from './flex.constants';
import { FlexProps } from './flex.models';
import { generateFlexClasses } from './flex.utilities';

const defaultProps = {
  tag: DEFAULT_TAG
};

export const FlexItem = (props: FlexProps) => {

  const { tag, iterateKey, children, isRender } = props;

  if (!isNil(isRender) && !isRender) {
    return <></>;
  }

  const flexClasses = generateFlexClasses(props, prefix.ITEM);
  const _children = React.Children.count(children) === 1 && typeof children === 'string' ? <>{children}</> : children;
  const item = React.createElement(tag, { className: flexClasses, key: iterateKey }, _children);

  return (
    <>
      {item}
    </>
  );
};

FlexItem.defaultProps = defaultProps;
