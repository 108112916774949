/* eslint-disable react/prop-types */

import React from 'react';
import cx from 'classnames';
import { ELSIcon, } from '../../components/els.components';
import {
  ActivityTypeConfigMap,
  RemediationActivityType,
  RemediationContentTypeConfigMap,
  RemediationStatusCounts
} from './remediation-home.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RecommendationDto,
  RecommendationItemDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getAssessmentGoalsItemsTotals,
  getAssessmentGoalsTotals,
  getAssessmentGoalsTotalsCombined,
  getContentTypeDisplay,
  getContentTypes,
  getProgressDisplay
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export type RemediationActivitySectionProps = {
  activityType: RemediationActivityType;
  assessmentGoals: AssessmentGoalDto[];
  recommendations: RecommendationDto[];
  recommendationItems: RecommendationItemDto[];
  recommendationStatusMap: Record<string, RemediationStatusCounts>;
  recommendationItemsStatusMap: Record<string, RemediationStatusCounts>;
  onTaxonClick: (assessmentGoal: AssessmentGoalDto) => void;
  onRecommendationClick: (recommendation: RecommendationDto) => void;
};

const RemediationActivitySection = (props: RemediationActivitySectionProps) => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationStatusMap,
    recommendationItems,
    recommendationItemsStatusMap
  } = props;

  const totals = getAssessmentGoalsTotalsCombined({
    assessmentGoals,
    activityType,
    recommendations,
    recommendationStatusMap,
    recommendationItems,
    recommendationItemsStatusMap
  });

  const progressDisplay = getProgressDisplay(totals, activityType);
  const contentTypes = getContentTypes(activityType);
  const contentTypesDisplay = getContentTypeDisplay(contentTypes);

  const renderAssessList = () => {
    if (!recommendations) {
      return null;
    }

    return (
      <div>
        {
          recommendations
            .filter((recommendation) => {
              return RemediationContentTypeConfigMap[recommendation.contentType].activityType === activityType;
            })
            .map((recommendation) => {

              const recTotals = getAssessmentGoalsItemsTotals({
                assessmentGoals,
                activityType,
                recommendations: [recommendation],
                recommendationItems,
                statusMap: recommendationItemsStatusMap
              });

              return (
                <div
                  className="o-els-container"
                  key={recommendation.id}
                >
                  <FlexLayout
                    modifiers={[
                      FlexLayoutModifier.MIDDLE,
                      FlexLayoutModifier.GUTTERS,
                    ]}
                  >
                    <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                      <div className="c-ssa-remediation-activity-section__checklist-item c-ssa-remediation-activity-section__checklist-item--center">
                        {recTotals.attempted}/{recTotals.total} Questions Answered
                      </div>
                    </FlexItem>
                    <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                      <div className="c-ssa-remediation-activity-section__checklist-item c-ssa-remediation-activity-section__checklist-item--center">
                        {recTotals.completed}/{recTotals.total} Questions Correct
                      </div>
                    </FlexItem>
                    <FlexItem>
                      <button
                        type="button"
                        onClick={() => props.onRecommendationClick(recommendation)}
                        className="c-els-button c-els-button--small c-ssa-remediation-activity-section__launch-btn"
                      >
                        <FlexLayout
                          modifiers={[
                            FlexLayoutModifier.MIDDLE,
                            FlexLayoutModifier.GUTTERS,
                          ]}
                        >
                          <FlexItem>
                            Take Quiz
                          </FlexItem>
                          <FlexItem>
                            <ELSIcon
                              size="1x"
                              prefix="hmds"
                              name="arrow-right"
                              customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                            />
                          </FlexItem>
                        </FlexLayout>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>
              );
            })
        }
      </div>
    );
  };

  const renderList = () => {
    if (!assessmentGoals) {
      return null;
    }

    return (
      <div>
        {
          assessmentGoals
            .filter((assessmentGoal) => {
              return assessmentGoal.type === activityType;
            })
            .map((assessmentGoal) => {

              const assessmentGoalTotals = getAssessmentGoalsTotals({
                assessmentGoals: [assessmentGoal],
                activityType,
                recommendations,
                statusMap: recommendationStatusMap,
              });

              return (
                <button
                  type="button"
                  onClick={() => props.onTaxonClick(assessmentGoal)}
                  key={assessmentGoal.vtwId}
                  className={
                    cx(
                      'u-els-debuttonize',
                      'c-ssa-remediation-activity-section__checklist-item',
                      {
                        'c-ssa-remediation-activity-section__checklist-item--completed': assessmentGoalTotals.completed === assessmentGoalTotals.total
                      }
                    )
                  }
                >
                  <FlexLayout
                    modifiers={[
                      FlexLayoutModifier.MIDDLE,
                      FlexLayoutModifier.GUTTERS_1o2
                    ]}
                  >
                    <FlexItem>
                      <ELSIcon
                        name={assessmentGoalTotals.completed === assessmentGoalTotals.total ? 'checkmark' : ''}
                        prefix="hmds"
                        size="1x"
                        customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                      />
                    </FlexItem>
                    <FlexItem
                      modifiers={[
                        FlexLayoutModifier.GROW,
                      ]}>
                      <div className="c-ssa-remediation-activity-section__checklist-item-text">
                        <strong>{assessmentGoal.text}</strong>
                        <span>&middot; </span>
                        <span className="u-els-color-n7">{assessmentGoalTotals.completed}/{assessmentGoalTotals.total} completed</span>
                      </div>
                    </FlexItem>
                    <FlexItem>
                      <ELSIcon
                        size="1x"
                        prefix="hmds"
                        name="arrow-right"
                        customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                      />
                    </FlexItem>
                  </FlexLayout>
                </button>
              );
            })
        }
      </div>
    );
  };

  return (
    <div className={`c-ssa-remediation-activity-section c-ssa-remediation-activity-section--${activityType.toLowerCase()}`}>

      <div className="c-ssa-remediation-activity-section__checklist-header">

        <div>
          <FlexLayout
            modifiers={[
              FlexLayoutModifier.BOTTOM,
            ]}
          >
            <FlexItem
              modifiers={[
                FlexLayoutModifier.GROW,
              ]}>
              <h3>
                <strong>{ActivityTypeConfigMap[activityType].title}: {contentTypesDisplay}</strong>
              </h3>
            </FlexItem>
            <FlexItem>
              <div className="u-els-color-n7">{progressDisplay} completed</div>
            </FlexItem>
          </FlexLayout>

        </div>

      </div>

      {activityType === RemediationActivityType.ASSESS ? renderAssessList() : renderList()}
    </div>
  );
};
export default RemediationActivitySection;
