import React, { Component } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { ELSPropsFromModalService } from '@els/els-component-modal-react';
import { compose } from 'recompose';
import { RoutePath } from '../../components/app/app.constants';
import { locationActions } from '../../redux/location/location.actions';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { pickCookies } from '../app-link-redirect/app-link-redirect.utilities';

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  resetCwState: studyActions.resetState,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type LogoutPageProps = PropsFromRedux & ELSPropsFromModalService;

export class CoursewareLogoutPage extends Component<LogoutPageProps> {

  componentDidMount() {
    const {
      redirect,
      resetCwState,
    } = this.props;

    // Remove cookies if left around
    pickCookies();

    resetCwState();
    redirect(RoutePath.LOGOUT);
  }

  render() {
    return <></>;
  }
}

export default compose(connector)(CoursewareLogoutPage);
