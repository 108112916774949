import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import { EolsUserEngagementDto } from './eols-user-engagement.dtos';

export const fetchUserEngagementReport = (courseSectionId: string): Promise<EolsUserEngagementDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/sherpath/course-management/engagement/reports', { courseSectionId }));
  });
};
