import React from 'react';
import { ELSTooltip } from '../../components/els.components';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { Messages } from '../../translations/message.models';

export type AiChatUserMaterialsProps = {
  ebookIsbnsAndTitles: Record<string, string>; // string[][];
  openVitalSource: (isbn: string, page: number) => void;
  messages: Messages;
}

export const AiChatUserMaterials = (props: AiChatUserMaterialsProps) => {

  const {
    ebookIsbnsAndTitles,
    openVitalSource,
    messages
  } = props;

  const renderBookLink = (isbn: string, title: string) => {
    return (
      <button
        title={title}
        type="button"
        className="u-els-anchorize u-els-debuttonize c-els-ai-chat__user-materials-title"
        onClick={() => {
          openVitalSource(isbn, 0);
        }}
      >
        {title}
      </button>
    );
  };

  const ebooksDisplay = (
    <div>
      <div className="u-els-bold u-els-color-n9 u-els-padding-bottom-1x">{messages.SHERPATH_AI_MATERIALS_ACCESS}</div>
      <div className="c-els-ai-chat__user-materials u-els-clearfix">
        {Object.entries(ebookIsbnsAndTitles).map(([isbn, title]) => {
          if (title !== null) {
            return (
              <FlexLayout key={isbn}
                         modifiers={[FlexLayoutModifier.GUTTERS]}
                         classes={['u-els-padding-bottom-1o2']}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]} classes={['c-els-ai-chat__user-materials-title-parent']}>
                  {renderBookLink(isbn, title)}
                </FlexItem>
                <FlexItem>
                  <span className="u-els-color-n7">{messages.SHERPATH_AI_EBOOK_LABEL}</span>
                </FlexItem>
              </FlexLayout>
            );
          }
          return null;
        })}
      </div>
    </div>
  );

  return (
    <ELSTooltip
        id="userMaterialsTooltip"
        content={ebooksDisplay}
        dialogAriaLabel={messages.SHERPATH_AI_MATERIALS_LABEL}
      >
      <span
            className="u-els-debuttonize u-els-anchorize"
          >
        {messages.SHERPATH_AI_MATERIALS_LABEL}
      </span>
    </ELSTooltip>
  );
};
