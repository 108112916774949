import { ELSToastOptions } from '@els/els-component-toast-react';

export const TOAST_AUTO_CLOSE_TIMEOUT = 15000;
export const BASE_TOAST_CONFIG : Partial<ELSToastOptions> = {
  autoClose: true,
  autoCloseDelay: TOAST_AUTO_CLOSE_TIMEOUT,
  bottom: false,
  left: false,
  center: true,
};
