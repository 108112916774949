import React, { useState } from 'react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSCheckBox,
  ELSIcon
} from '@els/els-component-form-field-react';
import {
  ChatEntryDto,
  PromptOverrideConfigDto,
  OverridePromptType
} from '../../apis/florence-facade/florence-facade.dtos';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import 'chartjs-plugin-datalabels';
import { locationActions } from '../../redux/location/location.actions';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { copyContentToClipboard } from '../../utilities/common.utilities';

const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  fetchChatEntryTracesAction: studyActions.fetchChatEntryTracesAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type AiChatAdminModalPropsOnly = {
  promptOverride: ChatEntryDto['promptOverride'];
  searchOverride: ChatEntryDto['searchOverride'];
  handleSave: (promptOverride: ChatEntryDto['promptOverride'], searchOverride: ChatEntryDto['searchOverride']) => void;
  handleCancel: () => void;
}

export type AiChatAdminModalProps = PropsFromRedux & ELSPropsFromToastService & AiChatAdminModalPropsOnly;

export type AiChatAdminModalState = {
  convertInput: string;
  promptOverride: Record<OverridePromptType, PromptOverrideConfigDto>;
  promptOverrideActiveKeys: Record<OverridePromptType, boolean>;
  searchOverrideIsbns: string;
}

enum ConfigField {
  prompt = 'prompt',
  userChatItemTemplate = 'userChatItemTemplate',
  botChatItemTemplate = 'botChatItemTemplate',
  promptBookChunkItemTemplate = 'promptBookChunkItemTemplate',
  promptVideoItemTemplate = 'promptVideoItemTemplate'
}

const exampleMap: Record<ConfigField, string> = {
  [ConfigField.userChatItemTemplate]: '{{MESSAGE}}',
  [ConfigField.botChatItemTemplate]: '{{MESSAGE}}',
  [ConfigField.prompt]: '{{SEARCH_RESULTS}} {{CHAT_HISTORY}} {{USER_MESSAGE}}',
  [ConfigField.promptBookChunkItemTemplate]: '{{MAP_ID}} {{BOOK_TITLE}} {{BOOK_CONTENT}}',
  [ConfigField.promptVideoItemTemplate]: '{{MAP_ID}} {{VIDEO_TITLE}} {{VIDEO_CONTENT}}'
};

const responseFormatMap: Record<OverridePromptType, string> = {
  [OverridePromptType.declineResponse]: 'any text',
  [OverridePromptType.primaryResponse]: 'any text',
  [OverridePromptType.classificationIsRelevant]: JSON.stringify(
    {
      userMessageTopics: ['List of keywords and topics related to last user message'],
      isUserMessageRelevant: 'YES/NO'
    }
  ),
  [OverridePromptType.declineResponseNoSearchResults]: 'any text',
  [OverridePromptType.studyContentGenerationResponse]: 'any text',
  [OverridePromptType.postProcess]: JSON.stringify({
    bookChunks: [
      {
        bookChunkId: -1,
        bookChunkWasUsed: true
      },
      {
        bookChunkId: -2,
        bookChunkWasUsed: false
      }
    ],
    videoTranscriptChunks: [
      {
        videoTranscriptChunkId: -3,
        videoChunkInclude: true
      },
      {
        videoTranscriptChunkId: -4,
        videoChunkInclude: false
      }
    ]
  }),
  [OverridePromptType.rankAndFilterSearchResults]: JSON.stringify(['123', '456']),
  [OverridePromptType.classificationStudyContentGeneration]: JSON.stringify(
    {
      thinking: 'summary of last user message intent and your thinking',
      isLastUserMessageAskingToGenerateQuizQuestions: 'YES/NO'
    }
  ),
  [OverridePromptType.contextResolution]: 'any text',

};

const defaultState: AiChatAdminModalState = {
  convertInput: '',
  promptOverrideActiveKeys: Object.keys(OverridePromptType).reduce((acc, key) => {
    return {
      ...acc,
      [key]: false
    };
  }, {} as Record<OverridePromptType, boolean>),
  promptOverride: Object.keys(OverridePromptType).reduce((acc, key) => {
    const emptyConfig: PromptOverrideConfigDto = {
      prompt: '',
      userChatItemTemplate: '',
      botChatItemTemplate: '',
      promptBookChunkItemTemplate: '',
      promptVideoItemTemplate: '',
    };
    return {
      ...acc,
      [key]: { ...emptyConfig }
    };
  }, {} as Record<OverridePromptType, PromptOverrideConfigDto>),
  searchOverrideIsbns: ''

};

export const AiChatAdminModalComponent = (props: AiChatAdminModalProps) => {
  const {
    handleSave,
    handleCancel,
    toastService,
  } = props;

  const getDefaultOverrideState = (
    promptOverride: ChatEntryDto['promptOverride'],
    searchOverride: ChatEntryDto['searchOverride'],
  ): Partial<AiChatAdminModalState> => {
    return {
      promptOverrideActiveKeys: {
        ...defaultState.promptOverrideActiveKeys,
        ...Object.keys(promptOverride || {}).reduce((acc, key) => {
          return {
            ...acc,
            [key]: true
          };
        }, {} as Record<OverridePromptType, boolean>)
      },
      promptOverride: {
        ...defaultState.promptOverride,
        ...promptOverride
      },
      searchOverrideIsbns: searchOverride && searchOverride.isbns ? searchOverride.isbns.join(',') : ''
    };
  };

  const [state, setState] = useState<AiChatAdminModalState>({
    ...defaultState,
    ...getDefaultOverrideState(props.promptOverride, props.searchOverride),
  });

  const handleFieldChange = (e, prompt: OverridePromptType, field: ConfigField) => {
    const { value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        promptOverride: {
          ...prevState.promptOverride,
          [prompt]: {
            ...prevState.promptOverride[prompt],
            [field]: value
          }
        }
      };
    });
  };

  const scrubIsbn = (isbn: string) => {
    if (!isbn) {
      return '';
    }
    return isbn.replace(/[^0-9,]/g, '').trim();
  };

  const handleIsbnsChange = (e) => {
    const { value } = e.target;
    setState((prevState) => {
      return {
        ...prevState,
        searchOverrideIsbns: scrubIsbn(value)
      };
    });
  };

  const getOverridesForSave = (): ChatEntryDto['promptOverride'] => {
    return Object.keys(state.promptOverride).reduce((acc, key) => {
      if (!state.promptOverrideActiveKeys[key]) {
        return acc;
      }

      const config = {} as Partial<PromptOverrideConfigDto>;
      if (state.promptOverride[key].prompt) {
        config.prompt = state.promptOverride[key].prompt;
      }
      if (state.promptOverride[key].userChatItemTemplate) {
        config.userChatItemTemplate = state.promptOverride[key].userChatItemTemplate;
      }
      if (state.promptOverride[key].botChatItemTemplate) {
        config.botChatItemTemplate = state.promptOverride[key].botChatItemTemplate;
      }
      if (state.promptOverride[key].promptBookChunkItemTemplate) {
        config.promptBookChunkItemTemplate = state.promptOverride[key].promptBookChunkItemTemplate;
      }
      if (state.promptOverride[key].promptVideoItemTemplate) {
        config.promptVideoItemTemplate = state.promptOverride[key].promptVideoItemTemplate;
      }

      return {
        ...acc,
        [key]: config
      };
    }, {});
  };

  const getIsbns = (isbnsString: string): string[] => {
    if (!isbnsString) {
      return [];
    }
    return isbnsString
      .split(',')
      .map((isbn) => {
        return scrubIsbn(isbn);
      })
      .filter((item) => {
        return item !== '';
      });
  };

  const getSearchOverridesForSave = (): ChatEntryDto['searchOverride'] => {
    const isbns = getIsbns(state.searchOverrideIsbns);
    if (!isbns.length) {
      return null;
    }
    return {
      isbns
    };
  };

  const handleCopyClick = (content: string) => {

    copyContentToClipboard(content);

    toastService.openToast({
      ...BASE_TOAST_CONFIG,
      component: (
        <div>Successfully copied</div>
      ),
      type: ELSWithToastService.types.POSITIVE
    });
  };

  const extractString = (input, start, stop) => {
    const startIndex = input.indexOf(start) + start.length;
    const stopIndex = input.indexOf(stop);
    if (startIndex === -1 || stopIndex === -1) {
      return null; // start or stop not found in the input
    }
    return input.substring(startIndex, stopIndex);
  };

  const renderConfig = (prompt: OverridePromptType) => {
    const fields = Object.keys(ConfigField) as ConfigField[];
    return fields.map((field) => {
      return (
        <div
          key={`${prompt}_${field}`}
          className="c-ssa-ai-chat-admin-override-modal__field"
        >
          <div>{field}</div>
          <div className="c-ssa-ai-chat-admin-override-modal__description">
            <div>Replace fields:</div>
            <div>{exampleMap[field]}</div>
          </div>
          <div className="c-els-field">
            <textarea
              name={field}
              className="c-els-field__input"
              value={state.promptOverride[prompt][field]}
              onChange={(e) => {
                handleFieldChange(e, prompt, field);
              }}
            />
          </div>
        </div>
      );
    });

  };

  return (
    <div>
      <div className="c-els-modal c-els-modal--full">
        <div className="c-els-modal__window u-els-width-1o1 c-ssa-ai-chat-admin-override-modal">
          <div className="c-ssa-ai-chat-admin-override-modal__top">
            <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS]}>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h2>Admin Overrides</h2>
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="u-els-debuttonize"
                  onClick={handleCancel}
                >
                  <ELSIcon name="close" size="1x" />
                  <span className="u-els-hide-visually">Close</span>
                </button>
              </FlexItem>
            </FlexLayout>
          </div>
          <div className="c-ssa-ai-chat-admin-override-modal__main">
            <div>
              <div className="o-els-container">
                <h4>Converter tool</h4>
                <div className="c-els-field">
                  <textarea
                    name="converter"
                    className="c-els-field__input"
                    value={state.convertInput}
                    onChange={(e) => {
                      const { value } = e.target;
                      setState((prevState) => {
                        return {
                          ...prevState,
                          convertInput: value
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <div className="o-els-container">
                <FlexLayout modifiers={[
                  FlexLayoutModifier.LEFT,
                  FlexLayoutModifier.GUTTERS
                ]}>
                  <FlexItem>
                    <button
                      type="button"
                      className="c-els-button c-els-button--small"
                      onClick={() => {
                        setState((prevState) => {
                          let parsed = state.convertInput;
                          try {
                            const str = `{"value": "${state.convertInput}"}`;
                            parsed = JSON.parse(str).value;
                          } catch (e) {
                            toastService.openToast({
                              ...BASE_TOAST_CONFIG,
                              component: (<div>Error parsing text</div>),
                              type: ELSWithToastService.types.NEGATIVE
                            });
                          }
                          return {
                            ...prevState,
                            convertInput: parsed
                          };
                        });
                      }}
                    >
                      Escaped to String
                    </button>
                  </FlexItem>
                  <FlexItem>
                    <button
                      type="button"
                      className="c-els-button c-els-button--small"
                      onClick={() => {
                        setState((prevState) => {
                          let newValue = state.convertInput;
                          try {
                            const startString = 'XYZ_START_ABC';
                            const stopString = 'XYZ_STOP_ABC';
                            const obj = { value: `${startString}${state.convertInput}${stopString}` };
                            newValue = extractString(JSON.stringify(obj), startString, stopString);
                          } catch (e) {
                            toastService.openToast({
                              ...BASE_TOAST_CONFIG,
                              component: (<div>Error parsing text</div>),
                              type: ELSWithToastService.types.NEGATIVE
                            });
                          }
                          return {
                            ...prevState,
                            convertInput: newValue
                          };
                        });
                      }}
                    >
                      String to Escaped
                    </button>
                  </FlexItem>
                  <FlexItem>
                    <button
                      type="button"
                      className="c-els-button c-els-button--secondary c-els-button--small"
                      onClick={() => {

                        try {
                          const overrides: {
                            promptOverride: ChatEntryDto['promptOverride'];
                            searchOverride: ChatEntryDto['searchOverride'];
                          } = JSON.parse(state.convertInput);
                          setState((prevState) => {
                            return {
                              ...prevState,
                              ...getDefaultOverrideState(overrides.promptOverride, overrides.searchOverride),
                              convertInput: ''
                            };
                          });
                        } catch (e) {
                          toastService.openToast({
                            ...BASE_TOAST_CONFIG,
                            component: (<div>Error parsing text</div>),
                            type: ELSWithToastService.types.NEGATIVE
                          });
                        }
                      }}
                    >
                      Load Overrides
                    </button>
                  </FlexItem>
                  <FlexItem>
                    <button
                      type="button"
                      className="c-els-button c-els-button--secondary c-els-button--small"
                      onClick={() => {
                        setState((prevState) => {
                          return {
                            ...prevState,
                            convertInput: ''
                          };
                        });
                      }}
                    >
                      Reset
                    </button>
                  </FlexItem>

                </FlexLayout>
              </div>
            </div>

            <div className="o-els-container o-els-container--2x">
              <h3>Search Override</h3>
              <div>
                <div
                  className="c-ssa-ai-chat-admin-override-modal__field"
                >
                  <div>Isbns (comma separated: 123,456)</div>
                  <div className="c-els-field">
                    <textarea
                      name="isbns"
                      className="c-els-field__input"
                      value={state.searchOverrideIsbns}
                      onChange={handleIsbnsChange}
                    />
                  </div>
                  <div>
                    Valid list: {getIsbns(state.searchOverrideIsbns).join(',')}
                  </div>
                </div>
              </div>
            </div>

            <div className="o-els-container o-els-container--2x">
              <h3>Prompt Override</h3>
              <h4 className="o-els-container">Choose the prompts to override</h4>
              {
                Object.keys(state.promptOverride)
                  .map((key) => {
                    return (
                      <div
                        key={key}
                        className="o-els-container o-els-container--1o2"
                      >
                        <ELSCheckBox
                          changeHandler={
                            () => {
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  promptOverrideActiveKeys: {
                                    ...prevState.promptOverrideActiveKeys,
                                    [key as OverridePromptType]: !prevState.promptOverrideActiveKeys[key as OverridePromptType]
                                  }
                                };
                              });
                            }
                          }
                          checked={state.promptOverrideActiveKeys[key as OverridePromptType]}
                        >
                          {key}
                        </ELSCheckBox>
                      </div>
                    );
                  })
              }
            </div>

            <div>
              {
                Object.keys(state.promptOverride)
                  .filter((key) => {
                    return state.promptOverrideActiveKeys[key];
                  })
                  .map((key) => {
                    return (
                      <div
                        key={key}
                        className="c-ssa-ai-chat-admin-override-modal__prompt"
                      >
                        <h4 className="u-els-margin-bottom">{key}</h4>
                        <div className="c-ssa-ai-chat-admin-override-modal__description">
                          <div>Expected response format:</div>
                          <div>{responseFormatMap[key as OverridePromptType]}</div>
                        </div>
                        {renderConfig(key as OverridePromptType)}
                      </div>
                    );
                  })
              }
            </div>
          </div>

          <div className="c-ssa-ai-chat-admin-override-modal__bottom">
            <FlexLayout modifiers={[
              FlexLayoutModifier.RIGHT,
              FlexLayoutModifier.GUTTERS
            ]}>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--small c-els-button--secondary"
                  onClick={() => {
                    const _promptOverride = getOverridesForSave();
                    const _searchOverride = getSearchOverridesForSave();
                    handleCopyClick(JSON.stringify({
                      promptOverride: _promptOverride,
                      searchOverride: _searchOverride
                    }));
                  }}
                >
                  Copy overrides
                </button>
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--small"
                  onClick={() => {
                    const _promptOverride = getOverridesForSave();
                    const _searchOverride = getSearchOverridesForSave();
                    handleSave(_promptOverride, _searchOverride);
                  }}
                >
                  Save overrides
                </button>
              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

const enhancers = [
  connector,
  ELSWithToastService,
  withPageLoader // This must come after connect
];

const AiChatAdminModal = compose<null, AiChatAdminModalPropsOnly>(...enhancers)(AiChatAdminModalComponent);

export default AiChatAdminModal;
