import React from 'react';

import { ELSIcon } from '@els/els-component-form-field-react';
import {
  ChatEntryDto,
  ChatReferenceDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getCitationDisplayOLD,
} from './ai-chat.utilities';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import {
  AnalyticsAction,
  AnalyticsActionProps,
} from '../../models/analytics.models';

export type AiChatEntryCitationOLDProps = {
  citations: ChatReferenceDto[];
  entry: ChatEntryDto;
  trackAction: (props: AnalyticsActionProps) => void;
}

export const AiChatEntryCitationOLD = (props: AiChatEntryCitationOLDProps) => {

  const {
    citations,
    trackAction,
    entry
  } = props;
  const handleReferenceClickOLD = (citation: ChatReferenceDto) => {
    trackAction({
      action: AnalyticsAction.AI_CHAT_CITATION_CLICK,
      props: {
        type: citation.type,
        resourceType: citation.resourceType,
        citationId: citation.id,
        entryId: entry.id,
      }
    });
  };

  return (
    <>
      {
        citations.map((citation) => {
          return (
            <div
              key={citation.id}
              className="c-els-ai-chat__entry-reference"
            >
              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_1o2,
                FlexLayoutModifier.LEFT,
                FlexLayoutModifier.TOP
              ]}>
                <FlexItem>
                  <div className="u-els-margin-top-1o8">
                    <ELSIcon
                      size="1x1o4"
                      prefix="hmds"
                      name="ebook"
                    />
                  </div>
                </FlexItem>
                <FlexItem classes={['u-cw-min-width-0']}>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                  <div onClick={() => handleReferenceClickOLD(citation)}>
                    {getCitationDisplayOLD(citation)}
                  </div>
                </FlexItem>
              </FlexLayout>
            </div>
          );
        })
      }
    </>
  );
};
