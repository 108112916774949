import React, { ReactElement } from 'react';
import { FlexProps } from './flex.models';

export const getChildClasses = (child: ReactElement, classKey: string):string => child.props[classKey] || '';

export const generateFlexClasses = (props: FlexProps, prefix: string):string => {
  const { modifiers, classes, childClassKey, children } = props;

  let allClasses = [prefix];

  // Flex modifiers corresponding to els styleguide
  if (modifiers && modifiers.length > 0) {
    allClasses = allClasses.concat(modifiers.map((modifier) => `${prefix}--${modifier}`));
  }

  // Arbitrary classes
  if (classes && classes.length > 0) {
    allClasses = allClasses.concat(classes);
  }

  // If you need to add a class of a child element, store that class as a prop on the child and pass that prop name here
  if (childClassKey && React.Children.count(children) === 1) {
    allClasses.push(getChildClasses(children as ReactElement, childClassKey));
  }

  return allClasses.join(' ');
};
