export const NO_VALUE = 'NO_VALUE';
export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

// TODO: Describe feature flags
export enum FEATURE_FLAG {
  ADOBE_ANALYTICS_DISABLED = 'ADOBE_ANALYTICS_DISABLED',
  IS_SITE_DOWN_FOR_MAINTENANCE = 'IS_SITE_DOWN_FOR_MAINTENANCE',
  EXAMPLE_FEATURE_FLAG = 'EXAMPLE_FEATURE_FLAG',
  ENABLE_SELF_STUDY_NAVIGATION_TABS = 'ENABLE_SELF_STUDY_NAVIGATION_TABS',
  ENABLE_SELF_STUDY_ASSESSMENT_CREATOR_REDIRECT = 'ENABLE_SELF_STUDY_ASSESSMENT_CREATOR_REDIRECT',
  ENABLE_V2_AUTHESS_APP_LINKING = 'ENABLE_V2_AUTHESS_APP_LINKING',
  IS_FLORENCE_HTTP_STREAMING_ENABLED = 'IS_FLORENCE_HTTP_STREAMING_ENABLED',
  IS_FLORENCE_STOP_STREAMING_ENABLED = 'IS_FLORENCE_STOP_STREAMING_ENABLED',
  IS_FLORENCE_HOW_IT_WORKS_LINK_ENABLED = 'IS_FLORENCE_HOW_IT_WORKS_LINK_ENABLED',
  IS_NCLEX_SELF_STUDY_OPTION_ENABLED = 'IS_NCLEX_SELF_STUDY_OPTION_ENABLED',
  IS_DIRECT_CHATBOT_ISBN = 'IS_DIRECT_CHATBOT_ISBN',
  FLORENCE_CHAT_MODEL = 'FLORENCE_CHAT_MODEL',
  IS_AI_CHAT_INSTRUCTOR_PERFORMANCE_ENABLED = 'IS_AI_CHAT_INSTRUCTOR_PERFORMANCE_ENABLED',
  IS_BOOK_CITATION_LINK_ENABLED = 'IS_BOOK_CITATION_LINK_ENABLED',
  IS_CHATBOT_MATERIALS_LIBRARY_ENABLED = 'IS_CHATBOT_MATERIALS_LIBRARY_ENABLED',
  IS_CHATBOT_EXPLAINER_ENABLED = 'IS_CHATBOT_EXPLAINER_ENABLED',
  IS_FLORENCE_GENERATE_THEMES_ENABLED = 'IS_FLORENCE_GENERATE_THEMES_ENABLED',
}

export const AB_TEST = 'AB_TEST';

export const AB_TEST_CONFIG_MAP: Partial<Record<FEATURE_FLAG, string[]>> = {
  [FEATURE_FLAG.EXAMPLE_FEATURE_FLAG]: [
    'EXAMPLE_FLAVOR_1',
    'EXAMPLE_FLAVOR_2',
  ],
};
