import React, {
  PropsWithChildren,
} from 'react';
import {
  ELSPropsFromToastService,
  ELSWithToastService,
} from '@els/els-component-toast-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { ELSIcon } from '@els/els-component-form-field-react';
import { FlexLayout } from '../flex/FlexLayout.component';
import { FlexLayoutModifier } from '../flex/flex.constants';
import { FlexItem } from '../flex/FlexItem.component';
import { AnalyticsAction } from '../../models/analytics.models';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import {
  getIsDirectAccessByFeatureFlag,
  getIsDirectAccessByIsbns
} from '../../pages/ai-chat/ai-chat.utilities';

const mapStateToProps = state => ({
  userId: studySelectors.getUserId(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  isbns: studySelectors.getIsbns(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  appLinkData: studySelectors.getLinkData(state),
  evolveProducts: studySelectors.getEvolveProducts(state),
});

const mapDispatchToProps = {
  returnAppLink: studyActions.returnAppLink,
  navigateToApp: studyActions.navigateToApp,
  trackAction: studyActions.trackAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AiChatBaseTemplatePropsOnly = PropsWithChildren<{}>;

export type AiChatBaseTemplateProps = PropsFromRedux & ELSPropsFromToastService & ELSPropsFromModalService & AiChatBaseTemplatePropsOnly;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const elsevierLogo = require('@els/els-styleguide-core/images/ElsevierLogo.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const relxLogo = require('@els/els-styleguide-core/images/RelxLogo.svg');

const isShowFooter = false;

export const AiChatBaseTemplateComponent = (props: AiChatBaseTemplateProps) => {

  const {
    featureFlagsGrouped,
    courseSectionId,
    isbns,
    children,
    evolveProducts,
  } = props;

  const getParentLinkId = (): string => {
    const {
      appLinkData,
      appLinkCookies
    } = props;
    if (appLinkData && appLinkData.parentLinkId) {
      return appLinkData.parentLinkId;
    }
    return appLinkCookies ? appLinkCookies.linkId : null;
  };

  const handleReturnClick = () => {
    const {
      returnAppLink,
      trackAction
    } = props;

    trackAction({
      action: AnalyticsAction.AI_CHAT_RETURN_CLICK,
      props: null
    });

    returnAppLink({
      linkId: getParentLinkId(),
      returnPostBody: null
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const iconSrc = require('@els/els-styleguide-core/images/NonSolusLogo.svg');

  const isDirectAccessByFeatureFlag = getIsDirectAccessByFeatureFlag(
    featureFlagsGrouped,
    courseSectionId,
    isbns
  );
  const isDirectAccessByIsbn = getIsDirectAccessByIsbns(evolveProducts, isbns);
  const isDirectAccess = isDirectAccessByFeatureFlag || isDirectAccessByIsbn;

  return (
    <>
      <div className="c-ssa-ai-chat-base">
        <div className="c-ssa-ai-chat-base__header">

          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.MIDDLE]}>
            <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1X_1o2, FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE]}>
                <FlexItem isRender={isDirectAccess}>
                  <img className="c-ssa-ai-chat-base__logo-img" src={iconSrc} alt="" />
                </FlexItem>
                <FlexItem isRender={!isDirectAccess}>
                  <button
                    className="u-els-debuttonize u-els-anchorize u-els-display-block"
                    type="button"
                    onClick={handleReturnClick}
                  >
                    <ELSIcon
                      name="close"
                      size="1x"
                      customClass="u-els-display-block"
                    />
                    <span className="u-els-hide-visually">Close</span>
                  </button>
                </FlexItem>
                <FlexItem>
                  <h4>Sherpath AI</h4>
                </FlexItem>
              </FlexLayout>
            </FlexItem>
          </FlexLayout>
        </div>
        <div className="c-ssa-ai-chat-base__page">
          <div className="c-ssa-ai-chat-base__main">
            {children}
          </div>
        </div>
        {isShowFooter && (
          <div className="c-ssa-ai-chat-base__footer">
            <div className="c-ssa-ai-chat-base__footer-slim">
              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  <img className="c-ssa-ai-chat-base__footer-logo" src={elsevierLogo} alt="" />
                </FlexItem>
                <FlexItem>
                  <img className="c-ssa-ai-chat-base__footer-logo" src={relxLogo} alt="" />
                </FlexItem>
              </FlexLayout>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const enhancers = [
  connector,
  ELSWithToastService,
  ELSWithModalService,
  withPageLoader // This must come after connect
];

const AiChatBaseTemplate = compose<null, AiChatBaseTemplatePropsOnly>(...enhancers)(AiChatBaseTemplateComponent);

export default AiChatBaseTemplate;
