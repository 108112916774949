import React, { ReactNode } from 'react';
import { ELSIcon } from '../els.components';
import { FlexLayout } from '../flex/FlexLayout.component';
import { FlexItem } from '../flex/FlexItem.component';
import { FlexLayoutModifier } from '../flex/flex.constants';
import { ELSButtonIconPosition } from '../../models/button.models';
import { getEAQIcon } from '../eaq-icon/eaq-icon.utilities';

export interface IconWithTextProps {
  children: ReactNode;
  iconName: string;
  iconPrefix: string;
  iconSize?: string;
  iconPosition?: ELSButtonIconPosition;
  iconClass?: string;
}

const IconWithText = (props: IconWithTextProps) => {
  const {
    children,
    iconName,
    iconPrefix,
    iconSize,
    iconPosition,
    iconClass
  } = props;

  const positionOfIcon = iconPosition || ELSButtonIconPosition.LEFT;

  const iconClassArray = ['u-els-display-block'];
  if (iconClass) {
    iconClassArray.push(iconClass);
  }

  // eslint-disable-next-line no-shadow
  const renderIcon = (iconName: string) => {
    if (iconName === 'eugene') {
      return getEAQIcon();
    }
    return (
      <ELSIcon
        size={iconSize || '1x'}
        prefix={iconPrefix}
        name={iconName}
        customClass={iconClassArray.join(' ')}
      />
    );
  };

  return (
    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE]}>
      <FlexItem classes={['u-cw-min-width-0']} isRender={positionOfIcon === ELSButtonIconPosition.RIGHT}>
        <div>
          {children}
        </div>
      </FlexItem>
      <FlexItem>
        {renderIcon(iconName)}
      </FlexItem>
      <FlexItem classes={['u-cw-min-width-0']} isRender={positionOfIcon === ELSButtonIconPosition.LEFT}>
        <div className="u-els-margin-top-1o8">
          {children}
        </div>
      </FlexItem>
    </FlexLayout>
  );
};

export default IconWithText;
