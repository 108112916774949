import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { RemRecContentTypeDto } from '../../apis/florence-facade/florence-facade.dtos';
import { RemediationContentTypeConfigMap } from './remediation-home.models';

export type ContentTypeDisplayProps = {
  contentType: RemRecContentTypeDto;
}
export const ContentTypeDisplay = (props: ContentTypeDisplayProps) => {
  const config = RemediationContentTypeConfigMap[props.contentType];
  return (
    <IconWithText
      iconName={config.iconName}
      iconPrefix={config.iconPrefix}
    >
      {config.displayName}
    </IconWithText>
  );
};
