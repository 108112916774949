import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSURLHelper } from '@els/els-ui-common-react';
import cx from 'classnames';
import moment from 'moment';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import 'chartjs-plugin-datalabels';
import { locationActions } from '../../redux/location/location.actions';
import { locationSelectors } from '../../redux/location/location.selectors';
import {
  getRecommendations,
  isAdminUser
} from '../ai-chat/ai-chat.utilities';
import { RoutePath } from '../../components/app/app.constants';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import {
  ChatDto,
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatPaginationResponseDto,
  ChatReferenceDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { AiChatEntryCitation } from '../ai-chat/AiChatEntryCitation.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  getPaginationConfig,
  getSortedChatEntries
} from './ai-chat-admin.utilities';
import {
  DATE_TIME_PRIMARY
} from '../../constants/date.constants';
import { ADMIN_MODAL_ID } from '../ai-chat/ai-chat.constants';
import AiChatAdminTracesModal from '../ai-chat/AiChatAdminTracesModal.component';

const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
  location: locationSelectors.getLocation(state),
  showPageLoader: studySelectors.getIsLoading(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  adminFetchChatEntryAction: studyActions.adminFetchChatEntryAction,
  adminFetchChatEntriesAction: studyActions.adminFetchChatEntriesAction,
  adminFetchChatEntryTracesAction: studyActions.adminFetchChatEntryTracesAction,
  adminFetchChatAction: studyActions.adminFetchChatAction,
  fetchChatEeoIsbnsAction: studyActions.fetchChatEeoIsbnsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AiChatAadminProps = PropsFromRedux & ELSPropsFromToastService & ELSPropsFromModalService;

export type AiChatAdminState = {
  search: string;
  isAdminUser: boolean;
  entriesPage: ChatPaginationResponseDto & {
    content: ChatEntryDto[];
  };
  activeEntryId: string;
  activeEntry: ChatEntryDto;
  chat: ChatDto;
  pageSize: number;
  entitlements: Record<string, string>;
}

const defaultState: AiChatAdminState = {
  search: '',
  isAdminUser: false,
  entriesPage: null,
  activeEntryId: null,
  activeEntry: null,
  chat: null,
  pageSize: 50,
  entitlements: {},
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AiChatAdminComponent = (props: AiChatAadminProps) => {

  const [state, setState] = useState<AiChatAdminState>({
    ...defaultState,
    isAdminUser: isAdminUser(),
    activeEntryId: ELSURLHelper.getParameterByName('entryId', props.location.search)
  });

  const mergeState = (newState: Partial<AiChatAdminState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const scrollToElementById = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getPage = (pageDiff: number) => {
    const { adminFetchChatEntriesAction } = props;
    return adminFetchChatEntriesAction(
      state.activeEntry.chatId.toString(),
      state.entriesPage.currentPage + pageDiff,
      state.pageSize
    ).then((entriesPage) => {
      mergeState({ entriesPage });
    });
  };

  useEffect(() => {

    const {
      redirect,
      adminFetchChatEntryAction,
      adminFetchChatAction,
      adminFetchChatEntriesAction,
      fetchChatEeoIsbnsAction
    } = props;

    if (!state.isAdminUser) {
      redirect(RoutePath.PAGE_NOT_FOUND);
    }

    fetchChatEeoIsbnsAction().then((entitlements) => {
      mergeState({
        entitlements: entitlements.reduce((acc, isbn) => {
          return {
            ...acc,
            [isbn]: isbn
          };
        }, {})
      });
    });

    adminFetchChatEntryAction(state.activeEntryId).then((entry) => {

      mergeState({ activeEntry: entry });

      adminFetchChatAction(entry.chatId.toString()).then((chat) => {
        mergeState({ chat });
      });

      adminFetchChatEntriesAction(entry.chatId.toString(), 0, state.pageSize)
        .then((response) => {

          const paginationConfig = getPaginationConfig(
            entry.index,
            response.content[response.content.length - 1].index,
            state.pageSize
          );

          if (paginationConfig.page !== 0) {
            return adminFetchChatEntriesAction(entry.chatId.toString(), paginationConfig.page, paginationConfig.pageSize);
          }

          return response;
        })
        .then((response) => {
          mergeState({
            entriesPage: response
          });
          setTimeout(() => {
            scrollToElementById(`entry-${state.activeEntryId}`);
          });
        });
    });

  }, []);

  if (!isAdminUser) {
    return null;
  }

  const handleCitationClick = (citation: ChatReferenceDto) => {
    const modalId = 'CITATION_MODAL';
    // eslint-disable-next-line no-console
    console.log(citation);
    props.modalService.openAlertModal({
      modalId,
      content: (
        <div className="">
          <div>Resource title: <strong>{citation.resourceTitle}</strong></div>
          <div>Title: <strong>{citation.title}</strong></div>
          <div className="u-els-margin-top">{citation.content}</div>
        </div>
      ),
      confirmHandler: () => {
        props.modalService.closeModal(modalId);
      },
    });
  };

  return (
    <div className="u-els-padding c-els-ai-chat-admin">
      <div className="o-els-container">
        <h2>Chat admin</h2>
      </div>

      {state.chat && (
        <div className="o-els-container">
          <h3>
            Chat details
          </h3>
          <table className="c-els-ai-chat-admin__entry-table">
            <tbody>
              <tr>
                <td>contextId:</td>
                <td>{state.chat.contextId}</td>
              </tr>
              <tr>
                <td>contextType:</td>
                <td>{state.chat.contextType}</td>
              </tr>
              <tr>
                <td>createdAt:</td>
                <td>{moment(state.chat.createdAt).format(DATE_TIME_PRIMARY)}</td>
              </tr>
              <tr>
                <td>chatId:</td>
                <td>{state.chat.id}</td>
              </tr>
              <tr>
                <td>title:</td>
                <td>{state.chat.title}</td>
              </tr>
              <tr>
                <td>type:</td>
                <td>{state.chat.type}</td>
              </tr>
              <tr>
                <td>userId:</td>
                <td>{state.chat.userId}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {
        state.entriesPage && (state.entriesPage.totalPages - 1) > state.entriesPage.currentPage && (
          <div>
            <button
              type="button"
              className="c-els-button c-els-button--x-small c-els-button--secondary"
              onClick={() => {
                getPage(1);
              }}>
              Previous entries
            </button>
          </div>
        )
      }
      <div className="o-els-container">
        {
          getSortedChatEntries(state.entriesPage).map((entry) => {

            const recommendations = getRecommendations(entry);

            return (
              <div key={entry.id}
                   id={`entry-${entry.id}`}
                   className={
                     cx('c-els-ai-chat-admin__entry', {
                       'c-els-ai-chat-admin__entry--user': entry.author === ChatEntryAuthorDto.USER,
                       'c-els-ai-chat-admin__entry--bot': entry.author === ChatEntryAuthorDto.BOT,
                       'c-els-ai-chat-admin__entry--active': entry.id.toString() === state.activeEntryId,
                     })
                   }
              >
                <div className="c-els-ai-chat-admin__entry-header">
                  <FlexLayout>
                    <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                      <table className="c-els-ai-chat-admin__entry-table">
                        <tbody>
                          <tr>
                            <td>EntryId:</td>
                            <td>{entry.id}</td>
                          </tr>
                          <tr>
                            <td>Index:</td>
                            <td>{entry.index}</td>
                          </tr>
                          <tr>
                            <td>Created At:</td>
                            <td>{moment(entry.createdAt).format(DATE_TIME_PRIMARY)}</td>
                          </tr>
                          <tr>
                            <td>Author:</td>
                            <td>{entry.author}</td>
                          </tr>
                        </tbody>
                      </table>
                    </FlexItem>
                    <FlexItem isRender={entry.author === ChatEntryAuthorDto.USER}>
                      <button
                        type="button"
                        onClick={() => {
                          // eslint-disable-next-line no-console
                          console.log(entry);
                          props.adminFetchChatEntryTracesAction(entry.id.toString()).then((traces) => {
                            props.modalService.openCustomModal({
                              modalId: ADMIN_MODAL_ID,
                              modal: (
                                <AiChatAdminTracesModal
                                  entry={entry}
                                  traces={traces}
                                  toastService={props.toastService}
                                  handleClose={() => {
                                    props.modalService.closeModal(ADMIN_MODAL_ID);
                                  }}
                                />
                              )
                            });
                          });
                        }}
                      >
                        View traces
                      </button>
                    </FlexItem>
                  </FlexLayout>

                </div>
                <div className="c-els-ai-chat-admin__entry-body">
                  <pre>{entry.message}</pre>
                </div>

                {recommendations && recommendations.length > 0 && (
                  <div className="c-els-ai-chat-admin__entry-videos">
                    {
                      recommendations.map((recommendation) => (
                        <div key={recommendation.id}>
                          <button
                            type="button"
                            className="u-els-anchorize"
                            onClick={() => {
                              // eslint-disable-next-line no-console
                              console.log(recommendation);
                              const modalId = 'RECOMMENDATION_MODAL';
                              props.modalService.openAlertModal({
                                modalId,
                                content: (
                                  <div>
                                    <div>Title: <strong>{recommendation.title}</strong></div>
                                    <div>Id: <strong>{recommendation.id}</strong></div>
                                    <div className="u-els-margin-top">{recommendation.content}</div>
                                  </div>
                                ),
                                confirmHandler: () => {
                                  props.modalService.closeModal(modalId);
                                },
                              });
                            }}>
                            Video: {recommendation.title}
                          </button>
                        </div>
                      ))
                    }
                  </div>
                )}

                {
                  entry.references && entry.references.length > 0 && (
                    <div className="c-els-ai-chat-admin__entry-citations">
                      <AiChatEntryCitation
                        citations={entry.references}
                        handleClick={handleCitationClick}
                        isCitationLinkEnabled
                        isDirectAccessByFeatureFlag={false}
                        isDirectAccessByIsbn={false}
                        entitlements={state.entitlements}
                      />
                    </div>
                  )
                }

              </div>
            );
          })
        }
      </div>
      {
        state.entriesPage && state.entriesPage.currentPage > 0 && (
          <div>
            <button
              type="button"
              className="c-els-button c-els-button--x-small c-els-button--secondary"
              onClick={() => {
                getPage(-1);
              }}>
              Next entries
            </button>
          </div>
        )
      }

    </div>
  );
};

const enhancers = [
  connector,
  ELSWithToastService,
  ELSWithModalService,
  withPageLoader // This must come after connect
];

const AiChatAdmin = compose<null, null>(...enhancers)(AiChatAdminComponent);

export default AiChatAdmin;
