import { EaqStudentPerformanceDto, EaqStudentTopicPerformanceDto } from '../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { TopicPerformanceTableRow, TopicWithLineage } from '../pages/eaq-student-topic-report/eaq-student-topic-report.models';
import {
  getPercentageAnsweredCorrectly,
  getStudentPerformance,
  getTimeSpentFormat
} from '../pages/eaq-student-topic-report/eaq-student-topic-report.utilities';

export const getTopicTableRows = (flattenedTopics: TopicWithLineage[]): TopicPerformanceTableRow[] => {
  if (!flattenedTopics || !flattenedTopics.length) {
    return null;
  }

  return flattenedTopics.map((topic: TopicWithLineage): TopicPerformanceTableRow => {
    const studentPerformance: EaqStudentPerformanceDto = getStudentPerformance(topic.item);
    return {
      ...studentPerformance,
      topic,
      topicName: topic.item.displayName || topic.item.text,
      lineageDisplay: topic.lineage
        .map((parent: EaqStudentTopicPerformanceDto) => {
          return parent.displayName || parent.text;
        }).join(' / '),
      lineageDisplayOrder: topic.lineage.map((item: EaqStudentTopicPerformanceDto) => {
        return item.displayOrder + (item.displayName || item.text);
      }).join('.'),
      timeSpentDisplay: getTimeSpentFormat(studentPerformance.timeSpent),
      percentCorrect: getPercentageAnsweredCorrectly(studentPerformance)
    };
  });
};
