// TODO: These models need to live outside the module definition file until they are properly exported from the module itself.
// Otherwise these type definitions return undefined when code is looking for them in the source module.

export enum ELSButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INFO = 'info',
  WARN = 'warn',
  CONFIRM = 'confirm',
  LINK = 'link',
  DEBUTTONIZE = 'debuttonize',
}

export enum ELSButtonAttrType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

export enum ELSButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ELSButtonIconPrefix {
  GIZMO = 'gizmo',
  HMDS = 'hmds',
}

export enum ELSButtonSize {
  X_LARGE = 'x-large',
  LARGE = 'large',
  SMALL = 'small',
  X_SMALL = 'x-small',
  DEFAULT = 'default',
}
