export enum SystemType {
  EVOLVETYPE = 'evolve',
  VSTTYPE = 'vst'
}

export interface CrosswalkUserDto {
  eolsId: number;
  externalUserId: number;
  userName: string;
  type: string;
  userData: string;
  eolsUserIdsByUserNameAndType: number[];
}
