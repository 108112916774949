import { ELSDropDownOption } from '@els/els-component-form-field-react';
import { SELECT_OPTION } from '../../../utilities/app.utilities';

export const allQuestionNumberOptions: ELSDropDownOption[] = [
  SELECT_OPTION,
  { name: '5 questions (est. 5m)', value: 5 },
  { name: '10 questions (est. 10m)', value: 10 },
  { name: '15 questions (est. 15m)', value: 15 },
  { name: '20 questions (est. 20m)', value: 20 },
  { name: '25 questions (est. 25m)', value: 25 },
  { name: '30 questions (est. 30m)', value: 30 },
  { name: '50 questions (est. 50m)', value: 50 },
  { name: '75 questions (est. 75m)', value: 75 },
  { name: '100 questions (est. 100m)', value: 100 },
];
