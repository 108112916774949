import React, {
  useEffect,
  useState
} from 'react';

import { ELSIcon } from '@els/els-component-form-field-react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import {
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatReferenceDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getCitations,
  getPageNumber
} from './ai-chat.utilities';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import IsRender from '../../components/is-render/IsRender.component';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { AiChatEntryCitationOLD } from './AiChatEntryCitationOLD.component';
import { AiChatEntryCitation } from './AiChatEntryCitation.component';
import { AnalyticsAction } from '../../models/analytics.models';

const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
});

const mapDispatchToProps = {
  openVitalSource: studyActions.openVitalSource,
  trackAction: studyActions.trackAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AiChatEntryCitationPropsOnly = {
  entry: ChatEntryDto;
  handleResourcesExpandToggle: () => void;
  isReferencesActive: boolean;
  isCitationLinkEnabled: boolean;
  isDirectAccessByFeatureFlag: boolean;
  isDirectAccessByIsbn: boolean;
  handleChatScroll: () => void;
  entitlements: Record<string, string>;
}

export type AiChatEntryCitationState = {
  isShowLoadingMessage: boolean;
}

const defaultState: AiChatEntryCitationState = {
  isShowLoadingMessage: false,
};

export type AiChatEntryCitationProps = PropsFromRedux & AiChatEntryCitationPropsOnly;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AiChatEntryCitationsComponent = (props: AiChatEntryCitationProps) => {

  if (props.entry && props.entry.author === ChatEntryAuthorDto.USER) {
    return null;
  }

  const [state, setState] = useState<AiChatEntryCitationState>(defaultState);

  useEffect(() => {
    if (props.entry._isPostProcessing) {
      setTimeout(() => {
        setState({ isShowLoadingMessage: true });
      }, 750); // Delay showing the loader because in cases where there are no search results the post-process api response is fast and causes a screen flash
    }
  }, [props.entry._isPostProcessing]);

  useEffect(() => {
    if (state.isShowLoadingMessage) {
      props.handleChatScroll();
    }
  }, [state.isShowLoadingMessage]);

  const {
    entry,
    handleResourcesExpandToggle,
    trackAction,
    isReferencesActive,
    isCitationLinkEnabled,
    openVitalSource,
    isDirectAccessByFeatureFlag,
    isDirectAccessByIsbn,
    entitlements
  } = props;

  const citations = getCitations(entry);

  if (entry && entry._isPostProcessing) {
    if (state.isShowLoadingMessage) {
      return (
        <ELSInlineLoader />
      );
    }
    return null;
  }

  if (!citations || !citations.length) {
    return null;
  }

  const isNewCitations = (_citations: ChatReferenceDto[]) => {
    return _citations[0].contentProps;
  };

  const handleCitationClick = (citation) => {
    const pageStart = getPageNumber(citation);
    trackAction({
      action: AnalyticsAction.AI_CHAT_CITATION_LINK_CLICK,
      props: {
        type: citation.type,
        resourceType: citation.resourceType,
        citationId: citation.id,
        entryId: entry.id,
        eeoISBN: citation._contentProps.source_id,
        pageStart
      }
    });
    openVitalSource(citation._contentProps.source_id, pageStart);
  };

  return (
    <div className="c-els-ai-chat__entry-reference-list">
      <button
        type="button"
        className="u-els-debuttonize u-els-display-block u-els-width-1o1 u-els-color-secondary"
        onClick={handleResourcesExpandToggle}
        aria-expanded={isReferencesActive ? 'true' : 'false'}
      >
        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.MIDDLE]}>
          <FlexItem>
            <ELSIcon
              size="1x"
              prefix="hmds"
              name={isReferencesActive ? 'chevron-up' : 'chevron-down'}
              customClass="u-els-display-block"
            />
          </FlexItem>
          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
            Evidence sources
          </FlexItem>
        </FlexLayout>
      </button>

      <IsRender isRender={isReferencesActive}>
        <div className="u-els-margin-top">
          {
            isNewCitations(citations)
              ? (
                (
                  <AiChatEntryCitation
                    citations={citations}
                    handleClick={handleCitationClick}
                    isCitationLinkEnabled={isCitationLinkEnabled}
                    isDirectAccessByFeatureFlag={isDirectAccessByFeatureFlag}
                    isDirectAccessByIsbn={isDirectAccessByIsbn}
                    entitlements={entitlements}
                  />
                )
              )
              : (
                <AiChatEntryCitationOLD
                  citations={citations}
                  trackAction={trackAction}
                  entry={entry}
                />
              )
          }
        </div>
      </IsRender>
    </div>
  );
};

const enhancers = [
  connector,
];

const AiChatEntryCitations = compose<null, AiChatEntryCitationPropsOnly>(...enhancers)(AiChatEntryCitationsComponent);

export default AiChatEntryCitations;
