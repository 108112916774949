import { Component } from 'react';
import { ELSWithToastService } from '@els/els-component-toast-react';
import { ELSToastServiceType } from '../../models/els.models';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';

interface WithToastProps {
  toastService: ELSToastServiceType;
}

const withToast = BaseComponent => {
  class WithToast extends Component<WithToastProps> {
    showSaveSuccess = () => {
      this.props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: <div>Saved successfully</div>,
        type: ELSWithToastService.types.POSITIVE
      });
    };

    render() {
      return <BaseComponent {...this.props} showSaveSuccess={this.showSaveSuccess} />;
    }
  }
  return ELSWithToastService(WithToast);
};

export default withToast;
