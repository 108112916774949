import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  AppLinkData,
  AppLinkReturnPostBody,
} from './eols-app-link.dtos';
import {
  Application,
} from './eols-app-link.constants';
import {
  AppLinkOutConfig
} from './eols-app-link.models';
import { addSearchParams } from '../../utilities/app.utilities';

export const getAppLinkApiUrl = (config: AppLinkOutConfig): string => {
  const { app, action } = config;
  if (app === Application.EPM_SKILL) {
    return `/sherpath/course-management/skill/app-link/${action}`;
  }
  const queryParams = {
    linkId: config.linkId,
    includeLinkHash: config.includeLinkHash,
    isCrossDomain: config.isCrossDomain,
    altSrcApp: config.altSrcApp,
    parentLinkId: config.parentLinkId
  };
  return addSearchParams(`/app-link/out/${app}/${action}`, queryParams);
};

export const getExternalAppRedirectUrl = (
  config: AppLinkOutConfig,
  token?: string
): Promise<{ redirectUrl: string }> => {

  const headers = token ? {
    Authorization: `Bearer ${token}`
  } : {};

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<{ redirectUrl: string }>(
      getAppLinkApiUrl(config),
      {
        ignoreToken: true,
        data: config.body
      },
      headers
    );
  });
};

export const getLinkNavigation = (
  props: {
    linkId: string;
    returnPostBody: Partial<AppLinkReturnPostBody>;
  }
): Promise<{ redirectUrl: string }> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<{ redirectUrl: string }>(
      `/app-link/return/${props.linkId}`,
      props.returnPostBody ? { data: props.returnPostBody } : null
    );
  });
};

export type HashLinkDto = {
  token: string;
  linkId: string;
}

export const fetchHashLink = (hashLink: string): Promise<HashLinkDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<HashLinkDto>(`/app-link/hash-link/${hashLink}`, {
      headers: {
        'X-App-ID': Application.STUDENT_STUDY
      }
    });
  });
};

export const fetchAppLinkData = (key: string): Promise<AppLinkData> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<AppLinkData>(`/app-link/data/${key}`);
  });
};
