const stopwords = 'a an and at but by for in nor of on or so the to up yet';
const defaults = stopwords.split(' ');

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const apStyleTitleCase = (value: string, options): string => {
  const configuration = options || {};

  if (!value) return '';

  const stop = configuration.stopwords || defaults;
  const keep = configuration.keepSpaces;
  const splitter = /(\s+|[-‑–—,:;!?()])/;

  return value
    .split(splitter)
    .map((word, index, all) => {
      // The splitter:
      if (index % 2) {
        if (/\s+/.test(word)) return keep ? word : ' ';
        return word;
      }

      const lower = word.toLowerCase();

      if (index !== 0 && index !== all.length - 1 && stop.includes(lower)) {
        return lower;
      }

      return capitalize(word);
    })
    .join('');
};
