import { getHourAndMinuteFromSecond } from './remediation-home.utilities';
import IconWithText from '../../components/icon-with-text/IconWithText.component';

export type TimeEstimateDisplayProps = {
  timeEstimateSeconds: number;
}
export const TimeEstimateDisplay = (props: TimeEstimateDisplayProps) => {
  return (
    <IconWithText
      iconName="clock"
      iconPrefix="gizmo"
    >
      <span>est. {getHourAndMinuteFromSecond(props.timeEstimateSeconds)}</span>
    </IconWithText>
  );
};
