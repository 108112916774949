import { omit, cloneDeep } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';

const fileName = 'error.utilities';

const getBrowserDetails = () => omit(cloneDeep(navigator), 'mimeTypes');

export const logError = ({ err, previousState, previousStateParams }) => {
  const details = {
    error: err,
    browser: getBrowserDetails(),
    previousState,
    previousStateParams
  };
  ELSLoggingService.error(fileName, details);
};

export const promiseErrorHandler = error => {
  ELSLoggingService.error(fileName, `Invoke service error: ${error.message}`, error);
  return { data: [] };
};

export const errorUtils = { logError, promiseErrorHandler };
