import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  OsmosisTokenDto,
  UserDto,
  VitalSourceDto,
} from './sherpath-course-management-service.dtos';
import {
  VitalSourceFetchConfig,
} from './sherpath-course-management-service.models';
import { AssessmentSubmissionDto } from '../eols-assessment-service/eols-assessment-service.dtos';

export const fetchVitalSource = (config: VitalSourceFetchConfig): Promise<VitalSourceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<VitalSourceDto>(`/sherpath/course-management/vst/isbn/${config.isbn}/page/${config.page}`);
  });
};

export const fetchUsers = (courseSectionIds: string[]): Promise<UserDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/sherpath/course-management/users', { courseSectionIds }));
  });
};

export const fetchAssessmentSubmissions = (assignmentId: string): Promise<AssessmentSubmissionDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/sherpath/course-management/assessment/submission', { assignmentId }));
  });
};

export const fetchOsmosisToken = (): Promise<OsmosisTokenDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get('/sherpath/course-management/osmosis/token');
  });
};
