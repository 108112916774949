/* eslint-disable @typescript-eslint/camelcase */

import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSCheckBox,
  ELSTextBox
} from '@els/els-component-form-field-react';
import FocusTrap from 'focus-trap-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import 'chartjs-plugin-datalabels';
import { locationActions } from '../../redux/location/location.actions';
import { locationSelectors } from '../../redux/location/location.selectors';
import {
  LlmModels,
  VectorSearchResponseDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { ELSDataTable } from '../../components/els.components';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import {
  PrimaryTaxonomyDto,
  PrimaryTaxonomyNodeType
} from '../../apis/rec-gateway/rec-gateway.models';
import AuthoringIsbnModal from './AuthoringIsbnModal.component';
import { isAdminUser } from '../ai-chat/ai-chat.utilities';
import { RoutePath } from '../../components/app/app.constants';
import { copyContentToClipboard } from '../../utilities/common.utilities';

const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
  location: locationSelectors.getLocation(state),
  showPageLoader: studySelectors.getIsLoading(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  adminSearchVectorAction: studyActions.adminSearchVectorAction,
  adminPromptAction: studyActions.adminPromptAction,
  fetchChatEeoIsbnsAction: studyActions.fetchChatEeoIsbnsAction,
  fetchPrimaryTaxonomyAction: studyActions.fetchPrimaryTaxonomyAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AuthoringProps = PropsFromRedux & ELSPropsFromToastService & ELSPropsFromModalService;

export type AuthoringState = {
  search: string;
  searchResults: VectorSearchResponseDto['results'];
  prompt: string;
  response: string;
  selectedSearchResults: Record<string, boolean>;
  selectedEeoIsbns: Record<string, boolean>;
  primaryTaxonomies: Record<string, string>;
  isAdminUser: boolean;
}

const defaultState: AuthoringState = {
  searchResults: [],
  search: '',
  prompt: '',
  response: '',
  selectedSearchResults: {},
  selectedEeoIsbns: {},
  primaryTaxonomies: {},
  isAdminUser: false
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AuthoringComponent = (props: AuthoringProps) => {

  const [state, setState] = useState<AuthoringState>({
    ...defaultState,
    isAdminUser: isAdminUser()
  });

  const mergeState = (newState: Partial<AuthoringState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const resolveEbookTitle = (ebookData: PrimaryTaxonomyDto, eeoIsbn: string): string => {
    if (!ebookData || !ebookData.data) {
      return eeoIsbn;
    }
    const ebookAttributes = ebookData.data.find(data => {
      return data.attributes.nodeType === PrimaryTaxonomyNodeType.BOOK;
    });
    return ebookAttributes ? ebookAttributes.attributes.displayName : eeoIsbn;
  };

  useEffect(() => {

    if (!state.isAdminUser) {
      props.redirect(RoutePath.PAGE_NOT_FOUND);
      return;
    }

    const { fetchChatEeoIsbnsAction, fetchPrimaryTaxonomyAction } = props;

    fetchChatEeoIsbnsAction().then((eeoIsbns) => {
      Promise.all(
        eeoIsbns.map((eeoIsbn) => {
          return fetchPrimaryTaxonomyAction(eeoIsbn).then((response) => {
            return { eeoIsbn, response };
          });
        })
      ).then((responses) => {
        const primaryTaxonomies = responses.reduce((acc, { eeoIsbn, response }) => {
          return {
            ...acc,
            [eeoIsbn]: resolveEbookTitle(response, eeoIsbn)
          };
        }, {});

        mergeState({
          primaryTaxonomies,
          selectedEeoIsbns: eeoIsbns.reduce((acc, eeoIsbn) => {
            return {
              ...acc,
              [eeoIsbn]: true
            };
          }, {})
        });

      });
    });

  }, []);

  const viewBookChunk = (row) => {
    const { modalService } = props;
    const modalId = 'viewBookChunk';
    modalService.openAlertModal({
      modalId,
      content: (
        <div className="">
          {row.content}
        </div>
      ),
      confirmHandler: () => {
        modalService.closeModal(modalId);
      },
    });
  };

  const getSelectAllSearchResults = (searchResults: VectorSearchResponseDto['results']): Record<string, boolean> => {
    if (!searchResults) {
      return {};
    }
    return searchResults.reduce((acc, result) => {
      return {
        ...acc,
        [result._source.chunk_id]: true
      };
    }, {});
  };
  const handleSearch = () => {

    if (!state.search) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Search is empty</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    const selectedIsbns = Object.keys(state.selectedEeoIsbns).filter((eeoIsbn) => state.selectedEeoIsbns[eeoIsbn]);

    if (!selectedIsbns.length) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>No isbns selected</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    props.adminSearchVectorAction(
      {
        queryText: state.search,
        filter: {
          bool: {
            must: [
              {
                term: {
                  content_type: 'EBK'
                }
              }
            ],
            filter: {
              bool: {
                should: Object.keys(state.selectedEeoIsbns).map((eeoIsbn) => {
                  return {
                    term: {
                      source_id: eeoIsbn
                    }
                  };
                })
              }
            }
          }
        },
        numberOfResultsToProvide: 20,
        numberOfResultsToTry: 20,
        minimumChunkLength: 0
      }
    ).then((response) => {
      mergeState({
        searchResults: response.results,
        selectedSearchResults: getSelectAllSearchResults(response.results)
      });
    });
  };

  const mapTableData = (result: VectorSearchResponseDto['results'][0]): {
    isbn: string;
    book: string;
    chapter: string;
    id: string;
    content: string;
  } => {
    return {
      isbn: result._source.source_id,
      book: result._source.title,
      chapter: result._source.bread_crumbs[0].title,
      id: result._source.chunk_id,
      content: result._source.chunk_text
    };
  };

  const getBookChunks = () => {
    if (!state.searchResults || !state.searchResults.length) {
      return '';
    }

    const chunks = state.searchResults
      .filter((result) => state.selectedSearchResults[result._source.chunk_id])
      .map(mapTableData)
      .map((chunk) => {
        return `<book_chunk>
<book_title>${chunk.book}</book_title>
<chapter_title>${chunk.chapter}</chapter_title>
<chunk>${chunk.content}</chunk>
</book_chunk>`;
      }).join('\n');

    return `<book_chunks>
${chunks}
</book_chunks>`;
  };

  const buildFinalPrompt = () => {

    const bookChunks = getBookChunks();

    return `You are an authoring bot.  Use the book_chunks to augment your knowledge.

${bookChunks}

${state.prompt}`;
  };

  const handlePrompt = () => {

    if (!state.prompt) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Prompt is empty</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    const selectedChunks = Object.keys(state.selectedSearchResults).filter((key) => state.selectedSearchResults[key]);

    if (
      !state.searchResults
      || !state.searchResults.length
      || !selectedChunks.length
    ) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Book chunks is empty</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    props.adminPromptAction(
      {
        prompt: buildFinalPrompt(),
        model_id: LlmModels.claude_3_sonnet,
        temperature: 0
      }
    ).then((response) => {
      mergeState({ response: response.outputText });
    });
  };

  const getTableData = (): {
    isbn: string;
    book: string;
    chapter: string;
    id: string;
    content: string;
  }[] => {
    return state.searchResults.map(mapTableData);
  };

  const isSearchChecked = (row: {
    isbn: string;
    book: string;
    chapter: string;
    id: string;
    content: string;
  }, selectedMap: Record<string, boolean>) => {
    return selectedMap[row.id];
  };

  const handleSearchCheck = (row: {
    isbn: string;
    book: string;
    chapter: string;
    id: string;
    content: string;
  }, isSelected: boolean) => {

    setState((prevState) => {
      return {
        ...prevState,
        selectedSearchResults: {
          ...prevState.selectedSearchResults,
          [row.id]: isSelected
        }
      };
    });
  };

  const selectAll = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedSearchResults: getSelectAllSearchResults(state.searchResults)
      };
    });
  };

  const deselectAll = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedSearchResults: {}
      };
    });
  };

  const handleCopy = () => {
    copyContentToClipboard(state.response);
    props.toastService.openToast({
      ...BASE_TOAST_CONFIG,
      component: (
        <div>Successfully copied</div>
      ),
      type: ELSWithToastService.types.POSITIVE
    });
  };

  const handleSelectedIsbnModalOpen = () => {
    const {
      modalService,
    } = props;
    const modalId = 'selectedIsbnModal';
    modalService.openModal({
      modalId,
      content: (
        <FocusTrap>
          <AuthoringIsbnModal
            modalId={modalId}
            modalService={modalService}
            handleSave={(selectedEeoIsbns) => {
              mergeState({ selectedEeoIsbns });
              modalService.closeModal(modalId);
            }}
            selectedEeoIsbns={state.selectedEeoIsbns}
            primaryTaxonomies={state.primaryTaxonomies}
          />
        </FocusTrap>
      )
    });
  };

  if (!isAdminUser) {
    return null;
  }

  return (
    <div className="u-els-padding c-els-authoring">
      <div className="o-els-container">
        <h2>Authoring POC</h2>
      </div>

      {
        state.response && (
          <div className="o-els-container">
            <h4>Response</h4>
            <div>
              <button
                type="button"
                className="u-els-anchorize"
                onClick={handleCopy}
              >
                copy
              </button>
            </div>
            <div className="o-els-container">
              <pre>{state.response}</pre>
            </div>
          </div>
        )
      }

      <div className="o-els-container">
        <h4>Prompt</h4>
        <div className="c-els-field">
          <textarea
            name="converter"
            className="c-els-field__input"
            value={state.prompt}
            onChange={(e) => {
              const { value } = e.target;
              setState((prevState) => {
                return {
                  ...prevState,
                  prompt: value
                };
              });
            }}
          />
        </div>
      </div>
      <div className="o-els-container">
        <button
          type="button"
          className="c-els-button c-els-button--small"
          onClick={handlePrompt}
        >
          Send final prompt to AI
        </button>
      </div>

      <div>
        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
          <FlexItem classes={['u-els-width-1o2']}>
            <h3>Full prompt preview</h3>
            <div className="o-els-container">
              <pre>{buildFinalPrompt()}</pre>
            </div>
          </FlexItem>
          <FlexItem classes={['u-els-width-1o2']}>
            <h3>Book chunks</h3>

            <div className="o-els-container">
              <ELSTextBox
                name="editChatTitleInput"
                value={state.search}
                changeHandler={(e, title) => {
                  mergeState({
                    search: title
                  });
                }}>
                Search
              </ELSTextBox>

              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_1o2,
                FlexLayoutModifier.LEFT
              ]}>
                <FlexItem>
                  Selected isbns:
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-anchorize"
                    onClick={handleSelectedIsbnModalOpen}
                  >
                    {Object.keys(state.selectedEeoIsbns).filter((key) => {
                      return state.selectedEeoIsbns[key];
                    }).length}
                  </button>
                </FlexItem>
              </FlexLayout>
            </div>
            <div className="o-els-container">
              <button
                type="button"
                className="c-els-button c-els-button--small"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            {state.searchResults.length > 0 && (
              <div className="o-els-container">
                <div className="o-els-container">
                  <FlexLayout modifiers={[
                    FlexLayoutModifier.LEFT,
                    FlexLayoutModifier.GUTTERS
                  ]}>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={selectAll}
                      >
                        select all
                      </button>
                    </FlexItem>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={deselectAll}
                      >
                        deselect all
                      </button>
                    </FlexItem>
                  </FlexLayout>

                </div>

                <h4>Search Results</h4>
                <div className="o-els-container">
                  <ELSDataTable
                    data={getTableData()}
                    noWrap
                    sortIconSize="1o2"
                  >
                    <column
                      header=" "
                      customRender={(row) => {
                        return (
                          <ELSCheckBox
                            changeHandler={(e, value) => handleSearchCheck(row, value)}
                            checked={isSearchChecked(row, state.selectedSearchResults)}
                          />
                        );
                      }}
                    />
                    <column header="details"
                            customRender={(row) => {
                              return (
                                <>
                                  <div>{row.book}</div>
                                  <div>isbn: {row.isbn}</div>
                                  <div>{row.chapter}</div>
                                </>
                              );
                            }}
                    />
                    <column field="content"
                            header="content"
                            customRender={(row) => {
                              return (
                                <>
                                  <div>{row.content.length > 400 ? `${row.content.substring(0, 400)}...` : row.content} </div>
                                  {row.content.length > 400 && (
                                    <div>
                                      <button
                                        type="button"
                                        className="u-els-anchorize"
                                        onClick={() => viewBookChunk(row)}
                                      >
                                        view full content
                                      </button>
                                    </div>
                                  )}
                                </>
                              );
                            }}
                    />
                  </ELSDataTable>
                </div>
              </div>
            )}
          </FlexItem>
        </FlexLayout>
      </div>
    </div>
  );
};

const enhancers = [
  connector,
  ELSWithToastService,
  ELSWithModalService,
  withPageLoader // This must come after connect
];

const Authoring = compose<null, null>(...enhancers)(AuthoringComponent);

export default Authoring;
