import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import withToast from '../../hocs/with-toast/withToast.hoc';
import { ELSPropsFromToastService, ELSWithToastService } from '../els.components';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';

const mapStateToProps = state => ({
  errorsWithout404: studySelectors.getToastableErrors(state),
  conflictError: studySelectors.getConflictErrors(state)
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type ErrorNotificationProps = PropsFromRedux & ELSPropsFromToastService;

export class ErrorNotification extends Component<ErrorNotificationProps, {}> {

  componentDidUpdate(prevProps: ErrorNotificationProps) {
    const { errorsWithout404: prevErrorsWithout404 } = prevProps;
    const { errorsWithout404, toastService, conflictError } = this.props;
    if (errorsWithout404.length !== prevErrorsWithout404.length) {
      if (conflictError && conflictError.length > 0) {
        toastService.openToast({
          ...BASE_TOAST_CONFIG,
          component: (
            <div>
              <p>Course Plan Conflict</p>
              <p>
                There was a conflict while updating your Course Plan.
                This usually occurs when your Course Plan is being updated simultaneously on another tab or by another user.
                Please refresh the page and try again.
                If the problem persists please contact your administrator.
              </p>
            </div>
          ),
          type: ELSWithToastService.types.NEGATIVE
        });
      } else {
        toastService.openToast({
          ...BASE_TOAST_CONFIG,
          component: (
            <div>
              <p>Request Error</p>
              <p>Please refresh the page and try again. If the problem persists please contact your administrator.</p>
            </div>
          ),
          type: ELSWithToastService.types.NEGATIVE
        });
      }
    }
  }

  render() {
    return <></>;
  }
}

export default withToast(connector(ErrorNotification));
