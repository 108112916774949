import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { studentStudyInitialState } from './student-study/studentStudy.constants';
import { StudentStudyStore } from './student-study/studentStudy.models';
import {
  omitStateKeys,
  RootState
} from './redux.constants';
import { AssessmentSubmissionDto } from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { CourseSectionDto } from '../apis/eols-course-crud/eols-course-crud.dtos';
import institutions from '../constants/institutions.constants.json';
import { OsmosisTokenDto } from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { EvolveProductDto } from '../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

const filterNotFoundError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 404) {
    return false;
  }
  if (get(error, 'error.response.status', '') === 404) {
    return false;
  }
  if (get(error, 'response.statusCode', '') === 404) {
    return false;
  }
  return true;
});

const includesConflictError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 409) {
    return true;
  }
  if (get(error, 'error.response.status', '') === 409) {
    return true;
  }
  if (get(error, 'response.statusCode', '') === 409) {
    return true;
  }
  return false;
});

const omitProps = (rootState: RootState): RootState => {
  const studentStudyState = reduce(rootState.studentStudyState, (acc, cur, key) => {
    if (omitStateKeys[key]) {
      return acc;
    }
    return {
      ...acc,
      [key]: cur,
    };
  }, {}) as StudentStudyStore;
  return {
    ...rootState,
    studentStudyState,
  };
};

const omitPropsWithDefaultValue = (rootState: RootState): RootState => {
  const studentStudyState = reduce(rootState.studentStudyState, (acc, cur, key) => {
    if (studentStudyInitialState[key] !== cur) {
      return {
        ...acc,
        [key]: cur,
      };
    }
    return acc;
  }, {}) as StudentStudyStore;
  return {
    ...rootState,
    studentStudyState,
  };
};

const getAssessmentSubmissionsMap = (
  assessmentSubmissionsResponses: {
    assignmentId: string;
    assessmentSubmissions: AssessmentSubmissionDto[];
  }[]
) => {
  if (!assessmentSubmissionsResponses || !assessmentSubmissionsResponses.length) {
    return {};
  }
  return assessmentSubmissionsResponses.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.assignmentId]: cur.assessmentSubmissions
    };
  }, {});
};

const getEvolveResourcesFromCourseSection = (
  isbns: string[],
  vantageComponentIsbns: string[],
  currentCourse: CourseSectionDto
): EvolveProductDto[] => {

  const _isbns = vantageComponentIsbns || isbns;

  if (isEmpty(_isbns) || isEmpty(currentCourse) || isEmpty(currentCourse.entitlements)) {
    return [];
  }

  return _isbns.reduce((_acc, isbn) => {
    return currentCourse.entitlements.reduce((acc, entitlement) => {
      if (isbn === entitlement.isbn) {
        return [...acc, JSON.parse(entitlement.data)];
      }
      return acc;
    }, _acc);
  }, []);
};

const getNormalizedInstitution = (userEmailDomain: string) => {
  let institution = 'Unknown';
  if (!userEmailDomain) {
    return institution;
  }
  const cleanedUserEmailDomain = userEmailDomain.trim().toLowerCase();
  institution = institutions[cleanedUserEmailDomain] || institution;
  return institution;
};

const shouldGetNewOsmosisToken = (osmosisTokenDto: OsmosisTokenDto) => {
  if (!osmosisTokenDto || !osmosisTokenDto.expires) {
    return true;
  }
  return moment.utc(osmosisTokenDto.expires).add({ hours: -10 }).isBefore(moment());
};

export {
  filterNotFoundError,
  includesConflictError,
  omitProps,
  omitPropsWithDefaultValue,
  getAssessmentSubmissionsMap,
  getEvolveResourcesFromCourseSection,
  getNormalizedInstitution,
  shouldGetNewOsmosisToken
};
