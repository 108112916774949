export const osmosisVideoUrl = 'https://www.osmosis.org';

// eslint-disable-next-line max-len
export const TEMP_USER_CHAT_ID = 7777777777;
export const TEMP_BOT_CHAT_ID = 8888888888;
export const ADMIN_MODAL_ID = 'ADMIN_MODAL_ID';

export enum ChatConnectionProtocol {
  HTTP_STREAM = 'HTTP_STREAM',
  WEB_SOCKETS = 'WEB_SOCKETS',
  HTTP_POST = 'HTTP_POST',
}

export const MAX_PROMPT_LENGTH = 2500;
export const MAX_PROMPT_LENGTH_DISPLAY = '2,500';

// These will move to database and can be removed after FLOR-1244
export const tempTitlesFromPrimaryTax: Record<number, string> = {
  9780323498081: null,
  9780323672825: null,
  9780323674577: "Nix: Williams' Basic Nutrition and Diet Therapy, Sixteenth Edition",
  9780323693974: "MacDonald: Stanhope & Lancaster's Community Health Nursing in Canada, 4th Edition",
  9780323694780: 'Williams: Fundamental Concepts and Skills for Nursing, Sixth Edition',
  9780323696081: "Killian: Morris's Calculate with Confidence, Canadian Edition, Second Edition",
  9780323696784: 'Urden: Critical Care Nursing, Ninth Edition',
  9780323696975: 'Morris: Calculate with Confidence, Eighth Edition',
  9780323697132: 'Huber: Leadership and Nursing Care Management, Seventh Edition',
  9780323698054: "Touhy: Ebersole and Hess' Gerontological Nursing & Healthy Aging, Sixth Edition",
  9780323759212: "Keenan-Lindsay: Perry's Maternal Child Nursing Care in Canada, 3rd Edition",
  9780323759670: 'Sole: Introduction to Critical Care Nursing, Eighth Edition',
  9780323762939: 'LoBiondo-Wood: Nursing Research, Tenth Edition',
  9780323763684: "Mallette: Arnold and Bogg's Interpersonal Relationships, 1st Edition",
  9780323778763: 'Boscart: Ebersole and Hess’ Gerontological Nursing & Healthy Aging in Canada, Third Edition',
  9780323778817: "Pollard: Varcarolis's Psychiatric Mental Health Nursing, Canadian Edition, 3",
  9780323778862: "Power-Kean: Huether & McCance's Understanding Pathophysiology, Canadian Edition 2e",
  9780323791588: "Tyerman: Lewis's Medical-Surgical Nursing in Canada, 5th Edition",
  9780323791984: "Steele: Keltner's Psychiatric Nursing, 9th edition",
  9780323792035: 'Turner: Mulholland’s The Nurse, The Math, The Meds, 5th Edition',
  9780323809412: 'Leifer: Growth and Development Across the Lifespan, 3rd Edition',
  9780323809917: 'Urden: Priorities in Critical Care Nursing, 9th Edition',
  9780323811446: 'Morrison-Valfre: Foundations of Mental Health Care, 8e',
  9780323811682: 'Touhy: Toward Healthy Aging, 11e',
  9780323811750: 'Carroll:  Success in Practical/Vocational Nursing From Student to Leader 10e',
  9780323811866: 'Stromberg: Medical-Surgical Nursing, 5th Edition',
  9780323811941: 'Grodner Nutritional Foundations 8e Chapter',
  9780323812085: 'Giddens: Concepts for Nursing Practice, 4th Edition',
  9780323812122: 'Kee: Clinical Calculations, 10th Edition',
  9780323812269: 'Zerwekh Nursing Today 11e Chapter',
  9780323812566: 'Willihnganz: Basic Pharmacology for Nurses, 19th Edition',
  9780323824279: 'Cherry: Contemporary Nursing, 9th edition',
  9780323825498: 'Banasik: Pathophysiology, 7th Edition',
  9780323825726: 'Workman: Understanding Pharmacology, 3rd Edition',
  9780323826143: 'Cooper: Adult Health Nursing, 9th Edition',
  9780323826426: 'Grove: Understanding Nursing Research, 8th Edition',
  9780323827065: 'Cooper: Foundations and Adult Health Nursing, 9th Edition',
  9780323827119: 'Cooper: Foundations of Nursing, 9th Edition',
  9780323827560: 'Black: Professional Nursing, 10e',
  9780323828451: 'Linton: Medical-Surgical Nursing, 8th Edition',
  9780323828895: 'Williams: Basic Geriatric Nursing, 8e',
  9780323830911: 'Leifer: Introduction to Maternity and Pediatric Nursing, 9th edition',
  9780323831833: 'Nies: Community/Public Health Nursing, 8th Edition',
  9780323846295: 'Edelman: Health Promotion Throughout the Life Span, 10th Edition',
  9780323846486: 'Hardy: Health Informatics, 3rd edition',
  9780323870672: 'Astle: Potter and Perry’s Canadian Fundamentals of Nursing, 7th Edition',
  9780323871426: 'Boggs: Interpersonal Relationships: Professional Communication Skills for Nurses, 9e',
  9780323875219: 'Jarvis: Physical Examination and Health Assessment, Canadian, 4e',
  9780323935579: 'Friberg: Conceptual Foundations, 8e Chapter',
  9780323935975: "Gilbert: Williams' Essentials of Nutrition and Diet Therapy, 13th edition",
  9780443115967: 'Visovsky: Introduction to Clinical Pharmacology, Eleventh Edition',
  9780323828116: 'Yoost Fundamentals of Nursing 3e Chapter',
  9780323792332: 'Harding: Lewis’s Medical-Surgical Nursing, 12th Edition',
  9780443108686: "Burchum: Lehne's Pharmacology for Nursing Care, 12e",
  9780323932974: "Hockenberry: Wong's Nursing Care of Infants and Children, 12e",
  9780323884198: 'Stanhope:  Public Health Nursing, 11th Edition',
  9780323812160: 'Potter: Fundamentals of Nursing, 11th Edition',
  9780323697095: 'Halter: Foundations of Psychiatric-Mental Health Nursing, Ninth Edition',
  9780323827997: 'Lilley: Pharmacology and the Nursing Process, 10th Edition',
  9780323931274: 'Ignatavicius: Medical-Surgical Nursing, 11th Edition',
  9780323811798: "Lowdermilk: Maternity and Women's Health Care, 13th edition",
  9780323825856: 'Perry: Maternal Child Nursing Care, 7th Edition',
  9780323830874: 'Ogden: Calculation of Drug Dosages, 12th Edition',
  9780323776905: 'Stanhope: Foundations of Population Health for Community/Public Health Nursing, 6th Edition',
  9780323761864: 'Ball: Seidel’s Guide to Physical Examination, 10th Edition',
  9780323846387: "Murray: Foundations of Maternal-Newborn and Women's Health Nursing, 8e",
  9780323697903: 'McKinney: Maternal-Child Nursing, Sixth Edition',
  9780323636469: null,
  9780323758604: 'Bonewit-West: Clinical Procedures for Medical Assisting, 11th edition',
  9780323765053: 'Robinson: Essentials of Dental Assisting, 7th Edition',
  9780323824521: "Niedzwiecki: Kinn's Medical Assisting Fundamentals, Second Edition",
  9780323882439: 'Robinson: Modern Dental Assisting, 14th Edition',
  9780323883023: "Niedzwiecki: Kinn's The Medical Assistant, 15th Edition",
  9780323883139: "Niedzwiecki: Kinn's The Clinical Medical Assistant, 15th Edition",
  9780443106613: "Niedzwiecki: Kinn's The Administrative Medical Assistant, 15th Edition",
  9780323789905: 'Rogers: McCance & Huether’s Pathophysiology: The Biologic Basis for Disease in Adults and Children, Ninth Edition',
  9780323763202: 'Wilson: Health Assessment for Nursing Practice, Seventh Edition',
  9780323811484: "Fosbre: Varcarolis' Essentials of Psychiatric Mental Health Nursing, 5th Edition",
  9780323683210: "Hockenberry: Wong's Essentials of Pediatric Nursing, Eleventh Edition",
  9780323793179: 'McCuistion: Pharmacology, 11th Edition',
  9780323811729: 'Jarvis: Physical Examination & Health Assessment, 9e',
  9780323598132: null,
  9780323937405: null,
  9780323934268: 'McQuillen Martensen: Radiographic Image Analysis, 6th Edition',
  9780443119354: 'Eisenberg: Comprehensive Radiographic Pathology, 8th Edition',
  9780323872225: null,
  9780323414418: null,
  9780323695527: 'Iannucci: Dental Radiography, 6th Edition',
  9780323709316: null
};

// These will move to database and can be removed after FLOR-1244
export const tempTitlesFromEvolve: Record<number, string> = {
  9780323498081: 'Gerontologic Nursing, 6th Edition',
  9780323672825: 'Understanding Pathophysiology, 7th Edition',
  9780323636469: "Today's Medical Assistant, 4th Edition",
  9780323598132: 'Lehne’s Pharmacotherapeutics for Advanced Practice Nurses and Physician Assistants, 2nd Edition',
  9780323937405: 'Radiographic Positioning and Related Anatomy - Elsevier E-Book on VitalSource, 11th Edition',
  9780323872225: 'Introduction to Radiologic and Imaging Sciences and Patient Care, 8th Edition',
  9780323414418: "Gould's Pathophysiology for the Health Professions, 6th Edition",
  9780323709316: "Anthony's Textbook of Anatomy & Physiology, 21st Edition"
};
