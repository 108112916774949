/* eslint-disable max-len */
import { LANGUAGE_KEY } from './message.constants';
import en from './en';

const de: Record<LANGUAGE_KEY, string> = Object.keys(en).reduce((acc, cur) => {
  acc[cur] = cur;
  return acc;
}, { ...en });

export default de;
