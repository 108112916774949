import React from 'react';
import { _ReactChildren } from '../../models/common.models';

interface IsRenderProps {
  isRender: boolean;
  children: _ReactChildren;
}

const IsRender = (props: IsRenderProps) => {
  return props.isRender ? <>{props.children}</> : <></>;
};

export default IsRender;
