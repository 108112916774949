import { RemRecContentTypeDto } from '../../apis/florence-facade/florence-facade.dtos';

export enum RemediationActivityType {
  REVIEW = 'REVIEW',
  APPLY = 'APPLY',
  ASSESS = 'ASSESS',
}

export enum RemediationGoalType {
  TAXON = 'TAXON',
  CONTENT_TYPE = 'CONTENT_TYPE',
}

export const ActivityTypeConfigMap: Record<RemediationActivityType, {
  title: string;
  iconName: string;
  iconPrefix: string;
}> = {
  [RemediationActivityType.REVIEW]: {
    title: 'Review',
    iconName: 'books',
    iconPrefix: 'gizmo',
  },
  [RemediationActivityType.APPLY]: {
    title: 'Apply',
    iconName: 'laptop',
    iconPrefix: 'gizmo',
  },
  [RemediationActivityType.ASSESS]: {
    title: 'Assess',
    iconName: 'approve-document',
    iconPrefix: 'gizmo',
  },
};

export const RemediationContentTypeConfigMap: Record<RemRecContentTypeDto, {
  displayName: string;
  titlePlural: string;
  activityType: RemediationActivityType;
  iconName: string;
  iconPrefix: string;
  sortOrder: number;
}> = {
  [RemRecContentTypeDto.OSMOSIS_VIDEO]: {
    displayName: 'Osmosis video',
    titlePlural: 'Videos',
    activityType: RemediationActivityType.REVIEW,
    iconName: 'video',
    iconPrefix: 'gizmo',
    sortOrder: 1,
  },
  [RemRecContentTypeDto.HESI_CASE_STUDY]: {
    displayName: 'Case study',
    titlePlural: 'Case Studies',
    activityType: RemediationActivityType.APPLY,
    iconName: 'laptop',
    iconPrefix: 'gizmo',
    sortOrder: 0,
  },
  [RemRecContentTypeDto.EBOOK_CHUNK]: {
    displayName: 'eBook excerpt',
    titlePlural: 'Readings',
    activityType: RemediationActivityType.REVIEW,
    iconName: 'books',
    iconPrefix: 'gizmo',
    sortOrder: 0,
  },
  [RemRecContentTypeDto.EAQ]: {
    displayName: 'Quiz',
    titlePlural: 'Quiz Questions',
    activityType: RemediationActivityType.ASSESS,
    iconName: 'approve-document',
    iconPrefix: 'gizmo',
    sortOrder: 0,
  },
  [RemRecContentTypeDto.NGN_CASE_STUDY]: null,
  [RemRecContentTypeDto.SHERPATH_LESSON]: null,
};

export type RemediationStatusCounts = {
  completed: number;
  total: number;
  attempted: number;
}
