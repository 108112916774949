import React, {
  useEffect,
  useState
} from 'react';
import { ELSButton } from '@els/els-component-button-react';
import cx from 'classnames';
import moment from 'moment';
import { ELSModalService } from '@els/els-component-modal-react';
import {
  ELSIcon,
  ELSTextBox
} from '@els/els-component-form-field-react';
import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { Messages } from '../../translations/message.models';
import { ChatDto, } from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { DATE_TIME_PRIMARY } from '../../constants/date.constants';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import {
  validateStringLength,
  validateStringMinLength
} from '../../utilities/validator.utilities';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { getSortedChats } from './ai-chat.utilities';

export const PreviousChatsModalId = 'PreviousChatsModalId';

export type PreviousChatsModalProps = {
  chats: ChatDto[];
  activeChatId: number;
  messages: Messages;
  handleEditChatTitle: (chat: ChatDto) => void;
  handleChatClick: (chat: ChatDto, sendClickEvent: boolean, checkLoading: boolean) => void;
  modalService: ELSModalService;
  toastService: ELSToastService;
  trackAction: (props: AnalyticsActionProps) => void;
}

export type PreviousChatsModalState = {
  chats: ChatDto[];
  editingChat: ChatDto;
}

const defaultState: PreviousChatsModalState = {
  chats: [],
  editingChat: null,
};

const PreviousChatsModal = (props: PreviousChatsModalProps) => {
  const {
    activeChatId,
    handleEditChatTitle,
    handleChatClick,
    modalService,
    messages,
    trackAction,
    toastService
  } = props;

  const [state, setState] = useState<PreviousChatsModalState>(defaultState);

  const mergeState = (newState: Partial<PreviousChatsModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  useEffect(() => {
    mergeState({ chats: props.chats });
  }, []);

  useEffect(() => {
    if (state.editingChat) {
      const textBox: HTMLInputElement = document.querySelector('input[name="editChatTitleInput"]');
      if (textBox) {
        textBox.focus();
      }
    }
  }, [state.editingChat]);

  const onClickCancel = () => {
    modalService.closeModal(PreviousChatsModalId);
  };

  const handleEditChatTitleSave = () => {
    if (!state.editingChat || !state.editingChat.title || state.editingChat.title.trim().length <= 0) {
      toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Title entry not valid</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    handleEditChatTitle(state.editingChat);

    mergeState({
      editingChat: null,
      chats: state.chats.map((chat) => {
        if (chat.id !== state.editingChat.id) {
          return chat;
        }
        return {
          ...state.editingChat,
        };
      })
    });

  };

  const handleEditChatTitleCancel = () => {
    mergeState({
      editingChat: null,
    });
  };

  const handleEditChatTitleClick = (chat: ChatDto) => {

    mergeState({
      editingChat: chat,
    });

    trackAction({
      action: AnalyticsAction.AI_CHAT_EDIT_CLICK,
      props: {
        targetChatId: chat.id,
        targetChatTitle: chat.title,
        isTargetActive: chat.id === activeChatId,
      }
    });

  };

  return (
    <div>
      <div className="c-els-modal">
        <div className="c-els-modal__window u-els-width-1o1@mobile c-ssa-previous-chats-modal">
          <div className="c-ssa-previous-chats-modal__top">
            <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS]}>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <h3>Previous chats</h3>
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="u-els-debuttonize"
                  onClick={() => onClickCancel()}
                >
                  <ELSIcon name="close" size="1x" />
                  <span className="u-els-hide-visually">Close</span>
                </button>
              </FlexItem>
            </FlexLayout>
          </div>
          <ol
            aria-label="List of previous chats"
            className="c-ssa-previous-chats-modal__main"
          >
            {
              state.chats && getSortedChats(state.chats).map((chat) => {
                return (
                  <li
                    key={chat.id}
                    aria-label={`Chat title: ${chat.title}`}
                    className={cx('o-els-container o-els-container--1o2 u-els-padding-1o2', {
                      'u-els-background-color-n0': chat.id === activeChatId
                    })}
                  >
                    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]} classes={['c-ssa-previous-chats-modal__title-column']}>
                        {state.editingChat && state.editingChat.id === chat.id && (
                          <div>
                            <ELSTextBox name="editChatTitleInput"
                                        value={state.editingChat.title}
                                        validators={[
                                          validateStringLength(state.editingChat.title, 255, messages.ENTRY_EXCEEDS_LENGTH_LIMIT),
                                          validateStringMinLength(state.editingChat.title, 1, messages.VALUE_REQUIRED)
                                        ]}
                                        changeHandler={(e, title) => {
                                          mergeState({
                                            editingChat: {
                                              ...state.editingChat,
                                              title
                                            }
                                          });
                                        }}>
                              <span className="u-els-hide-visually">Title</span>
                            </ELSTextBox>
                            <div><small>{moment(chat.createdAt).format(DATE_TIME_PRIMARY)}</small></div>
                          </div>
                        )}
                        {(!state.editingChat || state.editingChat.id !== chat.id) && (
                          <>
                            <button
                              className="u-els-anchorize u-els-debuttonize u-els-display-block c-ssa-previous-chats-modal__title-button"
                              type="button"
                              onClick={() => handleChatClick(chat, true, true)}
                              title={chat.title}
                            >
                              {chat.title}
                            </button>
                            <div><small>{moment(chat.createdAt).format(DATE_TIME_PRIMARY)}</small></div>
                          </>
                        )}
                      </FlexItem>
                      <FlexItem>
                        {state.editingChat && state.editingChat.id === chat.id && (
                          <div>
                            <div className="u-els-margin-bottom-1o2">
                              <button
                                className="u-els-anchorize u-els-debuttonize u-els-display-block"
                                type="button"
                                onClick={() => handleEditChatTitleSave()}
                              >
                                <small>Save</small>
                              </button>
                            </div>
                            <div>

                              <button
                                className="u-els-anchorize u-els-debuttonize u-els-display-block"
                                type="button"
                                onClick={() => handleEditChatTitleCancel()}
                              >
                                <small>Cancel</small>
                              </button>
                            </div>
                          </div>
                        )}
                        {(!state.editingChat || state.editingChat.id !== chat.id) && (
                          <button
                            className="u-els-anchorize u-els-debuttonize u-els-display-block"
                            type="button"
                            onClick={() => handleEditChatTitleClick(chat)}
                          >
                            <small>Edit</small>
                          </button>
                        )}
                      </FlexItem>
                    </FlexLayout>
                  </li>
                );
              })
            }
          </ol>
          <div className="c-ssa-previous-chats-modal__bottom">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.WRAP,
              FlexLayoutModifier.LEFT
            ]}>
              <FlexItem>
                <ELSButton type={ELSButtonType.SECONDARY}
                           size={ELSButtonSize.SMALL}
                           onClick={onClickCancel}>
                  Close
                </ELSButton>
              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousChatsModal;
