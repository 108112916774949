import { ELSButton } from '@els/els-component-button-react';
import { ELSMenu } from '@els/els-component-menu-react';
import React, { SyntheticEvent } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSIcon } from '@els/els-component-form-field-react';
import { FlexLayoutModifier } from '../flex/flex.constants';
import { FlexItem } from '../flex/FlexItem.component';
import { FlexLayout } from '../flex/FlexLayout.component';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { RoutePath } from '../app/app.constants';
import { locationActions } from '../../redux/location/location.actions';
import {
  selfStudyProgress,
  topicPerformance
} from '../../pages/eaq-student-study/eaq-student-study.constants';
import { locationSelectors } from '../../redux/location/location.selectors';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { StudentStudyNavPage } from '../../constants/studentStudy.constants';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  location: locationSelectors.getLocation(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state)
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
};

type StudentStudyHeaderNavPropsOnly = {
    handleReturnNavigation: (e: SyntheticEvent<Element, Event>) => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type StudentStudyHeaderNavProps = PropsFromRedux & StudentStudyHeaderNavPropsOnly;

const StudentStudyHeaderNavComponent = (props: StudentStudyHeaderNavProps) => {

  const {
    messages,
    handleReturnNavigation,
    location,
    featureFlagsGrouped,
    courseSectionId,
  } = props;

  const isNavigationTabsEnabled = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped, FEATURE_FLAG.ENABLE_SELF_STUDY_NAVIGATION_TABS, courseSectionId
  );

  const isMenuItemDisabled = (item: StudentStudyNavPage): boolean => {
    const currentPath = location.pathname;

    if (currentPath === RoutePath.EAQ_STUDENT_STUDY
        && item === StudentStudyNavPage.SELF_STUDY_PROGRESS) {
      return true;
    }
    if (currentPath === RoutePath.EAQ_STUDENT_TOPIC_REPORT
          && item === StudentStudyNavPage.TOPIC_PERFORMANCE) {
      return true;
    }
    return false;
  };

  return (
    <div className="c-els-page-header">
      <FlexLayout
          modifiers={[FlexLayoutModifier.MIDDLE]}
          classes={['c-ssa-nav-group']}
      >
        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
          <FlexLayout
              modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS_2X]}
              classes={['c-ssa-nav-group']}
          >
            <FlexItem>
              <button
                  className="c-els-page-header__close-trigger u-els-debuttonize u-els-anchorize"
                  type="button"
                  onClick={handleReturnNavigation}
              >
                <ELSIcon name="close" size="1x" />
                <span className="u-els-hide-visually">{messages.CLOSE}</span>
              </button>
            </FlexItem>

            <FlexItem
              modifiers={[FlexLayoutModifier.GROW]}
              classes={['u-els-margin']}
            >
              <span className="c-els-page-header__title">{messages.STUDENT_STUDY}</span>
            </FlexItem>
          </FlexLayout>
        </FlexItem>
        {isNavigationTabsEnabled && (
          <FlexItem modifiers={[FlexLayoutModifier.RIGHT]}>
            <FlexLayout>
              <FlexItem
                  classes={['u-els-display-block u-els-display-none@mobile u-els-display-none@tablet']}
              >
                <ELSButton
                    isDisabled={isMenuItemDisabled(StudentStudyNavPage.SELF_STUDY_PROGRESS)}
                    className="c-ssa-student-study-page__nav-buttons"
                    onClick={() => {
                      props.redirect(RoutePath.EAQ_STUDENT_STUDY);
                    }}
                >
                  {messages.SELF_STUDY_PROGRESS}
                </ELSButton>
              </FlexItem>
              <FlexItem
                  classes={['u-els-display-block u-els-display-none@mobile u-els-display-none@tablet']}
              >
                <ELSButton
                    isDisabled={isMenuItemDisabled(StudentStudyNavPage.TOPIC_PERFORMANCE)}
                    className="c-ssa-student-study-page__nav-buttons"
                    onClick={() => {
                      props.redirect(RoutePath.EAQ_STUDENT_TOPIC_REPORT);
                    }}
                >
                  {messages.TOPIC_PERFORMANCE}
                </ELSButton>
              </FlexItem>

              <FlexItem
                  classes={['u-els-display-none u-els-display-block@mobile u-els-display-block@tablet']}
              >
                <ELSMenu
                    buttonId="navHeader"
                    buttonClassName="c-ssa-student-study-page__nav-button-menu"
                    icon="menu"
                    menuItems={[
                      {
                        content: messages.SELF_STUDY_PROGRESS,
                        isDisabled: isMenuItemDisabled(StudentStudyNavPage.SELF_STUDY_PROGRESS),
                        value: selfStudyProgress
                      },
                      {
                        content: messages.TOPIC_PERFORMANCE,
                        isDisabled: isMenuItemDisabled(StudentStudyNavPage.TOPIC_PERFORMANCE),
                        value: topicPerformance
                      },
                    ]}
                    onItemClick={(e) => {
                      if (e.value === selfStudyProgress) {
                        return props.redirect(RoutePath.EAQ_STUDENT_STUDY);
                      }
                      if (e.value === topicPerformance) {
                        return props.redirect(RoutePath.EAQ_STUDENT_TOPIC_REPORT);
                      }
                      return null;
                    }}
                />
              </FlexItem>
            </FlexLayout>
          </FlexItem>
        )}
      </FlexLayout>

    </div>
  );
};

const enhancers = [connector];

const StudentStudyHeaderNav = compose<null, StudentStudyHeaderNavPropsOnly>(...enhancers)(StudentStudyHeaderNavComponent);
export default StudentStudyHeaderNav;
