import React, {
  useEffect,
  useState
} from 'react';
import { ELSButton } from '@els/els-component-button-react';
import cx from 'classnames';
import { isNil, } from 'lodash';
import { ELSIcon } from '@els/els-component-form-field-react';
import {
  ChatEntryDto,
  ChatEntryEvaluationDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';

export enum UserFeedbackDimension {
  ACCURACY = 'ACCURACY',
  COHERENCY = 'COHERENCY',
  ORGANIZATION = 'ORGANIZATION',
  RELEVANCY = 'RELEVANCY',
  DETAIL = 'DETAIL',
}

export enum UserFeedbackOptionId {
  INACCURATE = 'INACCURATE',
  ACCURATE = 'ACCURATE',
  HARD_TO_UNDERSTAND = 'HARD_TO_UNDERSTAND',
  EASY_TO_UNDERSTAND = 'EASY_TO_UNDERSTAND',
  DISORGANIZED = 'DISORGANIZED',
  ORGANIZED = 'ORGANIZED',
  DID_NOT_ANSWER_THE_QUESTION = 'DID_NOT_ANSWER_THE_QUESTION',
  ANSWERED_THE_QUESTION = 'ANSWERED_THE_QUESTION',
  TOO_MUCH_DETAIL = 'TOO_MUCH_DETAIL',
  NOT_ENOUGH_DETAIL = 'NOT_ENOUGH_DETAIL',
  JUST_ENOUGH_DETAIL = 'JUST_ENOUGH_DETAIL',
}

export type UserFeedbackOption = {
  title: string;
  rating: number;
  dimension: UserFeedbackDimension;
  id: UserFeedbackOptionId;
}

export const UserFeedbackOptionMap: Record<UserFeedbackOptionId, UserFeedbackOption> = {
  [UserFeedbackOptionId.INACCURATE]: {
    id: UserFeedbackOptionId.INACCURATE,
    title: 'Inaccurate',
    rating: 0,
    dimension: UserFeedbackDimension.ACCURACY
  },
  [UserFeedbackOptionId.ACCURATE]: {
    id: UserFeedbackOptionId.ACCURATE,
    title: 'Accurate',
    rating: 2,
    dimension: UserFeedbackDimension.ACCURACY
  },
  [UserFeedbackOptionId.HARD_TO_UNDERSTAND]: {
    id: UserFeedbackOptionId.HARD_TO_UNDERSTAND,
    title: 'Hard to understand',
    rating: 0,
    dimension: UserFeedbackDimension.COHERENCY
  },
  [UserFeedbackOptionId.EASY_TO_UNDERSTAND]: {
    id: UserFeedbackOptionId.EASY_TO_UNDERSTAND,
    title: 'Easy to understand',
    rating: 2,
    dimension: UserFeedbackDimension.COHERENCY
  },
  [UserFeedbackOptionId.DISORGANIZED]: {
    id: UserFeedbackOptionId.DISORGANIZED,
    title: 'Disorganized',
    rating: 0,
    dimension: UserFeedbackDimension.ORGANIZATION
  },
  [UserFeedbackOptionId.ORGANIZED]: {
    id: UserFeedbackOptionId.ORGANIZED,
    title: 'Organized',
    rating: 2,
    dimension: UserFeedbackDimension.ORGANIZATION
  },
  [UserFeedbackOptionId.DID_NOT_ANSWER_THE_QUESTION]: {
    id: UserFeedbackOptionId.DID_NOT_ANSWER_THE_QUESTION,
    title: 'Did not answer the question',
    rating: 0,
    dimension: UserFeedbackDimension.RELEVANCY
  },
  [UserFeedbackOptionId.ANSWERED_THE_QUESTION]: {
    id: UserFeedbackOptionId.ANSWERED_THE_QUESTION,
    title: 'Answered the question',
    rating: 2,
    dimension: UserFeedbackDimension.RELEVANCY
  },
  [UserFeedbackOptionId.TOO_MUCH_DETAIL]: {
    id: UserFeedbackOptionId.TOO_MUCH_DETAIL,
    title: 'Too much detail',
    rating: 0,
    dimension: UserFeedbackDimension.DETAIL
  },
  [UserFeedbackOptionId.NOT_ENOUGH_DETAIL]: {
    id: UserFeedbackOptionId.NOT_ENOUGH_DETAIL,
    title: 'Not enough detail',
    rating: 0,
    dimension: UserFeedbackDimension.DETAIL
  },
  [UserFeedbackOptionId.JUST_ENOUGH_DETAIL]: {
    id: UserFeedbackOptionId.JUST_ENOUGH_DETAIL,
    title: 'Just enough detail',
    rating: 2,
    dimension: UserFeedbackDimension.DETAIL
  }
};

export type AiChatUserFeedbackModalProps = {
  overallRating: number;
  entry: ChatEntryDto;
  handleSaveClick: (
    chatEval: Partial<ChatEntryEvaluationDto>,
    isFaceClick: boolean,
  ) => void;
  handleCancelClick: () => void;
  entryEvaluation: ChatEntryEvaluationDto;
  trackAction: (props: AnalyticsActionProps) => void;
}

export type AiChatUserFeedbackModalState = {
  comment: string;
  dimensionMap: Record<UserFeedbackDimension, UserFeedbackOptionId>;
}

const defaultState: AiChatUserFeedbackModalState = {
  comment: '',
  dimensionMap: {} as Record<UserFeedbackDimension, UserFeedbackOptionId>,
};
export const AiChatUserFeedbackModal = (props: AiChatUserFeedbackModalProps) => {
  const {
    handleSaveClick,
    handleCancelClick,
  } = props;

  const [state, setState] = useState<AiChatUserFeedbackModalState>(defaultState);

  const mergeState = (newState: Partial<AiChatUserFeedbackModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  useEffect(() => {
    const { entryEvaluation } = props;

    if (!entryEvaluation) {
      mergeState(defaultState);
      return;
    }

    mergeState({
      comment: entryEvaluation.comment,
      dimensionMap: {
        [UserFeedbackDimension.DETAIL]: entryEvaluation.detail as UserFeedbackOptionId,
        [UserFeedbackDimension.COHERENCY]: entryEvaluation.coherency as UserFeedbackOptionId,
        [UserFeedbackDimension.ACCURACY]: entryEvaluation.accuracy as UserFeedbackOptionId,
        [UserFeedbackDimension.RELEVANCY]: entryEvaluation.relevancy as UserFeedbackOptionId,
        [UserFeedbackDimension.ORGANIZATION]: entryEvaluation.organization as UserFeedbackOptionId,
      },
    });
  }, []);

  const handlePromptChange = (e, value) => {
    mergeState({
      comment: value
    });
  };

  const isOptionActive = (option: UserFeedbackOption, _state: AiChatUserFeedbackModalState): boolean => {
    if (isNil(_state.dimensionMap[option.dimension])) {
      return false;
    }
    return _state.dimensionMap[option.dimension] === option.id;
  };

  const handleDimensionClick = (option: UserFeedbackOption, isActive: boolean) => {
    props.trackAction({
      action: AnalyticsAction.AI_CHAT_FEEDBACK_DIMENSION_CLICK,
      props: {
        rating: props.overallRating,
        name: option.id,
        isActiveWhenClicked: isActive,
        entryId: props.entry.id,
      }
    });

    setState((prevState) => {
      return {
        ...prevState,
        dimensionMap: {
          ...prevState.dimensionMap,
          [option.dimension]: isOptionActive(option, prevState) ? null : option.id
        }
      };
    });
  };

  const getNilVal = (val: UserFeedbackOptionId) => {
    if (isNil(val)) {
      return null;
    }
    if (props.overallRating !== 1 && UserFeedbackOptionMap[val].rating !== props.overallRating) {
      return null;
    }
    return val;
  };

  const handleSubmit = () => {

    const chatEval: Partial<ChatEntryEvaluationDto> = {
      overall: props.overallRating,
      accuracy: getNilVal(state.dimensionMap[UserFeedbackDimension.ACCURACY]),
      coherency: getNilVal(state.dimensionMap[UserFeedbackDimension.COHERENCY]),
      organization: getNilVal(state.dimensionMap[UserFeedbackDimension.ORGANIZATION]),
      relevancy: getNilVal(state.dimensionMap[UserFeedbackDimension.RELEVANCY]),
      detail: getNilVal(state.dimensionMap[UserFeedbackDimension.DETAIL]),
      comment: !state.comment ? null : state.comment,
    };

    if (props.entryEvaluation && props.entryEvaluation.id) {
      chatEval.id = props.entryEvaluation.id;
    }

    handleSaveClick(
      chatEval,
      false,
    );
  };

  const isOptionDisabled = () => {
    return false;
  };

  const isSubmitDisabled = () => {
    return (!state.comment || state.comment.trim().length === 0) && Object.values(state.dimensionMap).filter((val) => !isNil(val)).length === 0;
  };

  return (
    <div className="c-els-modal">
      <div className="c-els-modal__window">
        <div className="c-els-user-feedback">

          <button
            type="button"
            className="c-els-modal__close"
            onClick={handleCancelClick}
          >
            <ELSIcon name="close" size="1x" />
            <span className="u-els-hide-visually">Close this modal window</span>
          </button>

          <div className="c-els-user-feedback__main">

            <FlexLayout>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <div>How would you describe this response? Select all that apply...</div>
              </FlexItem>
            </FlexLayout>

            <div className="u-els-margin-top">
              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS,
                FlexLayoutModifier.WRAP,
                FlexLayoutModifier.LEFT
              ]}>
                {
                  Object.values(UserFeedbackOptionMap).filter((option) => {
                    if (option.rating === null) {
                      return true;
                    }
                    if (props.overallRating === 1) {
                      return true;
                    }
                    return option.rating === props.overallRating;
                  }).map((option) => {
                    const isDisabled = isOptionDisabled();
                    const isActive = isOptionActive(option, state);
                    return (
                      <FlexItem key={option.title}>
                        <div className="c-els-user-feedback__button-wrap">
                          <input
                            className="c-els-user-feedback__button-input"
                            type="checkbox"
                            disabled={isDisabled}
                            name={option.title}
                            id={option.title}
                            onClick={() => {
                              handleDimensionClick(option, isActive);
                            }}
                            checked={isOptionActive(option, state)} />
                          <label
                            htmlFor={option.title}
                            className={cx('c-els-user-feedback__button', {
                              'c-els-user-feedback__button--active': isOptionActive(option, state),
                              'c-els-user-feedback__button--disabled': isDisabled,
                            })}
                          >
                            {option.title}
                          </label>
                        </div>
                      </FlexItem>
                    );
                  })
                }
              </FlexLayout>
            </div>

            <div className="o-els-container">
              <div className="c-els-field">
                <label htmlFor="feedback-comment">
                  <textarea
                    className="c-els-field__input"
                    name="feedback-comment"
                    value={state.comment}
                    onChange={(e) => handlePromptChange(e, e.target.value)}
                    placeholder="Comments..."
                  />
                </label>

              </div>
            </div>
          </div>

          <div className="c-els-user-feedback__controls">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.RIGHT,
            ]}>
              <FlexItem>
                <ELSButton type={ELSButtonType.TERTIARY}
                           size={ELSButtonSize.SMALL}
                           onClick={() => {
                             handleCancelClick();
                           }}>
                  Cancel
                </ELSButton>
              </FlexItem>
              <FlexItem>
                <ELSButton type={ELSButtonType.PRIMARY}
                           size={ELSButtonSize.SMALL}
                           ariaLabel="Submit feedback"
                           isDisabled={isSubmitDisabled()}
                           onClick={() => {
                             handleSubmit();
                           }}>
                  Submit
                </ELSButton>
              </FlexItem>
            </FlexLayout>
          </div>

        </div>
      </div>
    </div>
  );
};
