import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  DeepLinkPostDto,
  DeepLinkPostResponseDto,
  DeepLinkResponseDto
} from './eols-lms-config-service.dtos';

export const postDeepLink = (data: DeepLinkPostDto): Promise<DeepLinkPostResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<DeepLinkPostResponseDto>('/v2/deeplinks', { data });
  });
};

export const fetchDeepLinkData = (deepLinkGuid: string, courseSectionId: string): Promise<DeepLinkResponseDto> => {
  return withApiErrorLoggingOnly(() => {

    return eolsBaseApi.get<DeepLinkResponseDto>(
      addSearchParams('/v2/deeplinks', {
        courseSectionId,
        deepLinkGuid
      })
    );
  });
};
