import { push, replace } from 'connected-react-router';
import { LocationState, LocationDescriptorObject } from 'history';

type redirect = {
  <S = LocationState>(path: string, state?: S): void;
  <S = LocationState>(location: LocationDescriptorObject<S>): void;
}
type replace = {
  <S = LocationState>(path: string, state?: S): void;
  <S = LocationState>(location: LocationDescriptorObject<S>): void;
}
const redirect: redirect = (path, state?) => dispatch => dispatch(push(path, state));
const replaceURL: replace = (path, state?) => dispatch => dispatch(replace(path, state));

export const locationActions = { redirect, replaceURL };
