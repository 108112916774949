import React from 'react';
import memoizeOne from 'memoize-one';

const EaqIcon = 'M405.2,263.4c-2.6,11.5-12.8,20.1-25.1,20.1c-5,0-9.7-1.4-13.6-3.9c3.3,1.6,7,2.4,10.9,2.4\n'
  + 'c14.2,0,25.7-11.5,25.7-25.7c0-14.2-11.5-25.7-25.7-25.7c-6.2,0-11.8,2.2-16.3,5.8c-7.2-1.2-14.5-3.8-21.3-7.4\n'
  + 'c-12.8-6.9-26.8-19.7-28.1-35.1c-0.7-8.3,3.4-13.9,10.8-13.8c-4.7-1.4-8.9-0.7-12.6,1.6c-4.5,2.7-7.9,7-10.5,11.9\n'
  + 'c-7.5,14.2-8.7,32.3-4.7,48.7c2.1,8.7,20,38.4,24.7,45.3c-4.9-5.4-23.5-33.3-27.1-39.7c-43.6,6.3-82.4,27.6-110.9,58.6\n'
  + 'c-51.3,55.7-65.5,151-25.6,216.7c16.4,27,43,48.4,73.8,55.5s65.4-0.9,87.9-23.1c22.5-22.2,30.8-58.6,17.6-87.3\n'
  + 'c-7.6-16.7-21.9-30.1-38.9-36.8c-23.8-9.3-53.3-4.1-70.5,14.7s-19.1,50.8-2.1,69.8s51.2,19.9,67.3,0.1c9.2-11.4,11.6-28.3,4.7-41.2\n'
  + 's-23.3-20.3-37.3-15.9c-14,4.4-23.2,21-18.5,34.8c4.7,13.9,23.3,21.3,35.8,13.6c4.4-2.7,8-7.5,7.6-12.6s-6-9.8-10.7-7.8\n'
  + 'c-3.5,1.5-5.3,5.6-8.8,7.1c-4.3,1.8-9.5-1.9-10.3-6.5c-0.7-4.6,2.3-9.2,6.4-11.4c9.1-5,21.8,2,24.1,12.1s-4.5,21-14,25.2\n'
  + 'c-17.2,7.7-39.1-6.2-42.2-24.8s10.2-37.5,27.9-44c23-8.4,50.8,3.3,62.8,24.6s8.5,49.6-6.8,68.8c-15.2,19.1-40.6,29-65,27.4\n'
  + 'c-28-1.8-56.4-17.2-70.4-41.8c-5.3-9.2-8.8-18.4-11.1-27.4c-17.5-72,33.7-128.5,100.4-142.3c22.1-4.6,49.4-0.3,71,9.4\n'
  + 'c11.1,5,16.2,14.5,24,20.1c-1-23.9-28.5-46.7-48.8-55.1C361,346.9,417,316,429.6,286.5l0.3-3.1C423.3,275.6,413.9,268.1,405.2,263.4\n'
  + 'z M388,239.2c5.7,3.5,9,9.4,9.4,15.6c-2.6-4.5-6.6-8.6-11.8-11.8c-5.2-3.2-10.7-4.9-15.8-5.3C375.5,235.4,382.3,235.7,388,239.2z';

export const getEAQIcon = memoizeOne(() => {
  return (
    <svg
      name="eaq eugene"
      viewBox="70 170 425 425"
      fill="currentColor"
      width="1rem"
      height="1rem"
    >
      <path d={EaqIcon} />
    </svg>
  );
});
