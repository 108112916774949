import { orderBy } from 'lodash';
import { ChatEntryDto } from '../../apis/florence-facade/florence-facade.dtos';
import { AiChatAdminState } from './AiChatAdmin.page';

export const getPaginationConfig = (
  idx: number,
  lastIndex: number,
  pageSize: number
): {
  page: number;
  pageSize: number;
} => {
  const diff = lastIndex - idx;
  const pagesBack = Math.ceil(diff / pageSize);
  return {
    page: pagesBack,
    pageSize
  };
};

export const getSortedChatEntries = (entriesPage: AiChatAdminState['entriesPage']): ChatEntryDto[] => {
  if (!entriesPage || !entriesPage.content) {
    return [];
  }
  return orderBy(entriesPage.content, ['id', 'index'], 'asc');
};
