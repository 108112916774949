import React from 'react';
import {
  isNil,
  isObject
} from 'lodash';
import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import {
  ChatEntryDto,
  ChatEntryTraceDto,
  ChatEntryTraceKey
} from '../../apis/florence-facade/florence-facade.dtos';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { copyContentToClipboard } from '../../utilities/common.utilities';

type AiChatAdminTracesModalProps = {
  entry: ChatEntryDto;
  traces: Partial<Record<ChatEntryTraceKey, ChatEntryTraceDto>>;
  toastService: ELSToastService;
  handleClose: () => void;
}

export const AiChatAdminTracesModal = (props: AiChatAdminTracesModalProps) => {

  const handleCopyClick = (content: string) => {

    copyContentToClipboard(content);

    props.toastService.openToast({
      ...BASE_TOAST_CONFIG,
      component: (
        <div>Successfully copied</div>
      ),
      type: ELSWithToastService.types.POSITIVE
    });
  };

  const renderKeyValue = (value) => {
    if (isNil(value)) {
      return '';
    }
    if (isObject(value)) {
      return JSON.stringify(value);
    }
    return value.toString();
  };

  return (
    <div>
      <div className="c-els-modal c-els-modal--full">
        <div className="c-els-modal__window u-els-width-1o1">
          <div className="c-ssa-ai-chat-admin-traces-modal">

            <button
              type="button"
              className="c-els-modal__close"
              onClick={props.handleClose}
            >
              <ELSIcon name="close" size="1x" />
              <span className="u-els-hide-visually">Close this modal window</span>
            </button>

            <h2>Entry</h2>
            <div className="u-els-margin-top-1x u-els-margin-bottom-2x">
              <table>
                <tbody>
                  {
                    Object.keys(props.entry).map((key) => {
                      return (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td className="u-els-padding-left">{renderKeyValue(props.entry[key])}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <h2>Traces</h2>

            {
              Object.keys(props.traces).map((key) => {
                return (
                  <div
                    key={key}
                    className="o-els-container o-els-container--2x"
                  >
                    <h3>{key}:</h3>
                    {
                      Object.keys(props.traces[key]).map((traceKey) => {
                        const content = renderKeyValue(props.traces[key][traceKey]);
                        return (
                          <div
                            key={`${key}_${traceKey}`}
                            className="o-els-container"
                          >
                            <div className="u-els-margin-bottom-1o4">
                              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2]}>
                                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                                  {traceKey}:
                                </FlexItem>
                                <FlexItem>
                                  <button
                                    type="button"
                                    className="u-els-debuttonize u-els-anchorize"
                                    onClick={() => handleCopyClick(content)}
                                  >
                                    copy
                                  </button>
                                </FlexItem>
                              </FlexLayout>
                            </div>
                            <pre>{content}</pre>
                          </div>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default AiChatAdminTracesModal;
