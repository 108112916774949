import React, { ReactElement } from 'react';

export type MasteryChartProps = {
  scoreAsPercentage: number;
}

const MasteryChart = (props: MasteryChartProps): ReactElement => {
  const {
    scoreAsPercentage,
  } = props;
  return (
    <div>
      <div className="c-ssa-mastery-chart">
        <div className="c-ssa-mastery-chart__bar">
          <div className="c-ssa-mastery-chart__bar-sections">
            <div className="c-ssa-mastery-chart__bar-section" />
            <div className="c-ssa-mastery-chart__bar-section" />
            <div className="c-ssa-mastery-chart__bar-section" />
            <div className="c-ssa-mastery-chart__bar-section" />
          </div>
          <div
            style={{
              left: `${scoreAsPercentage}%`
            }}
            className="c-ssa-mastery-chart__bar-indicator" />
        </div>
      </div>
    </div>
  );
};

export default MasteryChart;
