import React from 'react';
import cx from 'classnames';
import { ResourceQtiDataDto } from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { ELSIcon } from '../els.components';

export interface OrderQuestionsDisplayProps {
  question: ResourceQtiDataDto;
}

export const OrderQuestionDisplay = (props: OrderQuestionsDisplayProps) => {
  const {
    question
  } = props;

  const { correctResponse } = question;
  const { responseChoices } = question;

  return (
    <div>
      <h2
        role="presentation"
        className="o-els-container u-els-margin-top-2x u-els-margin-bottom-2x u-els-font-size-intro question-prompt__content question-prompt__content--selection"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: question.prompt }}
      />
      <ul className="c-scm-reorder">
        {
          (correctResponse as string[])
            .map((answer, index) => {
              const isCorrect = correctResponse[index] === answer;
              const answerText = responseChoices[answer];
              return (
                <li className="c-scm-reorder__row" key={JSON.stringify(answer)}>
                  <div className="o-els-flex-layout o-els-flex-layout--gutters">
                    <div className="o-els-flex-layout__item">
                      <div className="c-scm-reorder__context">
                        <div className="o-els-flex-layout o-els-flex-layout--middle o-els-flex-layout--left o-els-flex-layout--gutters">
                          <div className="o-els-flex-layout__item">
                            <div className={cx('c-scm-answer-icon c-scm-answer-icon--visible', {
                              'c-scm-answer-icon--correct': isCorrect,
                              'c-scm-answer-icon--incorrect': !isCorrect,
                            })}>
                              <ELSIcon
                                name={isCorrect ? 'check' : 'delete'}
                                prefix="gizmo"
                                size="1x"
                              />
                            </div>
                          </div>
                          <div className="o-els-flex-layout__item">
                            <div className="c-scm-reorder__number">{index + 1}.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
                      <div className="c-scm-reorder__item">
                        <div className="o-els-flex-layout o-els-flex-layout--middle o-els-flex-layout--gutters">
                          <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
                            <div className="c-scm-reorder__text">{answerText}</div>
                          </div>
                          <ELSIcon
                            name="drag-handle"
                            size="2x"
                            prefix="hmds"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
        }
      </ul>
      <div className="u-els-margin-left-4x">
        <div>
          <div className="u-els-margin-top u-els-font-size-h4">
            {/* eslint-disable-next-line react/no-danger */}
            <p className="rationale__text" dangerouslySetInnerHTML={{ __html: question.feedback.feedback }} />
          </div>
        </div>
      </div>
    </div>
  );

};
